import classNames from 'classnames'
import { Fragment, useEffect, useRef, useState } from 'react'
import { FaChevronDown, FaChevronUp } from 'react-icons/fa'
import TruncatedText from '../../../components/TruncatedText'

const CustomSelectBox = ({
  options,
  selectedValue,
  onChange,
  isDisabled,
  isLoading,
  isCollapse,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [selected, setSelected] = useState({})
  const ref = useRef()

  const disabledStyle = {
    pointerEvents: 'none',
    backgroundColor: 'rgb(222, 226, 230)',
    filter: 'grayscale(100%)',
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsOpen(false)
      }
    }

    document.addEventListener('click', handleClickOutside, true)

    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  }, [])

  useEffect(() => {
    setSelected(selectedValue)
  }, [selectedValue])

  const handleSelect = (value) => {
    setSelected(value)
    onChange(value)
    setIsOpen(false)
  }

  return (
    <div className={`${isCollapse ? 'd-none' : 'd-block'} select-box`} ref={ref}>
      {isLoading ? (
        <div className='selected loading-skeleton'>
          <div className='skeleton-text'></div>
        </div>
      ) : (
        <div
          className='selected'
          onClick={() => setIsOpen(!isOpen)}
          style={isDisabled ? disabledStyle : {}}
        >
          <TruncatedText
            text={selected.groupName || 'Select group'}
            id={`select-${selected?.id || 'default'}`}
            style={{ width: 'calc(100% - 20px)' }}
            isTooltip={false}
          />
          {isOpen ? (
            <FaChevronUp className='arrow-icon' />
          ) : (
            <FaChevronDown className='arrow-icon' />
          )}
        </div>
      )}
      {isOpen && !isLoading && (
        <div className={classNames('options', { open: isOpen })}>
          {options.map((option) => (
            <Fragment key={option.id}>
              <div className='option' onClick={() => handleSelect(option)}>
                <TruncatedText text={option.name} id={`option-${option.id}`} />
              </div>
            </Fragment>
          ))}
        </div>
      )}
    </div>
  )
}

export default CustomSelectBox
