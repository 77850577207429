import { yupResolver } from '@hookform/resolvers/yup'
import uploadApi from '_api/upload'
import userApi from '_api/user'
import ModalHitPayPayment from '_components/ModalHitPayPayment'
import { createGroup, getGroupFromStore } from '_redux/modules/group'
import { getListInvoicePremiumAccount } from '_redux/modules/invoice'
import { getListPackage } from '_redux/modules/package'
import { GROUP_IS_PRIVATE, PACKAGE_TYPE_PREMIUM_ACCOUNT } from '_utils/constant'
import { getUserInfo, setUserInfo } from '_utils/localData'
import moment from 'moment/moment'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'
import { Form, Spinner } from 'reactstrap'
import * as Yup from 'yup'
import GroupContact from '../components/GroupContact'
import GroupDescription from '../components/GroupDescription'
import GroupName from '../components/GroupName'
import GroupQuestion from '../components/GroupQuestion'
import GroupSetting from '../components/GroupSetting'
import ModalNotify from '../components/ModalNotify'

import CreatedModal from './CreatedModal'
import './style.scss'

const validationSchema = Yup.object().shape({
  groupName: Yup.string()
    .required('Group name is required')
    .min(1, 'Group name must be at least 1 character')
    .max(256, 'Group name can be up to 256 characters'),
  groupDescription: Yup.string('Invalid description').required('Invalid description'),
  groupQuestion: Yup.string().nullable().notRequired(),
  passCode: Yup.string().nullable().notRequired(),
  contact: Yup.string()
    .nullable()
    .test('is-valid-contact', 'Invalid contact format', (value) => {
      if (!value) return true

      if (/^\d{8}$/.test(value)) {
        return true
      }

      if (value.includes('@')) {
        return true
      }

      // Optimize WhatsApp link validation
      if (/^(https?:\/\/)?(www\.)?wa\.me\//.test(value)) {
        return true
      }

      // Optimize website validation
      if (/^(https?:\/\/)?(www\.)?[a-zA-Z0-9-]+\.(com|org|net|com\.sg|org\.sg)$/.test(value)) {
        return true
      }

      if (value.toUpperCase() === 'NA') {
        return true
      }

      return false
    }),
})

const CreateGroup = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { addToast } = useToasts()
  const userInfo = getUserInfo()

  const [openModal, setOpenModal] = useState(false)
  // const [currentPlanPremiumAccount, setCurrentPlanPremiumAccount] = useState()
  const [isPaymentGroup, setIsPaymentGroup] = useState(false)
  const [isSubmittedPayment, setIsSubmittedPayment] = useState(false)
  const [groupImg, setGroupImg] = useState('')
  const [groupImgUrl, setGroupUrl] = useState('')
  const [groupType, setGroupType] = useState(GROUP_IS_PRIVATE)
  const [defaultRole, setDefaultRole] = useState(1)
  const [autoApproval, setAutoApproval] = useState(0)
  const [accessMemberList, setAccessMemberList] = useState(1)
  const [errorMessage, setErrorMessage] = useState('')
  const [processing, setProcessing] = useState(false)
  const [newestPackage, setNewestPackage] = useState(null)
  const [hasClosedPaymentModal, setHasClosedPaymentModal] = useState(false)

  const { groupStored } = useSelector((state) => state.group)
  const { listPackage } = useSelector((state) => state.package)
  // const { listInvoicePremiumAccount } = useSelector((state) => state.invoice)

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
    watch,
  } = useForm({
    mode: 'all',
    resolver: yupResolver(validationSchema),
  })

  const contactValue = watch('contact')

  const handleCloseModal = () => {
    setIsPaymentGroup(false)
    setHasClosedPaymentModal(true)
  }

  useEffect(() => {
    dispatch(getGroupFromStore())
    dispatch(getListPackage({ packageType: PACKAGE_TYPE_PREMIUM_ACCOUNT }))
    dispatch(getListInvoicePremiumAccount())
  }, [])

  useEffect(() => {
    const isPremiumAccount = userInfo?.features?.premiumAccount || false

    // Kiểm tra nếu nhóm là public và người dùng không có premiumAccount
    if (!isPremiumAccount && groupType !== GROUP_IS_PRIVATE) {
      // Chỉ hiển thị modal nếu người dùng chưa đóng nó
      if (!hasClosedPaymentModal) {
        setIsPaymentGroup(true)
      }
    } else {
      // Nếu nhóm là private hoặc người dùng có premiumAccount, reset flag đóng modal
      setHasClosedPaymentModal(false)
    }
  }, [groupType, userInfo, hasClosedPaymentModal])

  useEffect(() => {
    if (groupStored) {
      reset({
        groupName: groupStored.name.trim(),
        groupDescription: groupStored.description,
        photoUrl: groupStored.photoUrl,
        passCode: groupStored.passCode,
        groupQuestion: groupStored.question,
        public: groupStored.public,
        defaultRole: groupStored.defaultRole,
        autoApproval: groupStored.autoApproval,
        accessMemberList: groupStored.accessMemberList,
        groupContact: groupStored.contact,
      })
      setGroupType(groupStored.public)
      setDefaultRole(groupStored.defaultRole)
      setAutoApproval(groupStored.autoApproval)
      setAccessMemberList(groupStored.accessMemberList)

      setValue('contact', groupStored.contact)
    }
  }, [JSON.stringify(groupStored), reset])

  useEffect(() => {
    if (listPackage?.length) {
      setNewestPackage(listPackage[0])
    }
  }, [JSON.stringify(listPackage)])

  const toggleModalPaymentGroup = () => setIsPaymentGroup(!isPaymentGroup)

  const onGroupImageUploaded = (event) => {
    const { files } = event.target
    if (files) {
      const img = files[0]
      setGroupUrl(URL.createObjectURL(img))
      setGroupImg(files)
    }
  }

  const onChangeRadio = (state, value) => {
    if (state === 'groupType') {
      // Nếu chuyển từ private sang public hoặc ngược lại, reset flag đóng modal nếu cần
      if (groupType === GROUP_IS_PRIVATE && value !== GROUP_IS_PRIVATE) {
        setHasClosedPaymentModal(false)
      }
      setGroupType(value)
    } else if (state === 'defaultRole') {
      setDefaultRole(value)
    } else if (state === 'accessMemberList') {
      setAccessMemberList(value)
    } else {
      setAutoApproval(value)
    }
  }

  const handleContactChange = (event) => {
    const { value } = event.target

    if (/^\d{8}$/.test(value)) {
      setValue('contact', `https://wa.me/${value}`)
    } else if (value.includes('@')) {
      setValue('contact', value)
    } else if (/^(https?:\/\/)?(www\.)?wa\.me\//.test(value)) {
      setValue('contact', value.startsWith('http') ? value : `https://${value}`)
    } else if (/^(https?:\/\/)?(www\.)?[a-zA-Z0-9-]+\.(com|org|net|com\.sg|org\.sg)$/.test(value)) {
      setValue('contact', value.startsWith('http') ? value : `https://${value}`)
    } else if (value.toUpperCase() === 'NA') {
      setValue('contact', value.toUpperCase())
    } else {
      setValue('contact', value)
    }
  }

  const onSubmit = async (data) => {
    const postData = {
      name: data.groupName.trim(),
      description: data.groupDescription,
      photoUrl: null,
      passcode: data.passCode,
      question: data.groupQuestion,
      public: groupType,
      defaultRole,
      autoApproval,
      accessMemberList,
      ...(contactValue && { contact: contactValue }),
    }

    const isPremiumAccount = userInfo?.features?.premiumAccount || false
    if (!isPremiumAccount && postData.public !== GROUP_IS_PRIVATE) {
      setIsPaymentGroup(true)
      return
    }

    if (groupImg) {
      const { msgResp: res } = await uploadApi.uploadPhoto(groupImg)
      postData.photoUrl = res.url
    }

    try {
      setProcessing(true)
      const createGroupThunk = createGroup(postData)
      await dispatch(createGroupThunk)

      addToast('Created successfully', {
        appearance: 'success',
        autoDismiss: true,
      })

      const referrer = localStorage.getItem('REFERRER')
      if (referrer) {
        localStorage.removeItem('REFERRER')
        history.push('/event/create')
      } else {
        history.push('/group/my-group-buy')
      }
    } catch (err) {
      setProcessing(false)
      setErrorMessage(err.msgResp)
      addToast(err?.msgResp || err.message, {
        appearance: 'error',
        autoDismiss: true,
      })
    }
  }

  const confirmPayment = async (e) => {
    e.preventDefault()

    setIsSubmittedPayment(true)

    try {
      const { msgResp } = await userApi.upgradeToPremium({
        packageType: PACKAGE_TYPE_PREMIUM_ACCOUNT,
        packageId: newestPackage?.id,
        startDate: moment().format('YYYY-MM-DD'),
      })

      localStorage.setItem('newestTransaction', JSON.stringify(msgResp))

      if (msgResp?.paymentLink) {
        window.open(msgResp.paymentLink, '_blank')
      }

      await dispatch(getListInvoicePremiumAccount())
      userApi
        .getUser()
        .then((res) => {
          setUserInfo(res.msgResp)
        })
        .catch((error) => {
          addToast(error.msgResp || error.message, { appearance: 'error', autoDismiss: true })
        })

      addToast('Payment success', { appearance: 'success', autoDismiss: true })
      setIsSubmittedPayment(false)
    } catch (error) {
      setIsSubmittedPayment(false)
      addToast(error.msgResp || error.message, { appearance: 'error', autoDismiss: true })
    } finally {
      toggleModalPaymentGroup()
    }
  }

  return (
    <React.Fragment>
      <Form onSubmit={handleSubmit(onSubmit)} className='container create-group'>
        <div className='c-form c-form--group mt-2'>
          <div className='row'>
            <div className='col-4'>
              <div className='upload-image'>
                <img
                  src={groupImgUrl?.url || groupImgUrl || groupStored?.photoUrl}
                  alt=''
                  className='w-100 h-100'
                  style={{ zIndex: 3, objectFit: 'cover' }}
                />
                <div className='upload-image-txt'>
                  <i className='icon-capture'></i>
                  <span>
                    Add Profile
                    <br />
                    Picture
                  </span>
                </div>
                <div className='u-file-upload__item'>
                  <div className='u-file-upload__wrap'>
                    <a className='c-upload preview-images-zone'>
                      <input
                        type='file'
                        accept='image/*'
                        name='file_source_01'
                        size='40'
                        className='inputFile'
                        data-error='#error-file_source_01'
                        onChange={onGroupImageUploaded}
                        style={{ zIndex: '999' }}
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-8'>
              <GroupName register={register('groupName')} error={errors?.groupName} />
              <GroupDescription
                register={register('groupDescription')}
                error={errors?.groupDescription}
              />
            </div>
          </div>
          <div className='box-question'>
            <GroupQuestion register={register('groupQuestion')} error={errors?.groupQuestion} />
            <GroupContact
              register={{
                ...register('contact', {
                  onChange: handleContactChange,
                }),
              }}
              error={errors?.contact}
              contactValue={contactValue}
            />
            <GroupSetting
              groupType={groupType}
              defaultRole={defaultRole}
              autoApproval={autoApproval}
              onChangeRadio={onChangeRadio}
              accessMemberList={accessMemberList}
              register={register('passCode')}
            />
          </div>
        </div>
        <div className='row row--bot align-self-end'>
          <small className='errorMessage'>{errorMessage || errors?.passCode?.message}</small>
          <div className='col-6 m-auto'>
            <button disabled={processing} className='btn btn--main'>
              {processing ? <Spinner color='light' size='sm' /> : 'Save'}
            </button>
          </div>
        </div>
      </Form>

      <ModalHitPayPayment
        isShow={isPaymentGroup}
        toggle={handleCloseModal}
        service={{
          type: 'Upgrade premium account',
          price: newestPackage?.discountPrice || newestPackage?.price || 0,
        }}
        isSubmittedPayment={isSubmittedPayment}
        confirmPayment={confirmPayment}
      />

      <CreatedModal />

      <ModalNotify modal={openModal} toggle={() => setOpenModal(!openModal)} />
    </React.Fragment>
  )
}

export default CreateGroup
