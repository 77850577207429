import axiosClient from './axiosClient'

const orderApi = {
  getListOrdersByBuyer: (groupId) => {
    const url = '/list-orders-by-buyer'
    if (groupId) {
      axiosClient.interceptors.request.use((config) => {
        config.headers['group-id'] = groupId
        return config
      })
    }
    return axiosClient.get(url)
  },
  getListOrdersShopByBuyer: (shopId) => {
    const url = '/list-shop-event-order-by-buyer'
    if (shopId) {
      axiosClient.interceptors.request.use((config) => {
        config.headers['shop-id'] = shopId
        return config
      })
    }
    return axiosClient.get(url)
  },
  getOrderByRef: (ref, isEventShop = false) => {
    const endPoint = isEventShop ? 'list-shop-event-order-by-ref' : 'list-orders-by-ref'
    const url = `/${endPoint}?ref=${ref}`
    return axiosClient.get(url)
  },
  createOrder: (data) => {
    const url = '/create-order'
    return axiosClient.post(url, data)
  },
  getListOrdersByEventId: (eventId, isEventShop = false, isByAdmin = false) => {
    const endPointShop = isEventShop ? '/list-shop-event-orders-by-event' : '/list-orders-by-event'
    const endPointAdmin = isByAdmin ? '/get-list-order-by-admin' : endPointShop

    const url = `${endPointAdmin}?eid=${eventId}`
    return axiosClient.get(url)
  },
  updateOrderBuyerStatus: (ref, data, isEventShop = false) => {
    const endPoint = isEventShop
      ? 'update-shop-event-order-buyer-status'
      : 'update-order-buyer-status'
    const url = `/${endPoint}?ref=${ref}`
    return axiosClient.patch(url, data)
  },
  updateOrderBuyerInfo: (ref, data, isEventShop = false) => {
    const endPoint = !isEventShop ? 'update-order-buyer-info' : 'update-shop-event-order-buyer-info'
    const url = `/${endPoint}?ref=${ref}`
    return axiosClient.patch(url, data)
  },
  updateOrderBuyerStatusWithPaymentScreenshot: (ref, data) => {
    const endPoint = !data.isEventShop
      ? 'update-order-buyer-status'
      : 'update-shop-event-order-buyer-status'
    const url = `/${endPoint}?ref=${ref}`
    return axiosClient.patch(url, data)
  },
  updateOrderStatus: (ref, data) => {
    const url = `/update-order-status?ref=${ref}`
    return axiosClient.patch(url, data)
  },
  updateOrder: ({ oid, data }) => {
    const url = `/update-order?ref=${oid}`
    return axiosClient.put(url, data)
  },
  updateShopEventOrder: ({ oid, data }) => {
    const url = `/update-shop-event-order?ref=${oid}`
    return axiosClient.put(url, data)
  },
  updateOrderNo: (id) => {
    const url = `/update-order-no?eid=${id}`
    return axiosClient.patch(url)
  },
  updateOrderStatusAndBuyerStatus: (ref, data, isEventShop = false) => {
    const endPoint = isEventShop ? 'update-shop-event-order-status' : 'update-order-status'
    const url = `/${endPoint}?ref=${ref}`
    return axiosClient.patch(url, data)
  },
  updateInvoicesPlacerStatus: (data, isEventShop = false) => {
    const url = isEventShop
      ? '/update-shop-event-invoices-place-status'
      : '/update-invoices-placer-status'
    return axiosClient.patch(url, data)
  },
  updateInvoicesStatus: (data, dShopId = '') => {
    const url = !dShopId ? '/update-invoices-status' : '/update-shop-event-invoices-status'
    return axiosClient.patch(url, data)
  },
  createShopEventOrder: (data) => {
    const url = '/create-shop-event-order'
    return axiosClient.post(url, data)
  },
}

export default orderApi
