/* eslint-disable function-paren-newline */
import LoadingOverlay from '_components/LoadingOverlay'
import { EVENT_STATUS_OPEN } from '_utils/constant'
import { flexibleSearch, sortListEvents } from '_utils/function'
import moment from 'moment'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getListEventsOpeningMyGroupBuy } from '../../../redux/modules/event'
import EventItem from '../../Event/components/EventItem'
import './style.scss'

const MyGroupBuy = () => {
  const dispatch = useDispatch()
  const [searchString, setSearchString] = useState('')
  const [loading, setLoading] = useState(false)

  const { eventsMyGroupBuy } = useSelector((state) => state.event)

  const fetchData = async (isMounted) => {
    try {
      if (isMounted) {
        setLoading(true)
        await dispatch(getListEventsOpeningMyGroupBuy())
      }
    } catch (error) {
    } finally {
      if (isMounted) {
        setLoading(false)
      }
    }
  }

  useEffect(() => {
    let isMounted = true

    fetchData(isMounted)

    return () => {
      isMounted = false
    }
  }, [])

  const onChangeSearch = (event) => {
    setSearchString(event.target.value)
  }

  const listEventFiltered = useMemo(() => {
    if (!searchString) return eventsMyGroupBuy

    return eventsMyGroupBuy.filter((item) => flexibleSearch(item?.title, searchString))
  }, [searchString, JSON.stringify(eventsMyGroupBuy)])

  // const userInGroup = (groupId) => {
  //   const userInfo = getUserInfo()
  //   return userInfo?.groups?.find((group) => group.id === groupId)
  // }

  if (loading) return <LoadingOverlay />

  return (
    <div className='row-top event-page my-3'>
      <div className='mb-3'>
        <div className='c-form c-form--search event-search'>
          <div className='form-group'>
            <i className='icon-search'></i>
            <input
              className='form-control'
              placeholder='Search group name'
              type='text'
              value={searchString}
              onChange={onChangeSearch}
            />
          </div>
        </div>
      </div>
      {!loading && !listEventFiltered.length ? (
        <>
          <h6 className='mt-5 fw-bold text-center w-100'>No Events Found</h6>
        </>
      ) : (
        <div className='group-list group-list--event group-list--event02 pb-4'>
          {sortListEvents(listEventFiltered)
            ?.filter((item) => item.closingTimestamp > moment().unix())
            ?.filter((item) => item.status === EVENT_STATUS_OPEN)
            ?.filter((item) => !item.shopId)
            .map((event, index) => (
              <EventItem event={event} key={index} />
            ))}
        </div>
      )}
    </div>
  )
}

export default MyGroupBuy
