/* eslint-disable indent */
import deliveryApi from '_api/delivery'
import orderApi from '_api/order'
import ModalDetailDelivery from '_pages/Event/components/ModalDetailDelivery'
import ModalMessage from '_pages/Event/components/ModalMessage'
import ModalPaymentScreen from '_pages/Event/components/ModalPaymentScreen'
import ModalWarning from '_pages/Event/components/ModalWarning'
import { updateDeliveryCombinedPayment, updateDeliveryPaymentStatus } from '_redux/modules/delivery'
import { getListCombinedPayment, updateOrderCombinedPayment } from '_redux/modules/event'
import {
  DELIVERY_BUYER_PAID,
  DELIVERY_BUYER_REFUND,
  DELIVERY_BUYER_UNPAID,
  DELIVERY_HOST_CANCELLED,
  DELIVERY_HOST_PAID,
  DELIVERY_HOST_UNPAID,
} from '_utils/constant'
import { handleDataOrder } from '_utils/function'
import moment from 'moment'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'
import { Col, FormGroup, Input, Row, Tooltip } from 'reactstrap'

function DeliveryList({ listDelivery }) {
  const { addToast } = useToasts()
  const dispatch = useDispatch()

  const { eventAndOrderById, listCombinedPayments } = useSelector((state) => state.event)

  const [modalMessage, setModalMessage] = useState(false)
  const [modalPaymentScreen, setModalPaymentScreen] = useState(false)
  const [modalDetail, setModalDetail] = useState(false)
  const [modalWarning, setModalWarning] = useState(false)
  const [dataModal, setDataModal] = useState({})
  const [dataModalDetail, setDataModalDetail] = useState({})
  const [tooltipOpen, setTooltipOpen] = useState({})

  const toggleTooltip = (id) => {
    setTooltipOpen((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }))
  }

  const initListDelivery = useCallback(
    async (data = []) => {
      const listCombinedPaymentRef = [
        ...new Set(
          data
            .filter((element) => element.combinedPaymentRef)
            .map((element) => element.combinedPaymentRef)
        ),
      ]
      if (listCombinedPaymentRef.length) {
        await dispatch(getListCombinedPayment(listCombinedPaymentRef))
        dispatch({ type: 'LOADING_DELIVERY_END' })
      }
    },
    [dispatch, listDelivery]
  )

  useEffect(() => {
    const fetchData = async () => {
      await initListDelivery(listDelivery)
    }

    fetchData()
  }, [listDelivery])

  const toggleMessage = () => setModalMessage(!modalMessage)

  const togglePaymentScreen = () => setModalPaymentScreen(!modalPaymentScreen)

  const toggleDetail = () => {
    setModalDetail(!modalDetail)
  }

  const toggleWarning = () => setModalWarning(!modalWarning)

  const updateDeliveryBookingStatus = async (deliveryId, dOrderRef, data) => {
    try {
      dispatch(updateDeliveryPaymentStatus(deliveryId, dOrderRef, data.status, data.bookerStatus))
      await deliveryApi.updateDeliveryBookingStatus(dOrderRef, data)
      addToast('Updated successfully', { appearance: 'success', autoDismiss: true })
    } catch (error) {}
  }

  const updateDeliveryBookerStatus = async (dOrderRef, data, deliveryId) => {
    try {
      dispatch(updateDeliveryPaymentStatus(deliveryId, dOrderRef, data.status, data.bookerStatus))
      await deliveryApi.updateDeliveryBookerStatus(dOrderRef, { bookerStatus: data.bookerStatus })
      addToast('Updated successfully', { appearance: 'success', autoDismiss: true })
    } catch (error) {}
  }

  const updateDeliveryCombinedPaymentAPI = async (data) => {
    try {
      dispatch(
        updateDeliveryCombinedPayment(data.combinedPaymentRef, data.status, data.placerStatus)
      )
      dispatch(updateOrderCombinedPayment(data.combinedPaymentRef, data.status, data.placerStatus))
      await orderApi.updateInvoicesStatus(data)
      addToast('Updated successfully', { appearance: 'success', autoDismiss: true })
    } catch (error) {}
  }

  const onChangeSelect = async (
    e,
    dOrderRef,
    dOrderStatus,
    dOrderBookerStatus,
    combinedPaymentRef,
    deliveryId
  ) => {
    try {
      if (dOrderStatus === DELIVERY_HOST_UNPAID && Number(e.target.value) === DELIVERY_HOST_PAID) {
        const data = { status: Number(e.target.value), bookerStatus: DELIVERY_BUYER_PAID }
        await updateDeliveryBookingStatus(deliveryId, dOrderRef, data)
      } else if (
        dOrderStatus !== DELIVERY_HOST_CANCELLED &&
        Number(e.target.value) === DELIVERY_HOST_CANCELLED &&
        dOrderBookerStatus > DELIVERY_BUYER_UNPAID
      ) {
        if (combinedPaymentRef) {
          const data = {
            combinedPaymentRef,
            status: Number(e.target.value),
            placerStatus: DELIVERY_BUYER_REFUND,
          }
          await updateDeliveryCombinedPaymentAPI(data, deliveryId)
        } else {
          const data = { status: Number(e.target.value), bookerStatus: DELIVERY_BUYER_REFUND }
          await updateDeliveryBookingStatus(deliveryId, dOrderRef, data)
        }
      } else if (
        dOrderStatus === DELIVERY_HOST_CANCELLED &&
        Number(e.target.value) !== DELIVERY_HOST_CANCELLED &&
        dOrderBookerStatus === DELIVERY_BUYER_REFUND
      ) {
        const data = { status: Number(e.target.value), bookerStatus: DELIVERY_BUYER_PAID }
        await updateDeliveryBookingStatus(deliveryId, dOrderRef, data)
      } else {
        const data = { status: Number(e.target.value), bookerStatus: dOrderBookerStatus }
        await updateDeliveryBookingStatus(deliveryId, dOrderRef, data)
      }
    } catch (error) {
      const { msgResp } = error
      addToast(msgResp, { appearance: 'error', autoDismiss: true })
    }
  }

  const openMessageModal = (e, order) => {
    e.preventDefault()
    setDataModal(order)
    toggleMessage()
  }

  const openPaymentScreen = (e, delivery) => {
    e.preventDefault()

    const { combinedPaymentRef } = delivery
    let listCombinedOrder = []
    let listCombinedDelivery = []

    if (combinedPaymentRef) {
      const exitCombinedPayment = listCombinedPayments?.findIndex(
        (item) => item.combinedPaymentRef === combinedPaymentRef
      )

      if (exitCombinedPayment !== -1) {
        const { orderCombinedPayment, deliveryCombinedPayment } =
          listCombinedPayments[exitCombinedPayment]

        orderCombinedPayment.forEach(async (element) => {
          const { eid, ref: elementRef } = element
          const { orders, adminCost, discount, deliveryCost, productIdList } =
            eventAndOrderById.find((item) => item.id === eid) || {}

          const { listOrder: otherListOrder } = handleDataOrder(
            orders,
            adminCost,
            discount,
            deliveryCost,
            productIdList
          )

          const otherOrder = otherListOrder.find((item) => item.ref === elementRef)

          listCombinedOrder = [...listCombinedOrder, otherOrder].filter((el) => el)
        })
        listCombinedDelivery = [...deliveryCombinedPayment].filter((el) => el)
      }
    }

    setDataModal({ ...delivery, listCombinedOrder, listCombinedDelivery })
    togglePaymentScreen()
  }

  const handleInvalidPayment = async (dOrderRef, dOrderStatus, combinedPaymentRef) => {
    try {
      const r = confirm('Are you sure this is invalid payment?')
      if (r === true) {
        if (combinedPaymentRef) {
          const data = {
            combinedPaymentRef,
            status: DELIVERY_HOST_UNPAID,
            placerStatus: DELIVERY_BUYER_UNPAID,
          }

          await updateDeliveryCombinedPaymentAPI(data)
        } else {
          const data = { status: dOrderStatus, bookerStatus: DELIVERY_BUYER_UNPAID }

          await updateDeliveryBookerStatus(dOrderRef, data, dataModal?.did)
        }
        togglePaymentScreen()
      }
    } catch (error) {
      const { msgResp } = error
      addToast(msgResp, { appearance: 'error', autoDismiss: true })
      togglePaymentScreen()
    }
  }

  const handleShowDetail = (e, delivery) => {
    e.preventDefault()
    setDataModalDetail(delivery)
    toggleDetail()
  }

  const handleCancel = async (
    e,
    dOrderRef,
    dOrderStatus,
    dOrderBookerStatus,
    combinedPaymentRef,
    deliveryId
  ) => {
    e.preventDefault()
    try {
      if (dOrderStatus !== DELIVERY_HOST_CANCELLED && dOrderBookerStatus > DELIVERY_BUYER_UNPAID) {
        if (combinedPaymentRef) {
          const data = {
            combinedPaymentRef,
            status: DELIVERY_HOST_CANCELLED,
            placerStatus: DELIVERY_BUYER_REFUND,
          }
          await updateDeliveryCombinedPaymentAPI(data)
        } else {
          const data = { status: DELIVERY_HOST_CANCELLED, bookerStatus: DELIVERY_BUYER_REFUND }
          await updateDeliveryBookingStatus(deliveryId, dOrderRef, data)
        }
        toggleDetail()
        toggleWarning()
      } else {
        const data = { status: DELIVERY_HOST_CANCELLED, bookerStatus: dOrderBookerStatus }
        await updateDeliveryBookingStatus(deliveryId, dOrderRef, data)
        toggleDetail()
        toggleWarning()
      }
    } catch (error) {
      const { msgResp } = error
      addToast(msgResp, { appearance: 'error', autoDismiss: true })
      toggleDetail()
      toggleWarning()
    }
  }

  const handleOpenWarning = (e, order) => {
    e.preventDefault()
    setDataModal(order)
    toggleWarning()
  }

  return (
    <div className='list-event'>
      <React.Fragment>
        {listDelivery.map((delivery, index) => (
          <Row
            key={index}
            className={`${
              index % 2 !== 0 ? 'bg-light' : ''
            } list-event__item border-0 items-center p-3`}
          >
            <Col>
              <Link
                to='#'
                style={{ color: '#7c797a' }}
                onClick={(e) => handleShowDetail(e, delivery)}
                id={`tooltip-${delivery.id}`}
              >
                {delivery.uName}
              </Link>
              <Tooltip
                isOpen={tooltipOpen[`tooltip-${delivery.id}`]}
                target={`tooltip-${delivery.id}`}
                toggle={() => toggleTooltip(`tooltip-${delivery.id}`)}
              >
                {delivery.combinedPaymentRef || delivery.ref}
              </Tooltip>

              {delivery.comment && (
                <div className='d-flex align-items-center mt-3'>
                  <span className='me-2'>Note:</span>
                  <Link to='#' onClick={(e) => openMessageModal(e, delivery)}>
                    <i className='icon-new'></i>
                  </Link>
                </div>
              )}
            </Col>
            <Col>{delivery.uPhone}</Col>
            <Col>{moment(delivery.createdAt * 1000).format('DD/MM/YYYY')}</Col>
            <Col>Delivery: {delivery.dzName}</Col>
            <Col className='text-center'>
              {delivery.status === DELIVERY_HOST_CANCELLED && <span>Refunded</span>}
              {delivery.status === DELIVERY_HOST_UNPAID && <span>Not Paid</span>}
              {delivery.status === DELIVERY_HOST_PAID && <span>Paid</span>}
            </Col>
            <Col className='text-center'>
              {delivery.bookerStatus === DELIVERY_BUYER_UNPAID && (
                <span className='n-paid' style={{ paddingRight: 0 }}>
                  Not Paid
                </span>
              )}
              {delivery.bookerStatus === DELIVERY_BUYER_PAID && (
                <a
                  href='#'
                  className={`${delivery?.combinedPaymentRef ? 'paid-combined' : 'paid'}`}
                  data-toggle='modal'
                  data-target='#modal-paid'
                  onClick={(e) => openPaymentScreen(e, delivery)}
                  style={{ fontSize: '12.5px' }}
                >
                  Paid
                </a>
              )}
              {delivery.bookerStatus === DELIVERY_HOST_CANCELLED && (
                <span className=''>Cancelled</span>
              )}
              {delivery.bookerStatus === DELIVERY_BUYER_REFUND && (
                <span className=''>Refunded</span>
              )}
            </Col>
            <Col>
              <FormGroup>
                <Input
                  id='select'
                  name='select'
                  type='select'
                  className='form-control-payment'
                  value={delivery.status}
                  onChange={(e) =>
                    onChangeSelect(
                      e,
                      delivery.ref,
                      delivery.status,
                      delivery.bookerStatus,
                      delivery.combinedPaymentRef,
                      delivery.did
                    )
                  }
                  style={
                    delivery.status === DELIVERY_HOST_UNPAID
                      ? { color: '#ff6903' }
                      : delivery.status === DELIVERY_HOST_PAID
                      ? { color: '#51aaaa' }
                      : { color: '#7c797a' }
                  }
                >
                  <option value={DELIVERY_HOST_CANCELLED}>Cancelled</option>
                  <option value={DELIVERY_HOST_UNPAID}>Unpaid</option>
                  <option value={DELIVERY_HOST_PAID}>Verified</option>
                </Input>
              </FormGroup>
            </Col>
          </Row>
        ))}
      </React.Fragment>
      {Object.keys(dataModal).length !== 0 && (
        <ModalMessage data={dataModal} modalMessage={modalMessage} toggleMessage={toggleMessage} />
      )}
      {Object.keys(dataModal).length !== 0 && (
        <ModalPaymentScreen
          data={dataModal}
          modalPaymentScreen={modalPaymentScreen}
          togglePaymentScreen={togglePaymentScreen}
          handleInvalidPayment={handleInvalidPayment}
        />
      )}
      {Object.keys(dataModal).length !== 0 && (
        <ModalWarning
          data={dataModal}
          title='delivery'
          modalWarning={modalWarning}
          toggleWarning={toggleWarning}
          handleCancel={handleCancel}
        />
      )}
      {Object.keys(dataModalDetail).length !== 0 && (
        <ModalDetailDelivery
          data={dataModalDetail}
          modalDetail={modalDetail}
          toggleDetail={toggleDetail}
          handleOpenWarning={handleOpenWarning}
          deliveryId={dataModalDetail.did}
        />
      )}
    </div>
  )
}

export default DeliveryList
