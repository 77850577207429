/* eslint-disable no-unused-vars */
import invoiceApi from '_api/invoice'
import userApi from '_api/user'
import ModalHitPayPayment from '_components/ModalHitPayPayment'
import ModalNotify from '_components/ModalNotify'
import { DeviceUserContext } from '_context/DeviceUserContext'
import useQuery from '_hooks/useQuery'
import bgAvatar from '_images/bg_avatar.png'
import { getMyGroups } from '_redux/modules/group'
import {
  cancelPlan,
  getListInvoicePremiumAccount,
  triggerPremiumForFirst,
} from '_redux/modules/invoice'
import { getListPackage } from '_redux/modules/package'
import {
  CO_BEE_FEATURE_PENDING_STATUS,
  DESKTOP,
  GROUP_IS_ADMIN,
  GROUP_IS_HOST,
  PACKAGE_TYPE_PREMIUM_ACCOUNT,
  PHONE,
} from '_utils/constant'
import { getUserInfo, setDeviceUser, setUserInfo } from '_utils/localData'
import moment from 'moment'
import React, { createContext, useContext, useEffect, useState } from 'react'
import { FaCheckCircle } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import { Link, Route, Switch } from 'react-router-dom'
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import { useToasts } from 'react-toast-notifications'
import Swal from 'sweetalert2'
import ChangePasswordPage from './ChangePassword'
import Invoices from './Invoices'
import NotificationsPage from './Notifications'
import UpdateProfilePage from './UpdateProfile'
import Avatar from './components/Avatar'
import './style.scss'

import GroupList from '../Group/GroupList'
import CardInfo from './CardInfo'

export const UserContext = createContext()

const Index = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { addToast } = useToasts()
  const { pathname } = useLocation()
  const query = useQuery()
  const userInfo = getUserInfo()

  const reference = query.get('reference')
  const status = query.get('status')

  const [countTrigger, setCountTrigger] = useState(0)
  const [currentUser, setCurrentUser] = useState({})
  const [isShowModalPayment, setIsShowModalPayment] = useState(false)
  const [isSubmittedPayment, setIsSubmittedPayment] = useState(false)
  const [isCheckAdminOfGroup, setIsCheckAdminOfGroup] = useState(false)
  const [newestPackage, setNewestPackage] = useState({})
  const [selectedSubscription, setSelectedSubscription] = useState('')
  const [isOpenModalNotify, setIsOpenModalNotify] = useState(false)

  const { checkDeviceUser, onSwitchDevice } = useContext(DeviceUserContext)
  const { listInvoicePremiumAccount } = useSelector((state) => state.invoice)
  const { listPackage } = useSelector((state) => state.package)

  const routes = [
    {
      id: 0,
      link: '/user/profile',
      label: 'Notification',
      icon: 'icon-noti',
    },
    {
      id: 1,
      link: '/user/update',
      label: 'Edit Profile',
      icon: 'icon-profile',
    },
    {
      id: 2,
      link: '/user/change-password',
      label: 'PIN Update',
      icon: 'icon-pin',
    },
    {
      id: 3,
      link: '/user/invoices',
      label: 'Invoices',
      icon: 'icon-invoice',
    },
    {
      id: 4,
      link: '/user/my-group',
      label: 'My Group',
      icon: 'icon-group',
    },
  ]

  const toggleModalPayment = () => setIsShowModalPayment(!isShowModalPayment)
  const toggleModalNotify = () => setIsOpenModalNotify(!isOpenModalNotify)

  const handleSwitchVersion = () => {
    if (screen.width >= 1203) {
      if (currentUser?.features?.premiumAccount) {
        setDeviceUser(checkDeviceUser === DESKTOP ? PHONE : DESKTOP)
        onSwitchDevice(checkDeviceUser === DESKTOP ? PHONE : DESKTOP)
        dispatch(getMyGroups)
      } else {
        addToast('You have not activated this feature yet', {
          appearance: 'warning',
          autoDismiss: true,
        })
      }
    } else {
      addToast('desktop model only use for device desktop', {
        appearance: 'warning',
        autoDismiss: true,
      })
    }
  }

  const triggerFeature = async () => {
    try {
      if (localStorage.getItem('newestTransaction')) {
        const newestTransaction = JSON.parse(localStorage.getItem('newestTransaction'))
        if (newestTransaction?.isFirst) {
          await dispatch(
            triggerPremiumForFirst({
              packageType: PACKAGE_TYPE_PREMIUM_ACCOUNT,
              paymentRequestId: reference,
            })
          )
        }
      }
    } catch (error) {
      addToast(error?.msgReps || error.message, {
        appearance: 'error',
        autoDismiss: true,
      })
    }
  }

  useEffect(() => {
    setCurrentUser(getUserInfo())
    dispatch(getListPackage({ packageType: PACKAGE_TYPE_PREMIUM_ACCOUNT }))
  }, [JSON.stringify(getUserInfo())])

  useEffect(() => {
    if (currentUser) {
      const isAdminOfGroup = currentUser?.groups?.some((item) => item.isAdmin === GROUP_IS_ADMIN)
      const isHostOfGroup = currentUser?.groups?.some((item) => item.isHost === GROUP_IS_HOST)
      setIsCheckAdminOfGroup(isAdminOfGroup || isHostOfGroup)
    }
  }, [currentUser])

  useEffect(() => {
    if (listPackage?.length) {
      setNewestPackage(listPackage[0])
    }
  }, [JSON.stringify(listPackage)])

  const updatePremiumFeatures = (isActive) => {
    const updatedFeatures = {
      ...userInfo,
      features: {
        premiumAccount: isActive,
      },
    }
    setCurrentUser(updatedFeatures)
    setUserInfo(updatedFeatures)
  }

  const processNewestTransaction = async (newestTransaction) => {
    if (newestTransaction.isFirst) {
      await triggerFeature()
      localStorage.setItem(
        'newestTransaction',
        JSON.stringify({ ...newestTransaction, isFirst: false })
      )
    }
    dispatch(getListInvoicePremiumAccount())
  }

  const updateUserInfo = async () => {
    try {
      const res = await userApi.getUser()
      setUserInfo(res.msgResp)
      setCurrentUser(res.msgResp)
    } catch (error) {
      addToast(error.msgResp || error.message, { appearance: 'error', autoDismiss: true })
    }
  }

  const handleInactiveStatus = () => {
    updatePremiumFeatures(false)
  }

  const handleActiveStatus = async () => {
    const newestTransaction = JSON.parse(localStorage.getItem('newestTransaction'))
    if (newestTransaction) {
      await processNewestTransaction(newestTransaction)
      await updateUserInfo()
      setCountTrigger((prev) => prev + 1)
    }
    updatePremiumFeatures(true)
  }

  useEffect(() => {
    const handleReferenceChange = async () => {
      if (!reference) return

      setIsOpenModalNotify(true)

      if (status === 'active' && countTrigger < 1) {
        await handleActiveStatus()
      } else if (status !== 'active') {
        handleInactiveStatus()
      }
    }

    handleReferenceChange()

    return () => {
      localStorage.removeItem('newestTransaction')
    }
  }, [reference, status, countTrigger])

  useEffect(() => {
    if (pathname.includes('/user/invoices')) {
      dispatch(getListInvoicePremiumAccount())
    }
  }, [pathname])

  if (!currentUser?.id) return <div>Loading...</div>

  const { name, photoUrl } = currentUser
  const avatarUrl = photoUrl || process.env.REACT_APP_AVATAR_URL + name

  const confirmPayment = async (e, type) => {
    e.preventDefault()

    // Kiểm tra nếu có phần tử nào trong listInvoicePremiumAccount có status là 1
    const invoicePending = listInvoicePremiumAccount.find(
      (invoice) => invoice.status === CO_BEE_FEATURE_PENDING_STATUS
    )
    if (invoicePending) {
      const { msgResp: paymentUrl } = await invoiceApi.confirmInvoice({
        packageType: PACKAGE_TYPE_PREMIUM_ACCOUNT,
        invoiceId: invoicePending.id,
      })

      window.open(paymentUrl, '_blank')
      return
    }

    setSelectedSubscription(type)
    setIsSubmittedPayment(true)

    try {
      const { msgResp } = await userApi.upgradeToPremium({
        packageType: PACKAGE_TYPE_PREMIUM_ACCOUNT,
        packageId: newestPackage?.id,
        startDate: moment().format('YYYY-MM-DD'),
      })

      localStorage.setItem('newestTransaction', JSON.stringify(msgResp))

      if (msgResp?.paymentLink) {
        window.open(msgResp.paymentLink, '_blank')
      }

      await dispatch(getListInvoicePremiumAccount())
      userApi
        .getUser()
        .then((res) => {
          setUserInfo(res.msgResp)
        })
        .catch((error) => {
          addToast(error.msgResp || error.message, { appearance: 'error', autoDismiss: true })
        })

      addToast('Payment success', { appearance: 'success', autoDismiss: true })
      setIsSubmittedPayment(false)

      toggleModalPayment()
    } catch (error) {
      setIsSubmittedPayment(false)
      addToast(error.msgResp || error.message, { appearance: 'error', autoDismiss: true })
      toggleModalPayment()
    }
  }

  const onSubmit = async () => {
    setCurrentUser({
      ...userInfo,
      features: {
        premiumAccount: true,
      },
    })
    setUserInfo({
      ...userInfo,
      features: {
        premiumAccount: true,
      },
    })

    history.replace({
      pathname: window.location.pathname,
      search: new URLSearchParams().toString(), // Điều này sẽ tạo một chuỗi trống
    })

    toggleModalNotify()
  }

  const onCancelPlan = async (invoice) => {
    try {
      const result = await Swal.fire({
        title: 'Are you sure cancel?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#15cdca',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, cancel it!',
        customClass: {
          container: 'modal-cancel-plan-container',
          title: 'modal-cancel-plan-title',
          content: 'modal-cancel-plan-content',
          confirmButton: 'modal-cancel-plan-button',
          cancelButton: 'modal-cancel-plan-button',
        },
      })

      if (result.isConfirmed) {
        await dispatch(cancelPlan(invoice))
        if (checkDeviceUser === DESKTOP) {
          onSwitchDevice(PHONE)
        }

        setCurrentUser({
          ...userInfo,
          features: {
            premiumAccount: false,
          },
        })
        setUserInfo({
          ...userInfo,
          features: {
            premiumAccount: false,
          },
        })
        localStorage.removeItem('newestTransaction')

        Swal.fire('Canceled!', 'Your plan has been canceled.', 'success')
      }
    } catch (error) {
      Swal.fire('Error!', error.message, 'error')
    }
  }

  return (
    <>
      <div className='profile-page'>
        <div className='profile-avatar-bg'>
          <img src={bgAvatar} alt='' />
        </div>
        <div className='c-form c-form--profile'>
          <Avatar photoUrl={avatarUrl} />
          <div className='upload-name'>{name}</div>
          {currentUser?.features?.premiumAccount && (
            <div
              className='w-100 fw-semibold d-flex align-items-center justify-content-center'
              style={{ color: '#15cdca', fontSize: 12 }}
            >
              (Premium Account
              <FaCheckCircle className='ms-1' />)
            </div>
          )}
          <div className='upgrade-premium'>
            {!currentUser?.features?.premiumAccount ? (
              <p className='upgrade-premium-btn mb-0' onClick={toggleModalPayment}>
                Upgrade to premium account
              </p>
            ) : null}

            {isCheckAdminOfGroup && currentUser?.features?.premiumAccount ? (
              <p onClick={handleSwitchVersion}>
                {checkDeviceUser === DESKTOP
                  ? 'Switch version mobile model'
                  : 'Switch version desktop model'}
              </p>
            ) : null}
          </div>
          {/* <div className='card-info text-end'>
            <Link to='/user/card-info'>
              <MdManageAccounts size={20} />
              <u className='ms-1'>Manage Card Info</u>
            </Link>
          </div> */}
          <div className='manage-premium mb-3'></div>
          <div className='nav-profile'>
            <ul className='justify-content-center'>
              {routes.map((route) => (
                <li key={route.id} style={{ flex: '0 0 22%' }}>
                  <Link to={route.link}>
                    <i className={route.icon}></i>
                    <span>{route.label}</span>
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <Switch>
          <Route path='/user/profile' component={NotificationsPage} />
          <Route path='/user/update' component={UpdateProfilePage} />
          <Route path='/user/change-password' component={ChangePasswordPage} />
          <Route
            path='/user/invoices'
            component={() => (
              <UserContext.Provider value={{ onCancelPlan }}>
                <Invoices />
              </UserContext.Provider>
            )}
          />
          <Route path='/user/my-group' component={GroupList} />
          <Route path='/user/card-info' component={CardInfo} />
        </Switch>
      </div>
      <ModalHitPayPayment
        isShow={isShowModalPayment}
        toggle={toggleModalPayment}
        isSubmittedPayment={isSubmittedPayment}
        confirmPayment={confirmPayment}
        service={{
          type: 'Upgrade premium account',
          price: newestPackage?.discountPrice || newestPackage?.price || 0,
          selectedSubscription,
        }}
      />
      <ModalNotify
        type={status === 'active' ? 'success' : 'warning'}
        isOpen={isOpenModalNotify}
        service={{
          description:
            status === 'active'
              ? 'Your payment has been processed successfully! Thank you for using our service.'
              : 'Your payment failed! Please try again later or contact our support team for help.',
        }}
        toggle={toggleModalNotify}
        onSubmit={onSubmit}
      />
    </>
  )
}

export default Index
