import React from 'react'
import { Spinner } from 'reactstrap'

function LoadingComponent({ className = '', message, style = {} }) {
  return (
    <div className={`text-center ${className}`} style={style}>
      <Spinner color='primary' style={{ width: '1.5rem', height: '1.5rem' }} />
      <p className='mt-1 text-primary mb-0' style={{ fontSize: 14 }}>
        {message || 'Loading...'}
      </p>
    </div>
  )
}

export default LoadingComponent
