import { CollapseContext } from '_context/CollapseMenuContext'
import { clearGroupDetailById, getGroupDetailById } from '_redux/modules/group'
import { getGroupIdSelected } from '_utils/localData'
import React, { useContext, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import Calendar from '../Calendar'
import LeftMenu from '../LeftMenu'
import './style.scss'

function Sidebar() {
  const dispatch = useDispatch()
  const { isCollapse } = useContext(CollapseContext)

  useEffect(() => {
    if (getGroupIdSelected()) {
      dispatch(clearGroupDetailById())
      dispatch(getGroupDetailById(getGroupIdSelected()))
    }
  }, [getGroupIdSelected()])

  return (
    <React.Fragment>
      <div
        className={`${
          isCollapse ? 'menu-shrink' : 'menu-dilate custom-scroll'
        } animate sidebar border-end`}
      >
        <LeftMenu />
        <div className='row wrap-calendar'>
          <Calendar />
        </div>
      </div>
    </React.Fragment>
  )
}

export default Sidebar
