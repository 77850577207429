import React from 'react'

const WrapOption = ({ children, label, isHasShop }) => {
  if (isHasShop) {
    return <optgroup label={label}>{children}</optgroup>
  }

  return <>{children}</>
}

const EventSelectEntity = ({
  register,
  options,
  disabled,
  selected,
  error,
  entityId,
  isHasShop,
}) => {
  const groupItems = options.filter((item) => item.id && !item.shopSlug)
  const shopItems = options.filter((item) => item.shopSlug)

  return (
    <div className='c-form mt-2'>
      <div className='form-group form-group--select'>
        <select
          className='form-control form-control--height'
          value={entityId || ''}
          disabled={disabled}
          {...register}
        >
          <WrapOption label='Group' isHasShop={isHasShop}>
            {groupItems.map((item, index) => (
              <option value={item.id} key={`group-${index}`} defaultValue={selected === item.id}>
                {item.name}
              </option>
            ))}
          </WrapOption>
          {isHasShop ? (
            <WrapOption label='Shop' isHasShop={isHasShop}>
              {shopItems.map((item, index) => (
                <option
                  value={item.id}
                  key={`shop-${index}`}
                  defaultValue={selected === item.shopId}
                >
                  {item.name}
                </option>
              ))}
            </WrapOption>
          ) : null}
        </select>
        {error && <small className='errorMessage'>{error}</small>}
      </div>
    </div>
  )
}

export default EventSelectEntity
