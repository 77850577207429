import Confirm from '_pages/Event/Confirm'
import EventCreate from '_pages/Event/Create'
import EventCreateItem from '_pages/Event/CreateItem'
import DeliveryDetail from '_pages/Event/DeliveryDetail'
import EventDetail from '_pages/Event/EventDetail'
import ManageDelivery from '_pages/Event/ManageDelivery'
import ManageOrder from '_pages/Event/ManageOrder'
import PurchaseDetail from '_pages/Event/PurchaseDetail'
import classNames from 'classnames'
import React from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom'
import SwitchMobileModel from '../components/SwitchMobileModel'
import Delivery from './DeliveryList'
import HostedEvents from './HostedEvents'
import './style.scss'

function CustomWrapper({ children, className = '' }) {
  return (
    <div
      className={classNames('container custom-scrollbar', className)}
      style={{ height: 'calc(100vh - 160px)' }}
    >
      {children}
    </div>
  )
}

function Event() {
  const { path } = useRouteMatch()
  return (
    <>
      <Switch>
        <Route
          path={`${path}/create`}
          render={(props) => (
            <CustomWrapper
              className='custom-scrollbar'
              style={{
                height: 'calc(100vh - 160px)',
              }}
            >
              <EventCreate {...props} />
            </CustomWrapper>
          )}
        />
        <Route
          path={`${path}/update`}
          render={(props) => (
            <CustomWrapper>
              <EventCreate {...props} />
            </CustomWrapper>
          )}
        />
        <Route
          path={`${path}/create-items`}
          render={(props) => (
            <CustomWrapper>
              <EventCreateItem {...props} />
            </CustomWrapper>
          )}
        />
        <Route
          path={`${path}/update-items`}
          render={(props) => (
            <CustomWrapper>
              <EventCreateItem {...props} />
            </CustomWrapper>
          )}
        />
        <Route
          path={`${path}/update-confirm`}
          render={(props) => (
            <CustomWrapper>
              <Confirm {...props} />
            </CustomWrapper>
          )}
        />
        <Route
          path={`${path}/created-confirm`}
          render={(props) => (
            <CustomWrapper>
              <Confirm {...props} />
            </CustomWrapper>
          )}
        />
        <Route
          path={`${path}/delivery-detail`}
          render={(props) => (
            <CustomWrapper>
              <DeliveryDetail {...props} />
            </CustomWrapper>
          )}
        />
        <Route
          path={`${path}/detail`}
          render={(props) => (
            <CustomWrapper>
              <EventDetail {...props} />
            </CustomWrapper>
          )}
        />
        <Route
          path={`${path}/purchase-detail`}
          render={(props) => (
            <CustomWrapper>
              <PurchaseDetail {...props} />
            </CustomWrapper>
          )}
        />
        <Route
          path={`${path}/manage-orders`}
          render={(props) => (
            <CustomWrapper>
              <ManageOrder {...props} />
            </CustomWrapper>
          )}
        />
        <Route
          path={`${path}/manage-delivery`}
          render={(props) => (
            <CustomWrapper>
              <ManageDelivery {...props} />
            </CustomWrapper>
          )}
        />
        <Route
          path={`${path}/detail-shared`}
          render={(props) => (
            <CustomWrapper>
              <SwitchMobileModel {...props} />
            </CustomWrapper>
          )}
        />
      </Switch>

      <Switch>
        <Route path={`${path}/list-hosted-by-group`} component={() => <HostedEvents />} />
        <Route path={`${path}/delivery-list`} component={() => <Delivery />} />
      </Switch>
    </>
  )
}

export default Event
