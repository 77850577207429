import ShopList from '_pages/Shop/ShopList'
import React from 'react'

const CoHootShop = () => (
  <div className='user__profile-desktop' style={{ height: 'calc(100vh - 160px)' }}>
    <div className='mx-auto pb-3 container'>
      <ShopList />
    </div>
  </div>
)

export default CoHootShop
