import MyGroupBuy from '_pages/Group/MyGroupBuy'
import React from 'react'

const MyGroupBuyDesktop = () => (
  <div className='user__profile-desktop' style={{ height: 'calc(100vh - 160px)' }}>
    <div className='mx-auto pb-3 container'>
      <MyGroupBuy />
    </div>
  </div>
)

export default MyGroupBuyDesktop
