/* eslint-disable no-multi-assign */
/* eslint-disable no-return-assign */
/* eslint-disable no-const-assign */
import deliveryApi from '_api/delivery'
import eventApi from '_api/event'
import moment from 'moment'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import ReactImageGallery from 'react-image-gallery'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { useToasts } from 'react-toast-notifications'
import { Spinner } from 'reactstrap'

import {
  addScheduledDelivery,
  addToListDeliveryCreatedByHost,
  clearDeliveryCreatedByHost,
} from '_redux/modules/delivery'
import {
  addEventClosePerMonthByHost,
  addEventCollectionPerMonthByHost,
  clearAllEvents,
  clearEventAndOrder,
  clearMyEvents,
  clearScheduledEvent,
} from '_redux/modules/event'
import {
  appendNewEventToGroup,
  clearPastPurchasedMember,
  getMyGroups,
  updateEventInGroup,
} from '_redux/modules/group'
import { clearDataPurchaseDetail, clearMyOrder } from '_redux/modules/order'
import { DELIVERY_CLOSED, DELIVERY_OPENED, DESKTOP } from '_utils/constant'
import { convertToGMT0, getDateRemainFromNow } from '_utils/function'
import {
  getCreateEventData,
  getDeviceUser,
  getUserInfo,
  removeCreateEventData,
  setCreateEventData,
} from '_utils/localData'

import { DeviceUserContext } from '_context/DeviceUserContext'
import { appendNewEventToShop, getMyShops, updateEventInShop } from '_redux/modules/shop'
import {
  updateEventClosePerMonthByHost,
  updateEventCollectionPerMonthByHost,
} from '../../../redux/modules/event'
import ModalSchedule from '../components/ModalSchedule'
import ProductConfirm from '../components/ProductConfirm'
import './style.scss'

const Confirm = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const refSubmitSchedule = useRef(null)
  const refSubmitEvent = useRef(null)
  const { checkDeviceUser } = useContext(DeviceUserContext)
  const { myGroups } = useSelector((state) => state.group)
  const { myShops } = useSelector((state) => state.shop)
  const { addToast } = useToasts()
  const userInfo = getUserInfo()
  const { id: userId } = userInfo
  const createEventData = getCreateEventData()
  const isEventForShop = createEventData?.shopId

  const [combinedListGroupShop, setCombinedListGroupShop] = useState([])

  if (!createEventData) {
    history.push('/event/create')
  }

  useEffect(() => {
    if (!myShops.length) {
      const fetchData = async () => {
        try {
          await dispatch(getMyShops())
        } catch (error) {
          addToast(error, { appearance: 'error', autoDismiss: true })
        }
      }

      fetchData()
    }
  }, [])

  useEffect(() => {
    if ((myGroups && myGroups.length) || (myShops && myShops.length)) {
      setCombinedListGroupShop(
        [...(myGroups || []), ...(myShops || [])].map((item) => ({
          ...item,
          id: item.id || item.shopId,
          name: item.name || item.shopName,
        }))
      )
    }
  }, [JSON.stringify(myGroups), JSON.stringify(myShops)])

  const entityName = combinedListGroupShop.find(
    (item) => item.id === createEventData?.entityId
  )?.name

  const listImages =
    createEventData?.photoUrls?.map((item) => ({
      original: item,
    })) || []

  const {
    control,
    register,
    handleSubmit,
    watch,
    setValue,
    getValues,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: createEventData,
  })
  const { fields } = useFieldArray({
    control,
    name: 'products',
  })
  const watchFieldArray = watch('products')
  const controlledFields = fields.map((field, _index) => ({
    ...field,
    ...watchFieldArray[_index],
  }))

  const calculateSubItemsPrice = (item) => {
    if (item.defaultQuantity <= 0 || !Array.isArray(item.subItems)) {
      return 0
    }

    return item.subItems.reduce((subTotal, subItem) => {
      const subItemSum = subItem.list.reduce((sum, { price }) => sum + price, 0)
      return subTotal + subItemSum * item.defaultQuantity
    }, 0)
  }

  const calculateTotalPrice = () =>
    controlledFields.reduce((totalAmount, item) => {
      if (!item) return totalAmount

      const itemPrice = item.defaultQuantity * parseFloat(item.price)
      const subItemsPrice = calculateSubItemsPrice(item)

      return totalAmount + itemPrice + subItemsPrice
    }, 0)

  const [openDate, setOpenDate] = useState(new Date(new Date().getTime() + 24 * 3 * 60 * 60 * 1000))
  const [openTime, setOpenTime] = useState('06:00')
  const [totalPriceValue, setTotalPrice] = useState(calculateTotalPrice())
  const [isSubmitScheduled, setIsSubmitScheduled] = useState(false)
  const [modalSchedule, setModalSchedule] = useState(false)
  const toggleSchedule = () => setModalSchedule(!modalSchedule)

  const updateDeliveryZones = async (deliveryZones) => {
    const rememberedDeliveryZones = deliveryZones
      .map(({ name, price }) => ({ name, price }))
      .filter((item) => item.name && item.price >= 0)

    const updateData = { rememberedDeliveryZones }

    try {
      await deliveryApi.updateRememberedDeliveryZones(
        userId,
        updateData,
        isEventForShop,
        createEventData.shopId
      )
    } catch (error) {
      addToast(error?.msgResp, { appearance: 'error', autoDismiss: true })
    }
  }

  const updateExistingEvent = async (postData) => {
    const { shop, selectedShop, ...restPostData } = postData
    if (isEventForShop) {
      restPostData.products = restPostData.products.map((product) => {
        const { id, pid, ...rest } = product
        return { ...rest, id: pid }
      })
    }

    const { msgCode, msgResp } = await eventApi.updateEvent(restPostData, isEventForShop)
    if (msgCode && Number(msgCode) % 100 === 0) {
      dispatch(clearEventAndOrder())
      dispatch(clearAllEvents())
      dispatch(clearMyEvents())
      !isEventForShop
        ? dispatch(updateEventInGroup(postData))
        : dispatch(updateEventInShop(postData))

      if (checkDeviceUser === DESKTOP) {
        dispatch(updateEventClosePerMonthByHost(postData))
        dispatch(updateEventCollectionPerMonthByHost(postData))
      }
      dispatch(clearDataPurchaseDetail())
      dispatch(clearPastPurchasedMember())
      dispatch(clearMyOrder())
      addToast('Update event successfully!', { appearance: 'success', autoDismiss: true })
      removeCreateEventData()

      const manageOrdersUrl = `/event/manage-orders?eventId=${postData.id}${
        isEventForShop ? '&eventShop=true' : ''
      }`
      history.push(manageOrdersUrl)
    } else {
      addToast(msgResp, { appearance: 'error', autoDismiss: true })
    }
  }

  const createNewEvent = async (postData) => {
    postData.products = postData.products.map((product) => {
      const { id, pid, ...rest } = product
      return rest
    })

    const { msgCode, msgResp } = await eventApi.createEvent(postData, isEventForShop)
    if (msgCode === 99999) {
      addToast(msgResp, { appearance: 'error', autoDismiss: true })
      return
    }

    if ('delivery' in msgResp) {
      dispatch(addToListDeliveryCreatedByHost(userId, msgResp.delivery))
    } else if ('shopEvenDelivery' in msgResp) {
      dispatch(addToListDeliveryCreatedByHost(userId, msgResp.shopEvenDelivery))
    }

    dispatch(clearAllEvents())
    dispatch(clearMyEvents())
    dispatch(clearDeliveryCreatedByHost())
    !isEventForShop
      ? dispatch(appendNewEventToGroup(msgResp))
      : dispatch(appendNewEventToShop(msgResp))

    if (checkDeviceUser === DESKTOP) {
      dispatch(addEventClosePerMonthByHost(msgResp))
      dispatch(addEventCollectionPerMonthByHost(msgResp))
    }
    addToast('Create event successfully!', { appearance: 'success', autoDismiss: true })
    removeCreateEventData()

    const manageOrdersUrl = `/event/manage-orders?eventId=${msgResp.id}${
      isEventForShop ? '&eventShop=true' : ''
    }`

    // if (postData.deliveryStatus === DELIVERY_OPENED) {
    //   const dynamicEntityId = postData?.groupId ? 'groupId' : 'shopId'
    //   const payload = {
    //     deliveryTime: postData.deliveryTime,
    //     deliveryDuration: postData.deliveryDuration,
    //     [dynamicEntityId]: postData?.groupId || postData?.shopId,
    //     isEventShop: isEventForShop,
    //   }

    //   // eslint-disable-next-line max-len
    //   const { msgResp: msgRespDelivery } = await deliveryApi.createDeliveryByHost(
    //     payload,
    //     payload.isEventShop
    //   )

    //   console.log('msgRespDelivery', msgRespDelivery)

    //   dispatch(addToListDeliveryCreatedByHost(userId, msgRespDelivery))
    //   addToast('Created delivery successfully!', { appearance: 'success', autoDismiss: true })
    // }

    history.push(manageOrdersUrl)
  }

  const handleError = (error) => {
    const errorMessage = error?.msgResp || 'Something went wrong!'
    addToast(errorMessage, { appearance: 'error', autoDismiss: true })
  }

  const handleOnSubmitEvent = handleSubmit(async (data) => {
    try {
      const {
        products,
        deliveryOption = {},
        closingTime,
        pickupTime,
        addDelivery,
        deliveryTime,
        paynow,
        payTogether,
        ...restData
      } = data

      const transformedProducts = products?.map((product) => {
        const updatedProduct = {
          ...product,
          subItems: product.subItems?.map((sub) => ({ ...sub, required: sub.required ? 1 : 0 })),
        }
        return !isEventForShop
          ? { ...updatedProduct, id: product.pid }
          : { ...updatedProduct, shopProductId: product.shopProductId }
      })

      const postData = {
        ...restData,
        closingTime: convertToGMT0(closingTime),
        pickupTime: convertToGMT0(pickupTime),
        deliveryTime: deliveryTime ? convertToGMT0(deliveryTime) : null,
        products: transformedProducts,
        paynow: paynow === true || paynow === 1 ? 0 : 1,
        payable: paynow === true || paynow === 1 ? 0 : 1,
        payTogether: payTogether === true || payTogether === 1 ? 1 : 0,
        createdUserPhotoUrl: userInfo?.photoUrl,
        deliveryStatus: addDelivery ? DELIVERY_OPENED : DELIVERY_CLOSED,
      }

      if (Object.keys(deliveryOption).length && addDelivery) {
        await updateDeliveryZones(deliveryOption.deliveryZones)
      }

      if (postData.id) {
        await updateExistingEvent(postData)
      } else {
        await createNewEvent(postData)
      }
    } catch (error) {
      handleError(error)
    }
  })

  const updateScheduledEvent = async (postData) => {
    const { msgCode, msgResp } = await eventApi.updateScheduledEvent(postData, isEventForShop)
    if (msgCode && Number(msgCode) % 100 === 0) {
      return { success: true, message: 'Update scheduled event successfully!' }
    }
    throw new Error(msgResp)
  }

  const createScheduledEvent = async (postData) => {
    const { msgCode, msgResp } = await eventApi.createScheduledEvent(postData, isEventForShop)
    if (msgCode === 99999) {
      throw new Error(msgResp)
    }

    if ('delivery' in msgResp) {
      dispatch(addScheduledDelivery(userId, msgResp.delivery))
    }

    return { success: true, message: 'Create scheduled event successfully!' }
  }

  const handleSuccessfulSchedule = (result) => {
    addToast(result.message, { appearance: 'success', autoDismiss: true })
    removeCreateEventData()
    dispatch(clearScheduledEvent)
    const route =
      getDeviceUser() === DESKTOP ? '/event/list-hosted-by-group' : '/event/list-my-events'
    history.push(route)
  }

  const handleScheduleError = (error) => {
    const errorMessage = error?.msgResp || 'Something went wrong!'
    addToast(errorMessage, { appearance: 'error', autoDismiss: true })
    toggleSchedule()
  }

  const handleOnSubmitSchedule = handleSubmit(async (data) => {
    try {
      const {
        products,
        closingTime,
        pickupTime,
        deliveryOption = {},
        addDelivery,
        deliveryTime,
        paynow,
        payTogether,
        ...restData
      } = data

      const transformedProducts = products?.map((product) => {
        const updatedProduct = {
          ...product,
          subItems: product.subItems?.map((sub) => ({ ...sub, required: sub.required ? 1 : 0 })),
        }
        return !isEventForShop
          ? { ...updatedProduct, id: product.pid }
          : { ...updatedProduct, shopProductId: product.shopProductId }
      })

      const adjustTimeForPM = (timeString) => {
        const [time, period] = timeString.split(' ')
        if (period === 'PM') {
          const [hours, minutes] = time.split(':')
          const adjustedHours = hours === '12' ? '12' : String(Number(hours) + 12)
          return `${adjustedHours}:${minutes}`
        }
        return time
      }

      const openTimeLocal = `${moment(openDate).format('DD-MM-YYYY')} ${adjustTimeForPM(openTime)}`

      const times = {
        pickupTime: convertToGMT0(pickupTime),
        closingTime: convertToGMT0(closingTime),
        openTime: convertToGMT0(openTimeLocal.replace(/ AM| PM/, '')),
        deliveryTime: convertToGMT0(deliveryTime),
        currentTime: convertToGMT0(moment().format('DD-MM-YYYY HH:mm')),
      }

      if (
        moment(times.openTime, 'DD-MM-YYYY HH:mm').valueOf() <=
        moment(times.currentTime, 'DD-MM-YYYY HH:mm').valueOf()
      ) {
        addToast('The event cannot be scheduled before the current time.', {
          appearance: 'error',
          autoDismiss: true,
        })
        return toggleSchedule()
      }

      if (
        moment(times.openTime, 'DD-MM-YYYY HH:mm') >= moment(times.closingTime, 'DD-MM-YYYY HH:mm')
      ) {
        addToast('The closing time must be after the schedule time.', {
          appearance: 'error',
          autoDismiss: true,
        })
        return toggleSchedule()
      }

      if (Object.keys(deliveryOption).length && addDelivery) {
        // const dynamicEntityId = createEventData?.groupId ? 'groupId' : 'shopId'
        // const dynamicEntityName = createEventData?.groupId ? 'groupName' : 'shopName'
        // console.log('createEventData', createEventData)

        await updateDeliveryZones(deliveryOption.deliveryZones)
      }

      const postData = {
        ...restData,
        products: transformedProducts,
        ...times,
        paynow: paynow === true || paynow === 1 ? 0 : 1,
        payable: paynow === true || paynow === 1 ? 0 : 1,
        payTogether: payTogether === true || payTogether === 1 ? 1 : 0,
        createdUserPhotoUrl: userInfo?.photoUrl,
        deliveryStatus: addDelivery ? DELIVERY_OPENED : DELIVERY_CLOSED,
      }

      setIsSubmitScheduled(true)

      const result = postData.id
        ? await updateScheduledEvent(postData)
        : await createScheduledEvent(postData)

      handleSuccessfulSchedule(result)
    } catch (error) {
      handleScheduleError(error)
    } finally {
      setIsSubmitScheduled(false)
    }
  })

  useEffect(() => {
    setTotalPrice(calculateTotalPrice())
  }, [controlledFields])

  useEffect(() => {
    const getGroups = () => {
      try {
        dispatch(getMyGroups)
      } catch (error) {
        addToast(error, { appearance: 'error', autoDismiss: true })
      }
    }

    getGroups()
  }, [])

  useEffect(() => {
    if (createEventData) {
      if (createEventData?.openTime) {
        const _openDate = createEventData.openTime.split(' ')[0].split('-').reverse()
        const _openTime = createEventData.openTime.split(' ')[1]
        setOpenDate(new Date(_openDate[0], _openDate[1] - 1, _openDate[2]))
        setOpenTime(_openTime)
      }
    }
  }, [createEventData?.openTime])

  const onChangeOpenTime = (value) => {
    setOpenTime(value)
  }

  return (
    <form id='confirm-form' className='row-top created-confirm-page'>
      <div className='group-list group-list--event detail-event group-list--event02'>
        <div className='group-list__item'>
          <div className='group-list__img'>
            <div className='c-image'>
              <img
                src={userInfo?.photoUrl || process.env.REACT_APP_AVATAR_URL + userInfo.name}
                alt=''
              />
            </div>
          </div>
          <div className='group-list__info'>
            <h3>{userInfo?.name}</h3>
            <p>{entityName || ''}</p>
          </div>
          <div className='group-list__btn text-right'>
            <span className='txt-date'>
              <span>{getDateRemainFromNow(createEventData?.closingTime)}</span> left
            </span>
            <span className='txt-number'>0 BUY</span>
          </div>
          <div className='group-list__des'>
            {listImages?.length > 0 && (
              <ReactImageGallery
                items={listImages}
                lazyLoad
                autoPlay
                showPlayButton={false}
                showNav={false}
                showBullets={true}
                showThumbnails={false}
              />
            )}

            <h4>{createEventData?.title}</h4>
            <p>{createEventData?.description}</p>
          </div>
          <div className='group-list__detail'>
            <div className='row'>
              <div className='col-4'>Closing:</div>
              <div className='col-8 align-self-center'>
                <p>{createEventData?.closingTime.split(' ')[0]}</p>
              </div>
            </div>
            <div className='row'>
              <div className='col-4'>
                <p>Collection:</p>
                <p>Address:</p>
              </div>
              <div className='col-8 text-blue'>
                <p>{createEventData?.pickupTime.split(' ')[0]}</p>
                <p>{createEventData?.pickupAddress}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='detail-list-news'>
        {controlledFields?.map((product, index) => (
          <ProductConfirm
            product={product}
            productIndex={index}
            key={index}
            setValue={setValue}
            register={register(`products.${index}.defaultQuantity`)}
          />
        ))}
      </div>
      <div className='nav-amount'>
        <div className='row'>
          <div className='col-8 '>
            <p className={`note fs-10 ${!createEventData?.paynow && 'is-close'}`}>
              <b>PAY LATER:</b>
              <br />
              Host requests payment to be made after the event closed. Sharing of delivery charges,
              discounts and admin fee may apply.
            </p>
          </div>
          <div className='col-4'>
            <div className='sum'>
              <p>
                <span className='c-txt-org'>
                  $<span>{totalPriceValue?.toFixed(2)}</span>
                </span>
              </p>
              <p>
                <span>
                  Admin Fee: $<span>{createEventData?.adminCost?.toFixed(2)}</span>
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <small style={{ color: '#ff9900' }}></small>
      <div className='c-form'>
        <div className='row justify-content-center'>
          <div className='col-6'>
            <button
              className='btn btn--gray'
              onClick={() => {
                setCreateEventData(getValues())
                if (createEventData?.id) {
                  history.push(`/event/update-items?id=${createEventData?.id}`)
                } else {
                  history.push('/event/create-items')
                }
              }}
            >
              PREVIOUS
            </button>
          </div>
          <div className='col-6'>
            <button
              onClick={handleOnSubmitEvent}
              disabled={isSubmitting || createEventData?.openTime}
              ref={refSubmitEvent}
              type='submit'
              className='btn btn--solid h-100'
            >
              {isSubmitting ? <Spinner color='light' size='sm' /> : 'PUBLISH'}
            </button>
          </div>
        </div>
        {userInfo?.features?.premiumAccount && createEventData?.groupId ? (
          <div
            style={{
              textAlign: 'center',
              fontSize: '0.9rem',
              marginTop: '20px',
              textDecoration: 'underline',
              color: '#d1274d',
              cursor: 'pointer',
            }}
            onClick={toggleSchedule}
          >
            Click here to schedule a later date to publish
          </div>
        ) : null}
      </div>
      <ModalSchedule
        idForm='confirm-form'
        modalSchedule={modalSchedule}
        toggleSchedule={toggleSchedule}
        openDate={openDate}
        openTime={openTime}
        onChangeOpenTime={onChangeOpenTime}
        onChangeOpenDate={setOpenDate}
        isSubmitScheduled={isSubmitScheduled}
        refSubmitButton={refSubmitSchedule}
        handleOnSubmitSchedule={handleOnSubmitSchedule}
      />
    </form>
  )
}

export default Confirm
