/* eslint-disable no-confusing-arrow */
/* eslint-disable no-else-return */
/* eslint-disable function-paren-newline */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-extra-semi */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable no-eval */
/* eslint-disable no-useless-escape */
import {
  AVAILABLE_STOCK_DEFAULT,
  BUYER_CANCELED,
  BUYER_COLLECTED,
  BUYER_ORDER_CANCELED,
  BUYER_ORDER_COLLECTED,
  BUYER_ORDER_PAID,
  BUYER_ORDER_UNPAID,
  BUYER_PAID,
  BUYER_REFUNDED,
  BUYER_UNPAID,
  DATE_FORMAT,
  DEFAULT_COST,
  DEFAULT_QUANTITY,
  DELIVERY_BUYER_CANCELLED,
  DELIVERY_HOST_CANCELLED,
  END_DAY,
  FIRST_INDEX_PRODUCT_CSV,
  FIRST_INDEX_SUBITEM_CSV,
  GROUP_DONT_APPROVAL,
  HOST_ORDER_CANCELED,
  HOST_ORDER_COLLECTED,
  HOST_ORDER_UNPAID,
  HOST_ORDER_VERIFIED,
  LIMIT_PER_ORDER_DEFAULT,
  LIMIT_TEXT,
  LIST_TIME,
  LIST_TIME_2,
  ORDER_CANCELED,
} from '_utils/constant'

import CryptoJS from 'crypto-js'
import { isEmpty } from 'lodash'
import moment from 'moment'
import * as XLSX from 'xlsx'
import { MINIMUM_ORDER_PER_PRODUCT, OPTION_CURRENCY_SG } from './constant'

export function clearParamUrl(paramsToClear = [], { history, location }) {
  const searchParams = new URLSearchParams(location.search)

  if (paramsToClear.length === 0) {
    history.replace({ search: '' })
  } else {
    paramsToClear.forEach((param) => searchParams.delete(param))
    history.replace({ search: searchParams.toString() })
  }
}

export function decryptHostName(encryptedHostName, secretKey) {
  const bytes = CryptoJS.AES.decrypt(encryptedHostName, secretKey)
  const decryptedText = bytes.toString(CryptoJS.enc.Utf8)
  return decryptedText
}

export function decryptParam(param, secretKey) {
  const bytes = CryptoJS.AES.decrypt(param, secretKey)
  const decryptedText = bytes.toString(CryptoJS.enc.Utf8)
  return decryptedText
}

export function flexibleSearch(fullString, searchTerm) {
  const escapedSearchTerm = searchTerm.toLowerCase().replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
  const regex = new RegExp(escapedSearchTerm.toLowerCase().replace(/\s+/g, '.*'), 'i')
  return regex.test(fullString.toLowerCase())
}

export function uniqueEvents(list1, list2) {
  const events1 = list1 ?? []
  const events2 = list2 ?? []

  if (events1.length === 0 && events2.length === 0) {
    return []
  }

  const allEvents = [...events1, ...events2]

  const uniqueEventsFiltered = Object.fromEntries(
    new Map(allEvents.map((event) => [event.id, event]))
  )

  return Object.values(uniqueEventsFiltered)
}

export function truncate(text, limit) {
  if (text.length <= limit) {
    return text
  }
  return `${text.slice(0, limit)}...`
}

export const randomDate = (start, end) =>
  new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()))

export const formatError = (code) => {
  switch (code) {
    case 1001:
      return 'Account not exist'
    case 1003:
      return 'Account not exist'
    case 1005:
      return 'Account not exist'
    case 1007:
      return 'Account not found'
    case 1009:
      return 'Request in progress'
    case 1023:
      return 'Email is taken'
    default:
      return 'Some thing went wrong'
  }
}

export const removeValuesFromArray = (valuesArray, value) => {
  const valueIndex = valuesArray.findIndex((entry) => entry === value)
  if (valueIndex === -1) {
    return
  }
  valuesArray.splice(valueIndex, 1)
}

export const arrayToString = (array) => array.toString().replaceAll(',', '')

export const getDaysLeft = (_date1) => {
  const date1 = _date1.split(' ')[0].replaceAll('-', ' ').split(' ')
  const date2 = moment().format('DD MM YYYY').split(' ')
  date1.map((i) => parseInt(i, 10))
  date2.map((i) => parseInt(i, 10))
  const oneDay = 24 * 60 * 60 * 1000
  const firstDate = new Date(...date1.reverse())
  const secondDate = new Date(...date2.reverse())

  const diffDays = Math.round(Math.abs((firstDate - secondDate) / oneDay))
  return diffDays
}
export const formatDay = (timestamp) => {
  const d = new Date(timestamp * 1000)
  const date = d.getDate()
  const month = d.getMonth() + 1
  const year = d.getFullYear()
  return `${date}-${month}-${year}`
}

export const generateSecretCode = () => {
  let magic = 0
  const timestamp = moment().unix()
  try {
    const code =
      "({a1:function(a) { for (var b = [], c = 0; c < a.length; c += 2) b.push(parseInt(a.substr(c, 2), 16)); return b; }, a2:function(a) { var b = this.a1(a); var c = ''; for (let i = 0; i < b.length; i++) { c += String.fromCharCode(b[i] ^ 222); } return eval(c); }})-----a8bfacfebffee3fee7e7e7e7e7e7e7e7e7e7e5fea8bfacfebcfee3feefecedeaebeaedecefe5fef6f6aaa7aebbb1b8fea9b7b0bab1a9fee3e3e3fef9b1bcb4bbbdaaf9f7f4bff7fbbce5"
    const codes = code.split('-----')
    magic = eval(codes[0]).a2(codes[1])
    return `${timestamp}, ${timestamp % magic}`
  } catch (e) {}
}

export const dec2alphabet = (number) => {
  const ALPHABET = [
    'A',
    'B',
    'C',
    'D',
    'E',
    'F',
    'G',
    'H',
    'I',
    'J',
    'K',
    'L',
    'M',
    'N',
    'O',
    'P',
    'Q',
    'R',
    'S',
    'T',
    'U',
    'V',
    'W',
    'X',
    'Y',
    'Z',
  ]
  if (number < 0) return null
  if (number === 0) return 'A'
  let alphaNum = ''
  let q = number
  while (q > 0) {
    const r = (q - 1) % 26
    q = parseInt((q - r) / 26, 10)
    alphaNum += ALPHABET[r]
  }
  // nếu number tới số 28 là chuỗi sẽ sai ! AB -> BA
  return number >= 28 ? alphaNum.split('').reverse().join('') : alphaNum // NEW CODE
  // return alphaNum // OLD CODE
}

export const idGenerator = () => `_${Math.random().toString(36).substr(2, 9)}`

export const getSubItemInFile = (sheet) => {
  let row = FIRST_INDEX_SUBITEM_CSV
  let subItems = []
  while (typeof x === 'undefined') {
    const indexCell = sheet[`A${row}`]

    if (!indexCell) break

    const subItemIndex = indexCell.v
    const subItemType = sheet[`B${row}`] ? sheet[`B${row}`].v : ''
    const subItemRequired = sheet[`C${row}`] ? sheet[`C${row}`].v : 0
    const subItemList = sheet[`D${row}`] ? sheet[`D${row}`].v.split('\n') : []

    const listTemp = subItemList
      .filter((subItem) => {
        if (subItem) return true
        return false
      })
      .map((subItem) => {
        const temps = subItem.split(':')
        return {
          name: temps[0],
          price: temps[1] !== '' ? parseFloat(temps[1].replace('$', '').trim()) : DEFAULT_COST,
        }
      })

    subItems = [
      ...subItems,
      {
        idx: subItemIndex,
        type: subItemType,
        required: subItemRequired,
        list: listTemp.length ? listTemp : [{ name: '', price: DEFAULT_COST }],
      },
    ]

    row += 1
  }

  return subItems
}

export const validateDate = (date) => moment(date, 'DD-MM-YYYY HH:mm').isAfter(moment())

export const readFileToCreateEvent = (sheet) => {
  // Read data from csv
  const title = sheet.B2.v
  const description = sheet.B3 ? sheet.B3.v : ''
  const pickupAddress = sheet.B4 ? sheet.B4.v : ''
  const closingDate = sheet.B5.w
  const pickupDate = sheet.B6.w
  const payLater = sheet.B7.v
  const adminCost = sheet.B8 ? sheet.B8.v : DEFAULT_COST
  const photoUrl = sheet.B9 ? sheet.B9.v : ''

  const event = {
    title,
    description,
    pickupAddress,
    paynow: payLater !== 'TRUE',
    payable: payLater !== 'TRUE',
    adminCost,
    deliveryCost: DEFAULT_COST,
    photoUrls: photoUrl ? [photoUrl] : [],
    closingTime: `${moment(closingDate).format('DD-MM-YYYY')} ${END_DAY}`,
    pickupTime: `${moment(pickupDate).format('DD-MM-YYYY')} ${END_DAY}`,
  }

  // Get subitems in file csv
  const itemList = []
  let colIdx = FIRST_INDEX_PRODUCT_CSV
  const SUBITEMS = getSubItemInFile(sheet)

  while (typeof x === 'undefined') {
    const item = {}
    // PROBLEM IS HERE (START)
    const col = dec2alphabet(colIdx)
    const nameCell = sheet[`${col}11`]
    const priceCell = sheet[`${col}12`]
    if (!nameCell || !priceCell) break

    // PROBLEM IS HERE (END)
    const avaStkCell = sheet[`${col}13`]
    const imgUrlCell = sheet[`${col}14`]
    const limitPerOrderCell = sheet[`${col}15`]
    const subItemsCell = sheet[`${col}16`]

    const subItemsIndex = subItemsCell ? subItemsCell.v.split(',') : []

    const productPhotoUrl = imgUrlCell ? imgUrlCell.v : ''
    item.idx = colIdx - FIRST_INDEX_PRODUCT_CSV
    item.name = nameCell.v || ''
    item.price = priceCell.v || DEFAULT_COST
    item.maxQuantity = avaStkCell ? avaStkCell.v : AVAILABLE_STOCK_DEFAULT
    item.defaultQuantity = DEFAULT_QUANTITY
    item.limitPerOrder = limitPerOrderCell ? limitPerOrderCell.v : LIMIT_PER_ORDER_DEFAULT
    item.photoUrls = productPhotoUrl ? [productPhotoUrl] : []
    item.minOrderQty = MINIMUM_ORDER_PER_PRODUCT
    item.subItems = subItemsIndex.map((idx) => {
      const subItemTemp = { ...SUBITEMS.find((subItem) => subItem.idx.toString() === idx) }

      delete subItemTemp.idx

      if (subItemTemp?.list?.length) {
        subItemTemp.list = [
          ...subItemTemp.list.map((subitem) => {
            const opt = { ...subitem }
            return { ...opt }
          }),
        ]
      }

      return { ...subItemTemp }
    })

    itemList.push(item)
    colIdx += 1
  }

  return { ...event, products: itemList }
}

export const getDateRemainFromNow = (date, getDaysRemain = false) => {
  if (!date) return
  if (!validateDate(date)) return '0 day'
  if (getDaysRemain) {
    const timestampRemain = moment(date, 'DD-MM-YYYY HH:mm').diff(moment())
    const timestamp24Hour = 1000 * 60 * 60 * 24
    if (timestampRemain < 0) {
      return '0 day'
    }
    if (timestampRemain < timestamp24Hour) {
      return 'A day'
    }
    return moment(date, 'DD-MM-YYYY HH:mm').fromNow(true)
  } else {
    return moment(date, 'DD-MM-YYYY HH:mm').fromNow(true)
  }
}

export const formatDate = ({ date, format = 'DD-MM-YYYY' }) => {
  if (!date) return
  return moment(date, 'DD-MM-YYYY').format(format)
}

export const getListPlacedBy = (listName) => {
  if (!listName.length) return ''
  return [...new Set(listName)].join(', ')
}

export const exportToCsv = (filename, rows) => {
  const processRow = (row) => {
    let finalVal = ''
    for (let j = 0; j < row.length; j++) {
      let innerValue = row[j] === null ? '' : row[j].toString()
      if (row[j] instanceof Date) {
        innerValue = row[j].toLocaleString()
      }
      let result = innerValue.replace(/"/g, '""')
      if (result.search(/("|,|\n)/g) >= 0) result = `"${result}"`
      if (j > 0) finalVal += ','
      finalVal += result
    }
    return `${finalVal}\n`
  }

  let csvFile = '\uFEFF'

  for (let i = 0; i < rows.length; i++) {
    csvFile += processRow(rows[i])
  }

  const blob = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' })
  if (navigator.msSaveBlob) {
    // IE 10+
    navigator.msSaveBlob(blob, filename)
  } else {
    const link = document.createElement('a')
    if (link.download !== undefined) {
      // feature detection
      // Browsers that support HTML5 download attribute
      const url = URL.createObjectURL(blob)
      link.setAttribute('href', url)
      link.setAttribute('download', filename)
      link.style.visibility = 'hidden'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }
  }
}

export const normalizeName = (name) => name.replace(/\s+/g, '-').toLowerCase()

export const isDisableEvent = (eStatus, buyerStatus, ePickupTime, ePickupDuration) => {
  if (!eStatus || !buyerStatus || !ePickupTime) return true
  const timeStart = Number(ePickupTime.split(' ')[1].split(':')[0])
  const timeEnd = timeStart + ePickupDuration / 3600
  let newEPickupTime = ''
  if (timeEnd <= 24) {
    newEPickupTime = `${ePickupTime.split(' ')[0]} ${timeEnd}:00`
  } else {
    newEPickupTime = ePickupTime
  }
  return (
    eStatus === ORDER_CANCELED ||
    buyerStatus === ORDER_CANCELED ||
    moment().isAfter(moment(newEPickupTime, 'DD-MM-YYYY HH:mm'))
  )
}

// sort list order by disable order and time created
export const sortListOrder = (listOrder) => {
  if (!listOrder || !listOrder.length) return []
  return listOrder.sort((first, second) => {
    const fDisable = isDisableEvent(
      first.status,
      first.buyerStatus,
      first.ePickupTime,
      first.ePickupDuration
    )
    const sDisable = isDisableEvent(
      second.status,
      second.buyerStatus,
      second.ePickupTime,
      second.ePickupDuration
    )
    if ((fDisable && !sDisable) || first.createdAt - second.createdAt < 0) return 1
    return -1
  })
}
export const sortListMyOrder1 = (listOrder) => {
  if (!listOrder || !listOrder.length) return []
  const listOrder1 = listOrder.sort((first, second) => {
    if (
      moment(first.ePickupTime, 'DD-MM-YYYY HH:mm').isAfter(
        moment(second.ePickupTime, 'DD-MM-YYYY HH:mm')
      )
    ) {
      return 1
    }
    return -1
  })
  const listOrder2 = listOrder1.filter(
    (item) => !isDisableEvent(item.status, item.buyerStatus, item.ePickupTime, item.ePickupDuration)
  )
  const listOrder3 = listOrder1.filter((item) =>
    isDisableEvent(item.status, item.buyerStatus, item.ePickupTime, item.ePickupDuration)
  )
  const listOrder4 = listOrder3.sort((first, second) => {
    if (
      moment(first.ePickupTime, 'DD-MM-YYYY HH:mm').isAfter(
        moment(second.ePickupTime, 'DD-MM-YYYY HH:mm')
      )
    ) {
      return 1
    }
    return -1
  })
  return [...listOrder2, ...listOrder4]
}

export const filterOrdersByUserId = (listOrder, uid) => {
  if (!listOrder || !listOrder.length) return []
  return listOrder.filter((o) => o.uid === uid)
}

export const convertStatusNumberToText = (statusCode) => {
  let text = ''
  switch (statusCode) {
    case HOST_ORDER_CANCELED:
      text = 'Canceled'
      break
    case HOST_ORDER_UNPAID:
      text = 'Unpaid'
      break
    case HOST_ORDER_VERIFIED:
      text = 'Verified'
      break
    case HOST_ORDER_COLLECTED:
      text = 'Collected'
      break
    case BUYER_ORDER_CANCELED:
      text = 'Canceled'
      break
    case BUYER_ORDER_UNPAID:
      text = 'Unpaid'
      break
    case BUYER_ORDER_PAID:
      text = 'Paid'
      break
    case BUYER_ORDER_COLLECTED:
      text = 'Collected'
      break
    default:
      break
  }

  return text
}

export const cartesian = (...args) => {
  const t = []
  for (let i = 0; i < args.length; i++) {
    const element = args[i]
    for (let j = 0; j < element.length; j++) {
      const element1 = element[j]
      t.push(element1)
    }
  }
  const r = []
  const max = t.length - 1
  function helper(arr, i) {
    for (let j = 0, l = t[i].length; j < l; j++) {
      const a = arr.slice(0) // clone arr
      if (t[i][j] !== '') {
        a.push(t[i][j])
      }
      if (i === max) r.push(a)
      else helper(a, i + 1)
    }
  }
  helper([], 0)
  return r
}

export const groupBy = (xs, key) =>
  xs.reduce((rv, x) => {
    ;(rv[x[key]] = rv[x[key]] || []).push(x)
    return rv
  }, {})

export const sum = (array) => {
  const holder = {}
  array.forEach((d) => {
    if (holder.hasOwnProperty(d.pName)) {
      holder[d.pName] += d.pQuantity
    } else {
      holder[d.pName] = d.pQuantity
    }
  })

  const array2 = []

  for (const prop in holder) {
    if ({}.hasOwnProperty.call(holder, prop)) {
      array2.push({ pNameAndPid: prop, pQuantity: holder[prop] })
    }
  }

  return array2
}

// sort list event by time created
export const sortListEvents = (listEvent) => {
  if (!listEvent || !listEvent.length) return []
  return listEvent.sort((first, second) => {
    if (first.createdAt - second.createdAt < 0) return 1
    return -1
  })
}

export const sortListData = (list, propertyName) => {
  if (!list || !list.length) return []
  return list.sort((first, second) => {
    if (first[propertyName] - second[propertyName] < 0) return 1
    return -1
  })
}

export const sortListEventByClosingDate = (listEvent) => {
  if (!listEvent || !listEvent.length) return []
  return listEvent.sort((first, second) => {
    if (first.closingDate - second.closingDate) return 1
    return -1
  })
}

// sort list event by pickupTimestamp
export const sortListEventsByPickupTimestamp = (listEvent, decrease = true) => {
  if (!listEvent || !listEvent.length) return []
  return listEvent.sort((first, second) => {
    if (first.pickupTimestamp - second.pickupTimestamp < 0) return decrease ? 1 : -1
    return decrease ? -1 : 1
  })
}

export const parseJwt = (token) => {
  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join('')
  )
  const result = JSON.parse(jsonPayload)
  result.id = result.uid
  delete result.uid
  return result
}

export const shortDescription = ({ description, maxWord = LIMIT_TEXT }) => {
  if (!description) return ''

  const descWords = description.split(' ').length

  if (descWords <= maxWord) return description

  const shortDesc = description.split(' ').slice(0, maxWord).join(' ')

  return `${shortDesc}...`
}

// read levenshtein distance or edit distance for more information
export const editDistance = (s1 = '', s2 = '') => {
  s1 = s1.toLowerCase()
  s2 = s2.toLowerCase()

  const costs = []
  for (let i = 0; i <= s1.length; i++) {
    let lastValue = i
    for (let j = 0; j <= s2.length; j++) {
      if (i === 0) {
        costs[j] = j
      } else if (j > 0) {
        let newValue = costs[j - 1]
        if (s1[i - 1] !== s2[j - 1]) {
          newValue = Math.min(Math.min(newValue, lastValue), costs[j]) + 1
        }
        costs[j - 1] = lastValue
        lastValue = newValue
      }
    }
    if (i > 0) {
      costs[s2.length] = lastValue
    }
  }
  return costs[s2.length]
}

export const calculatePercentMatch = (s1 = '', s2 = '') => {
  let longer = s1.toLowerCase()
  let shorter = s2.toLowerCase()
  if (s1.length < s2.length) {
    longer = s2
    shorter = s1
  }
  const longerLength = longer.length
  if (longerLength === 0) {
    return 0.0
  }
  return (longerLength - editDistance(longer, shorter)) / parseFloat(longerLength)
}

export const sortArrayBaseOnAnotherArray = (itemsArray, sortingArr) =>
  itemsArray
    .map((item) => {
      const n = sortingArr.indexOf(item.pid)
      return [n, item]
    })
    .sort()
    .map((j) => j[1])

export const handleDataOrder = (
  _listOrders,
  _adminCost,
  _discount,
  _deliveryCost,
  _productIdList = []
) => {
  let dataReturn = []
  let userOrder = []
  let adminCostAllOrder = 0
  let totalAllOrder = 0
  let placeBy = []
  let paymentCollected = 0
  let numBuy = 0
  if (_listOrders && _listOrders.length > 0) {
    let orderRef = []
    _listOrders.forEach((e) => {
      if (orderRef.includes(e.ref) !== true) {
        if (e.buyerStatus !== BUYER_CANCELED && e.status !== HOST_ORDER_CANCELED) {
          totalAllOrder += e.pPrice * e.pQuantity
          numBuy += e.pQuantity
          userOrder = [...userOrder, e.uid]
        }
        dataReturn = [
          ...dataReturn,
          {
            ref: e.ref,
            buyerStatus: e.buyerStatus,
            status: e.status,
            comment: e.comment,
            combinedPaymentRef:
              e.combinedPaymentRef && !!e.combinedPaymentRef.length ? e.combinedPaymentRef : '',
            uName: e.uName,
            uPhone: e.uPhone,
            uPhotoUrl: e.uPhotoUrl,
            uid: e.uid,
            eOrderNo: e.eOrderNo,
            eTitle: e.eTitle,
            eid: e.eid,
            paymentScreenshot: e.paymentScreenshot,
            createdAt: e.createdAt,
            allProductPerOrder: [
              {
                pid: e.pid,
                pName: e.pName,
                pPrice: e.pPrice,
                pPhotoUrls: e.pPhotoUrls,
                pQuantity: e.pQuantity,
                pSubItems: e.pSubItems,
              },
            ],
          },
        ]
        orderRef = [...orderRef, e.ref]
      } else {
        if (e.buyerStatus !== BUYER_CANCELED && e.status !== HOST_ORDER_CANCELED) {
          totalAllOrder += e.pPrice * e.pQuantity
          numBuy += e.pQuantity
        }
        for (let t = 0; t < dataReturn.length; t++) {
          if (dataReturn && dataReturn[t]?.ref === e.ref) {
            dataReturn[t].allProductPerOrder.push({
              pid: e.pid,
              pName: e.pName,
              pPrice: e.pPrice,
              pPhotoUrls: e.pPhotoUrls,
              pQuantity: e.pQuantity,
              pSubItems: e.pSubItems,
            })
          }
        }
      }
    })
    const listUid = []
    dataReturn.forEach((d) => {
      if (!listUid.includes(d.uid)) {
        placeBy = [...placeBy, d.uName]
        listUid.push(d.uid)
      }
    })
    const newUserOrder = userOrder.reduce((prev, cur) => {
      prev[cur] = (prev[cur] || 0) + 1
      return prev
    }, {})
    for (let index = 0; index < dataReturn.length; index++) {
      let totalPricePerOrder = 0
      dataReturn[index].allProductPerOrder.forEach((p) => {
        totalPricePerOrder += p.pPrice * p.pQuantity
      })
      const adminCostPerOrder = _adminCost
      let discountPerOrder = 0
      if (totalAllOrder !== 0 && !Number.isNaN(totalAllOrder)) {
        discountPerOrder = (_discount * totalPricePerOrder) / totalAllOrder
      }
      let deliveryPerOrder = 0
      if (
        Object.getOwnPropertyNames(newUserOrder).length !== 0 &&
        typeof newUserOrder[dataReturn[index].uid] !== 'undefined' &&
        newUserOrder[dataReturn[index].uid] !== 0
      ) {
        deliveryPerOrder =
          _deliveryCost /
          Object.getOwnPropertyNames(newUserOrder).length /
          newUserOrder[dataReturn[index].uid]
      }
      dataReturn[index].totalPricePerOrder = totalPricePerOrder
      dataReturn[index].adminCostPerOrder = adminCostPerOrder
      dataReturn[index].discountPerOrder = discountPerOrder
      dataReturn[index].deliveryPerOrder = deliveryPerOrder
      if (
        dataReturn[index].status !== HOST_ORDER_CANCELED &&
        dataReturn[index].buyerStatus !== BUYER_CANCELED
      ) {
        dataReturn[index].totalFee =
          totalPricePerOrder + adminCostPerOrder - discountPerOrder + deliveryPerOrder
      } else {
        dataReturn[index].totalFee = totalPricePerOrder
      }
    }
    for (let index = 0; index < dataReturn.length; index++) {
      if (
        dataReturn[index].buyerStatus >= BUYER_PAID &&
        dataReturn[index].status >= HOST_ORDER_VERIFIED
      ) {
        paymentCollected += dataReturn[index].totalFee
      }
    }
    adminCostAllOrder =
      dataReturn.filter(
        (item) => item.buyerStatus !== BUYER_CANCELED && item.status !== HOST_ORDER_CANCELED
      ).length * _adminCost
  }

  if (_productIdList && _productIdList.length > 0) {
    for (let index = 0; index < dataReturn.length; index++) {
      dataReturn[index].allProductPerOrder = sortArrayBaseOnAnotherArray(
        dataReturn[index].allProductPerOrder,
        [..._productIdList]
      )
    }
  }

  return {
    listOrder: dataReturn?.sort((a, b) => a.eOrderNo - b.eOrderNo) ?? [],
    placeBy,
    numBuy,
    totalAllOrder,
    adminCostAllOrder,
    paymentCollected,
  }
}

export const sortMembersInGroup = (list) => {
  if (!list || !list.length) return
  const listNewRequest = list
    .filter((i) => i.approval === GROUP_DONT_APPROVAL)
    .sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1))
  const listBuyers = list
    .filter((i) => i.orderCount > 0)
    .sort((a, b) => (a.orderCount > b.orderCount ? -1 : 1))

  const listRemain = list
    .filter((i) => listBuyers.indexOf(i) === -1)
    .filter((k) => listNewRequest.indexOf(k) === -1)
    .sort((a, b) => (a.name > b.name ? 1 : -1))
  return [...listNewRequest, ...listBuyers, ...listRemain]
}

export const getTimeZoneHour = () => {
  const date = new Date()
  const timeZone = date.getTimezoneOffset()
  return Math.abs(timeZone / 60)
}

// input is String (same as '28-12-2021 03:00')
export const convertToGMT0 = (timeString) => {
  const timeZoneToMilliseconds = getTimeZoneHour() * 60 * 60 * 1000
  const timeMilliseconds =
    moment(timeString, DATE_FORMAT).toDate().getTime() - timeZoneToMilliseconds
  const timeGMT0 = moment(timeMilliseconds).format(DATE_FORMAT)
  return timeGMT0
}

// input is String (same as '28-12-2021 03:00')
export const convertToCurrentGMT = (timeString) => {
  const timeZoneToMilliseconds = getTimeZoneHour() * 60 * 60 * 1000
  const timeMilliseconds =
    moment(timeString, DATE_FORMAT).toDate().getTime() + timeZoneToMilliseconds
  const timeCurrentGMT = moment(timeMilliseconds).format(DATE_FORMAT)
  return timeCurrentGMT
}

// input is number
export const convertTimestampToCurrentGMT = (timestamp) => {
  const timeZoneToMilliseconds = getTimeZoneHour() * 60 * 60 * 1000
  const timestampCurrentGMT = (timestamp * 1000 + timeZoneToMilliseconds) / 1000
  return timestampCurrentGMT
}

export const formatGMT = () => {
  const timeZoneHour = getTimeZoneHour()
  return `GMT-${timeZoneHour}:00`
}

// input is String
export const numDayFromNow = (day) => {
  const given = moment(day?.split(' ')[0], 'DD-MM-YYYY')
  const current = moment().startOf('day')
  return moment.duration(given.diff(current)).asDays()
}

export const sortListMyOrder = (listOrder) => {
  if (!listOrder || !listOrder.length) return []
  const listOrderActive = listOrder.filter(
    (item) => !isDisableEvent(item.status, item.buyerStatus, item.ePickupTime, item.ePickupDuration)
  )
  const listOrderDisable = listOrder.filter((i) => listOrderActive.indexOf(i) === -1)

  // eslint-disable-next-line array-callback-return
  const listOrderDisableSort = listOrderDisable.sort((first, second) => {
    if (
      moment(first.ePickupTime, 'DD-MM-YYYY HH:mm').isAfter(
        moment(second.ePickupTime, 'DD-MM-YYYY HH:mm')
      )
    ) {
      return -1
    }
    if (
      moment(first.ePickupTime, 'DD-MM-YYYY HH:mm').isBefore(
        moment(second.ePickupTime, 'DD-MM-YYYY HH:mm')
      )
    ) {
      return 1
    }
    if (first.createdAt > second.createdAt) {
      return 1
    }
    if (first.createdAt < second.createdAt) {
      return -1
    }
  })
  // eslint-disable-next-line array-callback-return
  const listOrderActiveSort = listOrderActive.sort((first, second) => {
    if (
      moment(first.ePickupTime, 'DD-MM-YYYY HH:mm').isAfter(
        moment(second.ePickupTime, 'DD-MM-YYYY HH:mm')
      )
    ) {
      return 1
    }
    if (
      moment(first.ePickupTime, 'DD-MM-YYYY HH:mm').isBefore(
        moment(second.ePickupTime, 'DD-MM-YYYY HH:mm')
      )
    ) {
      return -1
    }
    if (first.createdAt > second.createdAt) {
      return -1
    }
    if (first.createdAt < second.createdAt) {
      return 1
    }
  })
  return { listOrderActiveSort, listOrderDisableSort }
}

export const formatPlannedDate = (date) => {
  const d = moment(date).format('DD-MM-YYYY HH:mm')
  return d
}

export const convertTimeStringWithDuration = (time, timeDuration) => {
  const timeCurrentGMT = convertToCurrentGMT(time)
  const timeStart = Number(timeCurrentGMT.split(' ')[1].split(':')[0])
  const timeEnd = timeStart + timeDuration / 3600
  let timeStartText = ''
  let timeEndText = ''
  if (timeEnd <= 24) {
    const indexStart = LIST_TIME.findIndex((item) => item.id === timeStart)
    const indexEnd = LIST_TIME.findIndex((item) => item.id === timeEnd % 24)
    timeStartText = LIST_TIME[indexStart].text
    timeEndText = LIST_TIME[indexEnd].text
  }
  return `${timeCurrentGMT.split(' ')[0]} (${timeStartText} - ${timeEndText})`
}

export const convertTimeStringWithDurationTwoPart = (time, timeDuration) => {
  const timeCurrentGMT = convertToCurrentGMT(time)
  const timeStart = Number(timeCurrentGMT.split(' ')[1].split(':')[0])
  const timeEnd = timeStart + timeDuration / 3600
  let timeStartText = ''
  let timeEndText = ''
  if (timeEnd <= 24) {
    const indexStart = LIST_TIME.findIndex((item) => item.id === timeStart)
    const indexEnd = LIST_TIME.findIndex((item) => item.id === timeEnd % 24)
    timeStartText = LIST_TIME[indexStart].text
    timeEndText = LIST_TIME[indexEnd].text
  }
  return [timeCurrentGMT.split(' ')[0], `${timeStartText} - ${timeEndText}`]
}

export const convertToTimeEnd = (time, timeDuration, timeStamp = false) => {
  const timeCurrentGMT = convertToCurrentGMT(time)
  const timeStart = Number(timeCurrentGMT.split(' ')[1].split(':')[0])
  const timeEnd = timeStart + timeDuration / 3600
  const timeString = `${timeCurrentGMT.split(' ')[0]} ${timeEnd}:00`
  if (timeStamp) {
    return moment(timeString, 'DD-MM-YYYY HH:mm').unix()
  }
  return timeString
}

export const sortListMyDelivery = (listDelivery) => {
  if (!listDelivery || !listDelivery.length) return []
  const listDeliveryActive = []
  listDelivery.forEach((item) => {
    const { bookerStatus, status, dDeliveryDuration, dDeliveryTime } = item
    if (
      !moment().isAfter(
        moment(convertToTimeEnd(dDeliveryTime, dDeliveryDuration), 'DD-MM-YYYY HH:mm')
      ) &&
      bookerStatus !== DELIVERY_BUYER_CANCELLED &&
      status !== DELIVERY_HOST_CANCELLED
    ) {
      listDeliveryActive.push(item)
    }
  })
  const listDeliveryDisable = listDelivery.filter((i) => listDeliveryActive.indexOf(i) === -1)

  // eslint-disable-next-line array-callback-return
  const listDeliveryDisableSort = listDeliveryDisable.sort((first, second) => {
    if (
      moment(first.dDeliveryTime, 'DD-MM-YYYY HH:mm').isAfter(
        moment(second.dDeliveryTime, 'DD-MM-YYYY HH:mm')
      )
    ) {
      return -1
    }
    if (
      moment(first.dDeliveryTime, 'DD-MM-YYYY HH:mm').isBefore(
        moment(second.dDeliveryTime, 'DD-MM-YYYY HH:mm')
      )
    ) {
      return 1
    }
    if (first.createdAt > second.createdAt) {
      return 1
    }
    if (first.createdAt < second.createdAt) {
      return -1
    }
  })
  // eslint-disable-next-line array-callback-return
  const listDeliveryActiveSort = listDeliveryActive.sort((first, second) => {
    if (
      moment(first.dDeliveryTime, 'DD-MM-YYYY HH:mm').isAfter(
        moment(second.dDeliveryTime, 'DD-MM-YYYY HH:mm')
      )
    ) {
      return 1
    }
    if (
      moment(first.dDeliveryTime, 'DD-MM-YYYY HH:mm').isBefore(
        moment(second.dDeliveryTime, 'DD-MM-YYYY HH:mm')
      )
    ) {
      return -1
    }
    if (first.createdAt > second.createdAt) {
      return -1
    }
    if (first.createdAt < second.createdAt) {
      return 1
    }
  })
  return { listDeliveryActiveSort, listDeliveryDisableSort }
}

export const handleCombinedPayment = (combinedPayment, currRef = '') => {
  let { orderCombinedPayment = [], deliveryCombinedPayment = [] } = combinedPayment
  let stringReturn = ''
  deliveryCombinedPayment = deliveryCombinedPayment
    .filter((item) => item.ref !== currRef)
    .map(
      ({ dDeliveryTime, dDeliveryDuration }) =>
        `delivery for (${convertTimeStringWithDuration(dDeliveryTime, dDeliveryDuration)})`
    )

  orderCombinedPayment = orderCombinedPayment
    .filter((item) => item.ref !== currRef)
    .map(({ eTitle }) => `${eTitle}`)

  if (deliveryCombinedPayment.length && orderCombinedPayment.length) {
    stringReturn = `${orderCombinedPayment.join(', ')}, ${deliveryCombinedPayment.join(', ')}`
  } else if (deliveryCombinedPayment.length === 0 && orderCombinedPayment.length) {
    stringReturn = `${orderCombinedPayment.join(', ')}`
  } else if (deliveryCombinedPayment.length && orderCombinedPayment.length === 0) {
    stringReturn = `${deliveryCombinedPayment.join(', ')}`
  }
  return stringReturn
}

export function removeAccents(str) {
  const AccentsMap = [
    'aàảãáạăằẳẵắặâầẩẫấậ',
    'AÀẢÃÁẠĂẰẲẴẮẶÂẦẨẪẤẬ',
    'dđ',
    'DĐ',
    'eèẻẽéẹêềểễếệ',
    'EÈẺẼÉẸÊỀỂỄẾỆ',
    'iìỉĩíị',
    'IÌỈĨÍỊ',
    'oòỏõóọôồổỗốộơờởỡớợ',
    'OÒỎÕÓỌÔỒỔỖỐỘƠỜỞỠỚỢ',
    'uùủũúụưừửữứự',
    'UÙỦŨÚỤƯỪỬỮỨỰ',
    'yỳỷỹýỵ',
    'YỲỶỸÝỴ',
  ]
  for (let i = 0; i < AccentsMap.length; i++) {
    const re = new RegExp(`[${AccentsMap[i].substr(1)}]`, 'g')
    const char = AccentsMap[i][0]
    str = str.replace(re, char)
  }
  return str
}

export const generateIdWithLength = (length) => {
  let result = ''
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  const charactersLength = characters.length
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }
  return result
}

export const convertDuration = (dateTime, duration) => {
  if (dateTime) {
    const currentTime = convertToCurrentGMT(dateTime)
    const getHourStart = currentTime?.slice(11, 16)
    const deliveryStartIndex = LIST_TIME_2.findIndex((item) => item?.valueString === getHourStart)
    const getHourEnd = LIST_TIME_2?.filter(
      (item) =>
        (item?.valueNumber - LIST_TIME_2[deliveryStartIndex]?.valueNumber) * 3600 === duration
    )[0]

    return {
      hourStart: LIST_TIME_2[deliveryStartIndex]?.text,
      hourEnd: getHourEnd?.text,
    }
  }
}

export const getCoordinates = async (lstAddress) => {
  const newAddress = []

  if (lstAddress.length > 0) {
    for (let i = 0; i < lstAddress.length; i++) {
      const { index, value } = lstAddress[i]
      const res = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${value}&key=${process.env.REACT_APP_GOOGLE_KEY}`
      )
      const data = await res.json()
      if (data && data.results.length > 0) {
        const latitude = data.results[0].geometry.location.lat
        const longitude = data.results[0].geometry.location.lng

        newAddress.push({ index, lat: latitude, lng: longitude })
      }
    }
  }

  return newAddress
}

export const findNearestDate = (arrayOfDates, findDate) => {
  let nearestDate
  let momentsDate
  try {
    momentsDate = arrayOfDates
      .map((date) => {
        date = moment(date, 'DD-MM-YYYY')
        const diff = date.diff(moment(findDate, 'DD-MM-YYYY'), 'days')
        if (diff >= 0) {
          if (nearestDate) {
            if (date.diff(moment(nearestDate), 'days') < 0) {
              nearestDate = date
            }
          } else {
            nearestDate = date
          }
        }
        return date
      })
      .filter((isValid) => isValid)
    if (!nearestDate) {
      nearestDate = moment.max(momentsDate)
    }
    return nearestDate._i
  } catch (error) {
    return false
  }
}

export const findNearestDateOfEventClose = (arrayOfDates, findDate, listEvent) => {
  const getDateNearest = () => {
    let nearestDate
    let momentsDate
    try {
      momentsDate = arrayOfDates
        .map((date) => {
          date = moment(date, 'DD-MM-YYYY')
          const diff = date.diff(moment(findDate, 'DD-MM-YYYY'), 'days')
          if (diff >= 0) {
            if (nearestDate) {
              if (date.diff(moment(nearestDate), 'days') < 0) {
                nearestDate = date
              }
            } else {
              nearestDate = date
            }
          }
          return date
        })
        .filter((isValid) => isValid)
      if (!nearestDate) {
        nearestDate = moment.max(momentsDate)
      }
      return nearestDate._i
    } catch (error) {
      return false
    }
  }
  const listEventSameDateNearest = listEvent?.filter(
    (item) =>
      moment(item?.closingTime, 'DD-MM-YYYY').format('DD-MM-YYYY') ===
      moment(getDateNearest(), 'DD-MM-YYYY').format('DD-MM-YYYY')
  )
  const eventsCloseInFuture = listEvent?.filter((item) => {
    const convertDateNearest = moment(getDateNearest(), 'DD-MM-YYYY').format('YYYY-MM-DD')
    const convertDateClosing = moment(item.closingTime, 'DD-MM-YYYY').format('YYYY-MM-DD')
    return moment(convertDateClosing).isAfter(convertDateNearest)
  })
  const listEventClose = listEventSameDateNearest.concat(eventsCloseInFuture)
  return listEventClose
}

export const findNearestDateOfEventCollection = (arrayOfDates, findDate, listEvent) => {
  const getDateNearest = () => {
    let nearestDate
    let momentsDate
    try {
      momentsDate = arrayOfDates
        .map((date) => {
          date = moment(date, 'DD-MM-YYYY')
          const diff = date.diff(moment(findDate, 'DD-MM-YYYY'), 'days')
          if (diff >= 0) {
            if (nearestDate) {
              if (date.diff(moment(nearestDate), 'days') < 0) {
                nearestDate = date
              }
            } else {
              nearestDate = date
            }
          }
          return date
        })
        .filter((isValid) => isValid)
      if (!nearestDate) {
        nearestDate = moment.max(momentsDate)
      }
      return nearestDate._i
    } catch (error) {
      return false
    }
  }
  const listEventSameDateNearest = listEvent?.filter(
    (item) =>
      moment(item?.pickupTime, 'DD-MM-YYYY').format('DD-MM-YYYY') ===
      moment(getDateNearest(), 'DD-MM-YYYY').format('DD-MM-YYYY')
  )
  const eventsCollectionInFuture = listEvent?.filter((item) => {
    const convertDateNearest = moment(getDateNearest(), 'DD-MM-YYYY').format('YYYY-MM-DD')
    const convertDateCollection = moment(item.pickupTime, 'DD-MM-YYYY').format('YYYY-MM-DD')
    return moment(convertDateCollection).isAfter(convertDateNearest)
  })
  const listEventCollection = listEventSameDateNearest.concat(eventsCollectionInFuture)
  return listEventCollection
}

export const getFirstStringAndUppercase = (string) => {
  const str = string.split(' ')
  let s = ''
  for (let i = 0; i < str.length; i++) {
    if (i === 2) break
    s += str[i].substring(0, 1).toUpperCase()
  }

  return s
}

export function isHyperlink(contact) {
  // Kiểm tra xem chuỗi có chứa các phần mở rộng sau không
  const hasValidExtension = new RegExp(
    '^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|www\\.){1}([0-9A-Za-z-\\.@:%_+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?'
  ).test(contact)

  // Kiểm tra xem chuỗi có chứa dấu @ hay không
  const hasAtSymbol = contact?.includes('@')

  // Kiểm tra xem chuỗi có chứa ít nhất 8 chữ số (liên kết Whatsapp) hay không
  const hasEightDigits = /^\d{8}$/.test(contact)

  // Nếu chuỗi không phù hợp với bất kỳ điều kiện nào, trả về false
  if ((!hasValidExtension && !hasEightDigits) || hasAtSymbol) {
    return false
  }

  // Ngược lại, trả về true (là một liên kết)
  return true
}

export function handleExport(getEventById, format, listEvent = []) {
  const {
    title,
    description,
    closingTime,
    pickupTime,
    createdUserName,
    createdUserPhone,
    orders = [],
    adminCost,
    discount,
    deliveryCost,
  } = getEventById || {}

  const { totalAllOrder, adminCostAllOrder, paymentCollected } = handleDataOrder(
    orders,
    adminCost,
    discount,
    deliveryCost
  )

  const ordersActive = orders.filter(
    (order) =>
      order.buyerStatus !== BUYER_ORDER_CANCELED &&
      order.buyerStatus !== BUYER_REFUNDED &&
      order.status !== HOST_ORDER_CANCELED
  )

  const firstName = createdUserName ? createdUserName.split(' ').slice(0, 1) : ''
  const lastName = createdUserName ? createdUserName.split(' ').slice(1).join(' ') : ''

  const generalInfo = [
    ['Title', title],
    ['Description', description],
    ['Closing Date', convertToCurrentGMT(closingTime).trim().split(' ')[0]],
    ['Closing Time', convertToCurrentGMT(closingTime).trim().split(' ')[1]],
    ['Pick-up Date', convertToCurrentGMT(pickupTime).trim().split(' ')[0]],
    ['Pick-up Time', convertToCurrentGMT(pickupTime).trim().split(' ')[1]],
    ['Organiser First Name', firstName],
    ['Organiser Last Name', lastName],
    ['Organiser Number', createdUserPhone],
    [''],
  ]

  const total = [
    ['Total Purchase', `$${totalAllOrder}`],
    ['Admin Fee', `$${adminCostAllOrder}`],
    ['Payment Confirmed', `$${paymentCollected}`],
    [''],
  ]

  const totalOrderArr = [
    ...ordersActive.reduce((acc, curr) => {
      const order = acc.find((p) => p.pid === curr.pid)
      if (order) {
        const index = acc.indexOf(order)
        acc[index].count += curr.pQuantity
      } else {
        curr.count = curr.pQuantity
        acc.push(curr)
      }
      return acc
    }, []),
  ]

  const rowsOfTotalOrder = new Array(totalOrderArr.length)

  function export1() {
    const rowsOfProduct = new Array(ordersActive.length) || []

    for (let index = 0; index < ordersActive.length; index++) {
      const product = ordersActive[index]
      const {
        eOrderNo,
        ref,
        uName,
        uPhone,
        eTitle,
        createdAt,
        pName,
        pQuantity,
        buyerStatus,
        eid,
      } = product

      const event = listEvent.find((item) => item.id === eid)
      const eStatus = event?.status

      let buyerStatusString = ''

      switch (buyerStatus) {
        case BUYER_UNPAID:
          buyerStatusString = 'Not Paid'
          break
        case BUYER_PAID:
          buyerStatusString = 'Paid'
          break
        case BUYER_CANCELED:
          buyerStatusString = 'Cancelled'
          break
        case BUYER_COLLECTED:
          buyerStatusString = 'Collected'
          break
        case BUYER_REFUNDED:
          buyerStatusString = 'Refunded'
          break
        default:
          break
      }

      rowsOfProduct[index] = [
        eOrderNo,
        ref,
        uName,
        uPhone,
        eTitle,
        moment.unix(createdAt).format('DD-MM-YYYY'),
        pName,
        pQuantity,
        `${eStatus === 0 ? 'Close order' : 'Open order'}`,
        buyerStatusString,
      ]
    }

    const rows = [
      [['Event name', title]],
      [''],
      [''],
      [
        'Order No',
        'Ref',
        'Name',
        'Phone',
        'Event',
        'Date',
        'Purchase',
        'Quantity',
        'Event status',
        'Payment status',
      ],
      ...rowsOfProduct,
    ]

    const fileName = normalizeName(title)
    exportToCsv(`${fileName}.csv`, rows)
  }

  function export2() {
    const { products, productIdList } = getEventById

    const rowsOfContact = new Array(0)
    let colsOfProduct = []

    for (let i = 0; i < totalOrderArr.length; i++) {
      const order = totalOrderArr[i]
      const listTempProName = []
      const listTempProPrice = []
      const subitems = products.find((x) => x.id === order.pid).subItems || []
      subitems.sort((a, b) => (a.type > b.type ? 1 : b.type > a.type ? -1 : 0))
      for (let j = 0; j < subitems.length; j++) {
        const subitem = subitems[j]
        const optionsTempName = []
        const optionsTempPrice = []
        const { list, required } = subitem
        if (required === 0) {
          optionsTempName.push('')
          optionsTempPrice.push(0)
        }
        for (let k = 0; k < list.length; k++) {
          const option = list[k]
          optionsTempName.push(option.name)
          optionsTempPrice.push(option.price)
        }
        listTempProName.push(optionsTempName)
        listTempProPrice.push(optionsTempPrice)
      }

      let cartesianProductPrice = []
      if (listTempProPrice.length) {
        cartesianProductPrice = cartesian(listTempProPrice.map((item) => item))
      }

      if (!listTempProName.length) listTempProName.push([order.pName])
      if (!listTempProPrice.length) listTempProPrice.push([order.pPrice])

      const cartesianProductName = cartesian(listTempProName.map((item) => item))

      const _products = []

      for (let l = 0; l < cartesianProductName.length; l++) {
        const productName = cartesianProductName[l].toString()
        let productPrice = 0
        if (cartesianProductPrice.length) {
          productPrice = cartesianProductPrice[l].reduce((a, b) => a + b, 0)
        }
        _products.push({ pid: order.pid, name: productName, price: productPrice })
      }

      colsOfProduct = [
        ...colsOfProduct,
        ..._products.map((product) => {
          if (subitems.length) {
            return {
              ...product,
              name: product.name ? `${order.pName} (${product.name})` : `${order.pName}`,
            }
          }
          return { ...product, name: order.pName }
        }),
      ]

      totalOrderArr[i] = { ...order, subItems: subitems }
    }

    const _totalOrderArr = sortArrayBaseOnAnotherArray(totalOrderArr, [...productIdList])

    const _sortColsOfProduct = sortArrayBaseOnAnotherArray(colsOfProduct, [...productIdList])

    for (let i = 0; i < products.length; i++) {
      const productTemp = products[i]
      for (let j = 0; j < _sortColsOfProduct.length; j++) {
        const product = _sortColsOfProduct[j]
        if (product.pid === productTemp.id) {
          _sortColsOfProduct[j] = { ...product, price: product.price + productTemp.price }
        }
      }
    }

    const groupByOrderWithBuyerName = groupBy(ordersActive, 'uName')
    const listOfBuyerName = Object.keys(groupByOrderWithBuyerName)

    const colProductName = _sortColsOfProduct.map((p) => p.name)
    const colProductNameAndId = _sortColsOfProduct.map((p) => `${p.name} ${p.pid}`)
    const colProductPrice = _sortColsOfProduct.map((p) => `$${p.price}`)

    if (Array.isArray(listOfBuyerName)) {
      for (let i = 0; i < listOfBuyerName.length; i++) {
        const colOrderContact = []
        const buyerName = listOfBuyerName[i]
        const { uPhone: buyerPhone } = groupByOrderWithBuyerName[buyerName][0]

        for (let j = 0; j < groupByOrderWithBuyerName[buyerName].length; j++) {
          const objOrder = groupByOrderWithBuyerName[buyerName][j]
          const { pName, pSubItems } = objOrder

          let productName = pName
          if (pSubItems.length) {
            productName = `${pName} (${pSubItems
              .sort((a, b) => (a.type > b.type ? 1 : b.type > a.type ? -1 : 0))
              .map((s) => s.name)
              .toString()})`
          }

          groupByOrderWithBuyerName[buyerName][j] = { ...objOrder, pName: productName }
        }

        // eslint-disable-next-line no-confusing-arrow
        const _sum = sum(groupByOrderWithBuyerName[buyerName]).sort((a, b) =>
          a.pName > b.pName ? 1 : b.pName > a.pName ? -1 : 0
        )

        for (let k = 0; k < colProductNameAndId.length; k++) {
          let _quantity = 0
          const productNameAndId = colProductNameAndId[k]
          for (let j = 0; j < _sum.length; j++) {
            const _order = _sum[j]
            if (productNameAndId === _order.pNameAndPid) {
              _quantity += _order.pQuantity
            }
          }
          colOrderContact.push(_quantity)
        }

        const buyerFirstName = buyerName ? buyerName.split(' ').slice(0, 1) : ''
        const buyerLastName = buyerName ? buyerName.split(' ').slice(1).join(' ') : ''

        const comments = groupByOrderWithBuyerName[buyerName].map((order) => order.comment)
        const commentCombined = comments.filter((comment) => comment !== null).join(', ')

        rowsOfContact.push([
          '',
          buyerFirstName,
          buyerLastName,
          buyerPhone,
          commentCombined,
          ...colOrderContact,
        ])
      }
    }

    for (let index = 0; index < _totalOrderArr.length; index++) {
      const totalOrder = _totalOrderArr[index]

      const { pName, count } = totalOrder

      rowsOfTotalOrder[index] = [pName, count]
    }

    const rows = [
      ...generalInfo,
      ['', '', '', '', '', ...colProductPrice],
      ['', 'First Name', 'Last Name', 'Contact', 'Comment', ...colProductName],
      ...rowsOfContact,
      [''],
      ...total,
      ['Total orders'],
      ...rowsOfTotalOrder,
    ]

    const fileName = normalizeName(title)
    exportToCsv(`${fileName}.csv`, rows)
  }

  if (format === 1) {
    export1()
  } else {
    export2()
  }
}

export function handleExportMultipleEventAndOrder(
  listTotalOrderAfterFilter,
  format,
  listEvent = []
) {
  function export1(arrayOrder, arrayEventName) {
    const rowsOfProduct = new Array(arrayOrder.length) || []

    for (let index = 0; index < arrayOrder.length; index++) {
      const product = arrayOrder[index]
      const { ref, uName, uPhone, eTitle, createdAt, pName, pQuantity, buyerStatus, eid } = product
      const event = listEvent.find((item) => item.id === eid)

      const eStatus = event?.status
      let buyerStatusString = ''

      switch (buyerStatus) {
        case BUYER_UNPAID:
          buyerStatusString = 'Not Paid'
          break
        case BUYER_PAID:
          buyerStatusString = 'Paid'
          break
        case BUYER_CANCELED:
          buyerStatusString = 'Cancelled'
          break
        case BUYER_COLLECTED:
          buyerStatusString = 'Collected'
          break
        case BUYER_REFUNDED:
          buyerStatusString = 'Refunded'
          break
        default:
          break
      }

      rowsOfProduct[index] = [
        index,
        ref,
        uName,
        uPhone,
        eTitle,
        moment.unix(createdAt).format('DD-MM-YYYY'),
        pName,
        pQuantity,
        `${eStatus === 0 ? 'Close order' : 'Open order'}`,
        buyerStatusString,
      ]
    }

    const rows = [
      [''],
      [''],
      [''],
      ['', ['List event is filtered:', arrayEventName?.join(',')]],
      [''],
      [''],
      [
        'Order No',
        'Ref',
        'Name',
        'Phone',
        'Event',
        'Date',
        'Purchase',
        'Quantity',
        'Event status',
        'Payment status',
      ],
      ...rowsOfProduct,
    ]

    const fileName = normalizeName(`manage orders by ${moment().format('DD-MM-YYYY')}`)
    exportToCsv(`${fileName}.csv`, rows)
  }

  function export2(arrayEvent) {
    const listTitle = []
    const wb = XLSX.utils.book_new()
    for (let n = 0; n < arrayEvent?.length; n++) {
      const {
        title,
        description,
        closingTime,
        pickupTime,
        createdUserName,
        createdUserPhone,
        orders = [],
        adminCost,
        discount,
        deliveryCost,
      } = arrayEvent[n] || {}

      const { totalAllOrder, adminCostAllOrder, paymentCollected } = handleDataOrder(
        orders,
        adminCost,
        discount,
        deliveryCost
      )
      const ordersActive = orders.filter(
        (order) =>
          order.buyerStatus !== BUYER_ORDER_CANCELED &&
          order.buyerStatus !== BUYER_REFUNDED &&
          order.status !== HOST_ORDER_CANCELED
      )
      const firstName = createdUserName ? createdUserName.split(' ').slice(0, 1) : ''
      const lastName = createdUserName ? createdUserName.split(' ').slice(1).join(' ') : ''
      const generalInfo = [
        ['Title', title],
        ['Description', description],
        ['Closing Date', convertToCurrentGMT(closingTime).trim().split(' ')[0]],
        ['Closing Time', convertToCurrentGMT(closingTime).trim().split(' ')[1]],
        ['Pick-up Date', convertToCurrentGMT(pickupTime).trim().split(' ')[0]],
        ['Pick-up Time', convertToCurrentGMT(pickupTime).trim().split(' ')[1]],
        ['Organiser First Name', firstName],
        ['Organiser Last Name', lastName],
        ['Organiser Number', createdUserPhone],
        [''],
      ]
      const total = [
        ['Total Purchase', `$${totalAllOrder}`],
        ['Admin Fee', `$${adminCostAllOrder}`],
        ['Payment Confirmed', `$${paymentCollected}`],
        [''],
      ]
      const totalOrderArr = [
        ...ordersActive.reduce((acc, curr) => {
          const order = acc.find((p) => p.pid === curr.pid)
          if (order) {
            const index = acc.indexOf(order)
            acc[index].count += curr.pQuantity
          } else {
            curr.count = curr.pQuantity
            acc.push(curr)
          }
          return acc
        }, []),
      ]
      const rowsOfTotalOrder = new Array(totalOrderArr.length)

      const { products, productIdList } = arrayEvent[n]

      const rowsOfContact = new Array(0)
      let colsOfProduct = []

      for (let i = 0; i < totalOrderArr.length; i++) {
        const order = totalOrderArr[i]
        const listTempProName = []
        const listTempProPrice = []
        const subitems = products.find((x) => x.id === order.pid).subItems || []
        subitems.sort((a, b) => (a.type > b.type ? 1 : b.type > a.type ? -1 : 0))
        for (let j = 0; j < subitems.length; j++) {
          const subitem = subitems[j]
          const optionsTempName = []
          const optionsTempPrice = []
          const { list, required } = subitem
          if (required === 0) {
            optionsTempName.push('')
            optionsTempPrice.push(0)
          }
          for (let k = 0; k < list.length; k++) {
            const option = list[k]
            optionsTempName.push(option.name)
            optionsTempPrice.push(option.price)
          }
          listTempProName.push(optionsTempName)
          listTempProPrice.push(optionsTempPrice)
        }

        let cartesianProductPrice = []
        if (listTempProPrice.length) {
          cartesianProductPrice = cartesian(listTempProPrice.map((item) => item))
        }

        if (!listTempProName.length) listTempProName.push([order.pName])
        if (!listTempProPrice.length) listTempProPrice.push([order.pPrice])

        const cartesianProductName = cartesian(listTempProName.map((item) => item))

        const _products = []

        for (let l = 0; l < cartesianProductName.length; l++) {
          const productName = cartesianProductName[l].toString()
          let productPrice = 0
          if (cartesianProductPrice.length) {
            productPrice = cartesianProductPrice[l].reduce((a, b) => a + b, 0)
          }
          _products.push({ pid: order.pid, name: productName, price: productPrice })
        }

        colsOfProduct = [
          ...colsOfProduct,
          ..._products.map((product) => {
            if (subitems.length) {
              return {
                ...product,
                name: product.name ? `${order.pName} (${product.name})` : `${order.pName}`,
              }
            }
            return { ...product, name: order.pName }
          }),
        ]

        totalOrderArr[i] = { ...order, subItems: subitems }
      }

      const _totalOrderArr = sortArrayBaseOnAnotherArray(totalOrderArr, [...productIdList])

      const _sortColsOfProduct = sortArrayBaseOnAnotherArray(colsOfProduct, [...productIdList])

      for (let i = 0; i < products.length; i++) {
        const productTemp = products[i]
        for (let j = 0; j < _sortColsOfProduct.length; j++) {
          const product = _sortColsOfProduct[j]
          if (product.pid === productTemp.id) {
            _sortColsOfProduct[j] = { ...product, price: product.price + productTemp.price }
          }
        }
      }

      const groupByOrderWithBuyerName = groupBy(ordersActive, 'uName')
      const listOfBuyerName = Object.keys(groupByOrderWithBuyerName)

      const colProductName = _sortColsOfProduct.map((p) => p.name)
      const colProductNameAndId = _sortColsOfProduct.map((p) => `${p.name} ${p.pid}`)
      const colProductPrice = _sortColsOfProduct.map((p) => `$${p.price}`)

      if (Array.isArray(listOfBuyerName)) {
        for (let i = 0; i < listOfBuyerName.length; i++) {
          const colOrderContact = []
          const buyerName = listOfBuyerName[i]
          const { uPhone: buyerPhone } = groupByOrderWithBuyerName[buyerName][0]

          for (let j = 0; j < groupByOrderWithBuyerName[buyerName].length; j++) {
            const objOrder = groupByOrderWithBuyerName[buyerName][j]
            const { pName, pSubItems } = objOrder

            let productName = pName
            if (pSubItems.length) {
              productName = `${pName} (${pSubItems
                .sort((a, b) => (a.type > b.type ? 1 : b.type > a.type ? -1 : 0))
                .map((s) => s.name)
                .toString()})`
            }

            groupByOrderWithBuyerName[buyerName][j] = { ...objOrder, pName: productName }
          }

          const _sum = sum(groupByOrderWithBuyerName[buyerName]).sort((a, b) =>
            a.pName > b.pName ? 1 : b.pName > a.pName ? -1 : 0
          )

          for (let k = 0; k < colProductNameAndId.length; k++) {
            let _quantity = 0
            const productNameAndId = colProductNameAndId[k]
            for (let j = 0; j < _sum.length; j++) {
              const _order = _sum[j]
              if (productNameAndId === _order.pNameAndPid) {
                _quantity += _order.pQuantity
              }
            }
            colOrderContact.push(_quantity)
          }

          const buyerFirstName = buyerName ? buyerName.split(' ').slice(0, 1) : ''
          const buyerLastName = buyerName ? buyerName.split(' ').slice(1).join(' ') : ''

          const comments = groupByOrderWithBuyerName[buyerName].map((order) => order.comment)
          const commentCombined = comments.filter((comment) => comment !== null).join(', ')

          rowsOfContact.push([
            '',
            buyerFirstName,
            buyerLastName,
            buyerPhone,
            commentCombined,
            ...colOrderContact,
          ])
        }
      }

      for (let index = 0; index < _totalOrderArr.length; index++) {
        const totalOrder = _totalOrderArr[index]

        const { pName, count } = totalOrder

        rowsOfTotalOrder[index] = [pName, count]
      }

      const rows = [
        ...generalInfo,
        ['', '', '', '', '', ...colProductPrice],
        ['', 'First Name', 'Last Name', 'Contact', 'Comment', ...colProductName],
        ...rowsOfContact,
        [''],
        ...total,
        ['Total orders'],
        ...rowsOfTotalOrder,
      ]
      const formatSheetName = title.slice(0, 28)
      const CheckSheetNameIsExit = listTitle.includes(formatSheetName)
      let sheetAfterFormat
      if (CheckSheetNameIsExit) {
        sheetAfterFormat = `(1)${formatSheetName}`
        const checkSheetAfterFormatIsExit = listTitle.includes(sheetAfterFormat)
        if (checkSheetAfterFormatIsExit) {
          const countItemIsDuplicate = listTitle.filter((item) => item === sheetAfterFormat).length
          sheetAfterFormat = `$ ${countItemIsDuplicate + 1}{formatSheetName}`
        }
      } else {
        sheetAfterFormat = formatSheetName
      }
      const data = rows
      const ws = XLSX.utils.aoa_to_sheet(data)
      XLSX.utils.book_append_sheet(wb, ws, sheetAfterFormat)
      listTitle.push(sheetAfterFormat)
    }
    XLSX.writeFile(wb, 'Total order.xlsx')
  }

  if (format === 1) {
    // export1(listTotalOrderAfterFilter)
    const orderOfMultipleEvent = []
    const nameOfMultipleEvent = []
    listTotalOrderAfterFilter?.forEach((item) => {
      orderOfMultipleEvent.push(item.orders)
      nameOfMultipleEvent.push(item.title)
    })
    export1(orderOfMultipleEvent.flat(), nameOfMultipleEvent)
  } else {
    export2(listTotalOrderAfterFilter)
  }
}

export const formatNumberToCurrency = (
  number,
  countryDollar = 'en-US',
  options = {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }
) => {
  if (!number && !countryDollar && isEmpty(options)) throw new Error('Missing params')

  const currency = new Intl.NumberFormat(countryDollar, options)
  return currency.format(number).replace('$', '').trim()
}

export const formatNumberToCurrencyHasDot = (inputText) => {
  const hasDot = inputText.includes('.')

  const sanitizedInput = inputText.replace(/[^\d.]/g, '')

  // Separate integerPart and decimalPart
  const [integerPart, decimalPart] = sanitizedInput.split('.')

  /**
   * Flow typing input:
   * input: 1000
   * format: 1,000
   * input: 1,000.
   * format: 1,000.
   * input: 1,000.15
   * format: 1,000.15
   */
  let formattedIntegerPart = ''

  if (integerPart && !hasDot) {
    formattedIntegerPart = formatNumberToCurrency(integerPart, 'en-SG', OPTION_CURRENCY_SG)
  }

  if (integerPart && hasDot) {
    formattedIntegerPart = `${formatNumberToCurrency(integerPart, 'en-SG', OPTION_CURRENCY_SG)}.`
  }

  const formattedDecimalPart = decimalPart ? `${decimalPart}` : ''

  const formattedInput = `${formattedIntegerPart}${formattedDecimalPart}`

  return formattedInput
}

export const scrollToTop = () => window.scrollTo(0, 0)

export function removeDuplicates(arr) {
  return arr.filter((item, index) => {
    const jsonString = JSON.stringify(item)
    return index === arr.findIndex((obj) => JSON.stringify(obj) === jsonString)
  })
}
