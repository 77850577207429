/* eslint-disable no-confusing-arrow */
/* eslint-disable no-useless-escape */
/* eslint-disable quotes */
/* eslint-disable prefer-template */
/* eslint-disable function-paren-newline */
/* eslint-disable max-len */
import WithErrorBoundary from '_components/WithErrorBoundary'
import { SelectIdGroupContext } from '_context/SelectGroupContext'
import OrderSearch from '_pages/Event/ManageOrder/SearchOrder'
import { clearEventAndOrder, getEventGroupIdByHost } from '_redux/modules/event'
import { handleDataOrder, handleExport, handleExportMultipleEventAndOrder } from '_utils/function'
import moment from 'moment'
import { useContext, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useToasts } from 'react-toast-notifications'
import { Button, Col, Row } from 'reactstrap'

import { isArray } from 'lodash'
import EmptyStateMessage from '../../components/EmptyStateMessage'
import LoadingComponent from '../../components/LoadingComponent'
import { getEventAndOrder } from '../../redux/modules/event'
import { EVENT_CLOSE } from '../../utils/constant'
import OrderList from './components/OrderList'
import Pagination from './components/Pagination'
import SelectFilter from './components/SelectFilter/index'
import './style.scss'

const now = moment().unix()
function ManageOrder() {
  // use hooks at the top
  const dispatch = useDispatch()
  const { addToast } = useToasts()
  const context = useContext(SelectIdGroupContext)
  const { groupId } = context.infoGroupSelected
  const {
    // errorGetEventAndOrder,
    listEventGroupIdByHost,
    eventAndOrderById,
    loadingGetEventAndOrder,
    loadingGetEventGroupId,
  } = useSelector((state) => state.event)

  // declare constant
  const FORMAT_1 = 1
  const FORMAT_2 = 2

  // declare state
  const [filter, setFilter] = useState({
    listEvent: [],
    rangeDate: [
      {
        startDate: null,
        endDate: null,
        key: 'selection',
      },
    ],
    eventStatus: 'default',
    paymentStatus: [],
  })
  const [sorting, setSorting] = useState({ field: 'createdAt', order: 'desc', type: 'number' })
  const [searchString, setSearchString] = useState('')
  const [page, setPage] = useState({
    currentPage: 1,
    ordersPerPage: 10,
  })

  const [listOrder, setListOrder] = useState([])

  // declare variable
  const { listEvent, eventStatus, paymentStatus, rangeDate } = filter
  const { startDate, endDate } = rangeDate[0]
  const eventId = listEvent.map((event) => event.id)
  const paymentStatusValue = paymentStatus.map((status) => status.value)
  // const eStatus = listEventGroupIdByHost?.filter((event) => event.status === Number(eventStatus))
  // const eventStatusId = eStatus?.map((event) => event.id)
  const { currentPage, ordersPerPage } = page

  // Logic for displaying orders
  const indexOfLastOrder = currentPage * ordersPerPage
  const indexOfFirstOrder = indexOfLastOrder - ordersPerPage

  // Tính toán số trang
  const pageNumbers = useMemo(() => {
    const totalPages = Math.ceil(listOrder.length / ordersPerPage)
    return Array.from({ length: totalPages }, (_, i) => i + 1)
  }, [listOrder, ordersPerPage])

  const handleClearFilter = () => {
    setFilter({
      ...filter,
      listEvent: [],
      eventStatus: 'default',
      rangeDate: [
        {
          startDate: null,
          endDate: null,
          key: 'selection',
        },
      ],
      paymentStatus: [],
    })
    setSearchString('')
  }

  useEffect(() => {
    if (groupId === undefined) return
    const fetchData = async () => {
      try {
        dispatch({
          type: 'GET_EVENT_GROUP_ID',
          payload: { loadingGetEventGroupId: true },
        })

        dispatch(clearEventAndOrder())
        await dispatch(getEventGroupIdByHost(groupId))
      } catch (error) {
        addToast(error.message, { appearance: 'error', autoDismiss: true })
      } finally {
        dispatch({
          type: 'GET_EVENT_GROUP_ID',
          payload: { loadingGetEventGroupId: false },
        })
      }
    }

    fetchData()
  }, [groupId])

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!loadingGetEventGroupId) {
          const listEventId = listEventGroupIdByHost?.map((event) => event.id)
          await Promise.all(listEventId.map((id) => dispatch(getEventAndOrder(id))))
        }

        if (
          loadingGetEventGroupId &&
          isArray(listEventGroupIdByHost) &&
          listEventGroupIdByHost.length === 0
        ) {
          dispatch({
            type: 'LOADING_GET_EVENT_AND_ORDER_END',
          })
        }
      } catch (error) {
        addToast(error.message, { appearance: 'error', autoDismiss: true })
      }
    }

    fetchData()
  }, [loadingGetEventGroupId, JSON.stringify(listEventGroupIdByHost)])

  useEffect(() => {
    if (groupId && eventAndOrderById?.length) {
      const getOrderList = (_eventAndOrderById, _groupId) => {
        const orderList = _eventAndOrderById
          .filter((event) => {
            const isMatchingGroup = event.groupId === _groupId
            return isMatchingGroup
          })
          .flatMap((event) => {
            const { orders, adminCost, discount, deliveryCost, productIdList } = event

            const processedOrders = handleDataOrder(
              orders,
              adminCost,
              discount,
              deliveryCost,
              productIdList || []
            ).listOrder

            return processedOrders
          })

        return orderList
      }

      const orderList = getOrderList(eventAndOrderById, groupId)

      setListOrder(orderList)
    }
  }, [groupId, JSON.stringify(eventAndOrderById)])
  // console.log('eventAndOrderById', eventAndOrderById)

  const onChangeSearch = (event) => {
    setSearchString(event.target.value)
  }

  const handleChangeEvent = (options) => {
    const optionSelected = options.selectedOption.map((option) => option)
    setFilter({
      ...filter,
      listEvent: [...optionSelected],
    })
  }

  const handleChangeDate = (data) => {
    const formatStartDate = new Date(data[0].startDate.setHours(0, 0, 0, 0))
    const formatEndDate = new Date(data[0].endDate.setHours(23, 59, 59, 999))

    setFilter({
      ...filter,
      rangeDate: [
        {
          startDate: formatStartDate,
          endDate: formatEndDate,
          key: 'selection',
        },
      ],
    })
  }

  const handleChangeEventStatus = (event) => {
    const { value } = event.target
    setFilter({
      ...filter,
      eventStatus: value,
    })
  }

  const handlePaymentStatus = (options) => {
    const optionSelected = options.selectedOption.map((option) => option)
    setFilter({
      ...filter,
      paymentStatus: [...optionSelected],
    })
  }

  const currentOrders = useMemo(() => {
    const eventStatusValue = eventStatus === 'default' ? [0, 1] : [Number(eventStatus)]

    let filteredOrders = listOrder.filter((order) => {
      const event = eventAndOrderById.find((item) => item.id === order.eid)

      if (eventId.length > 0 && !eventId.includes(order.eid)) return false
      if (paymentStatus.length > 0 && !paymentStatusValue.includes(order.buyerStatus)) return false
      if (
        startDate !== null &&
        endDate !== null &&
        !moment.unix(order.createdAt).isBetween(startDate, endDate)
      ) {
        return false
      }

      if (!event) return false

      const isDisabled =
        event.pickupTimestamp < now || event.status === EVENT_CLOSE || event.closingTimestamp < now

      const actualStatus = isDisabled ? 0 : Number(event.status)

      return eventStatusValue.includes(actualStatus)
    })

    // Tìm kiếm
    if (searchString) {
      filteredOrders = filteredOrders.filter((item) => {
        const valuesAsString = JSON.stringify(Object.values(item)).toLowerCase()
        return valuesAsString.includes(searchString.toLowerCase())
      })
    }

    // Hàm so sánh để sắp xếp
    const compareFunction = (a, b) => {
      const { field, order, type } = sorting
      const sortOrder = order === 'asc' ? 1 : -1

      if (field === 'createdAt' || !field) {
        return sortOrder * (a.createdAt - b.createdAt)
      }

      const aValue = a[field] ?? (type === 'string' ? '' : 0)
      const bValue = b[field] ?? (type === 'string' ? '' : 0)

      if (type === 'string') {
        return sortOrder * aValue.localeCompare(bValue)
      }

      return sortOrder * (aValue - bValue)
    }

    // Sắp xếp
    const sortedOrders = [...filteredOrders].sort(compareFunction)

    // Phân trang
    return sortedOrders.slice(indexOfFirstOrder, indexOfLastOrder)
  }, [
    JSON.stringify(listOrder),
    listEventGroupIdByHost,
    eventAndOrderById,
    eventId,
    paymentStatus,
    paymentStatusValue,
    startDate,
    endDate,
    eventStatus,
    searchString,
    sorting,
    indexOfFirstOrder,
    indexOfLastOrder,
  ])

  const handlePrev = () => {
    if (currentPage > 1) {
      setPage({
        ...page,
        currentPage: currentPage - 1,
      })
    }
  }

  const handleNext = () => {
    if (currentPage < pageNumbers.length) {
      setPage({
        ...page,
        currentPage: currentPage + 1,
      })
    }
  }

  useEffect(() => {
    if (currentOrders?.length > 0) {
      if (
        pageNumbers.length === 1 &&
        currentOrders?.length === 0 &&
        currentPage > pageNumbers.length
      ) {
        setPage({
          ...page,
          currentPage: 1,
        })
      }
    }
  }, [eventId.length, paymentStatus.length, currentOrders?.length, currentPage, pageNumbers.length])

  const filterBeforeExport = (format) => {
    if (!currentOrders?.length) {
      addToast('No orders to export', { appearance: 'warning', autoDismiss: true })
      return
    }

    const handleSingleEventExport = (_format) => {
      const getEventById = eventAndOrderById?.find((item) => item.id === eventId[0])
      const orderRefs = currentOrders.map((item) => item.ref)
      const filteredOrders = orderRefs
        .map((ref) => getEventById?.orders?.find((order) => order.ref === ref))
        .filter(Boolean)

      if (getEventById && filteredOrders.length > 0) {
        const eventAndOrderByFilter = {
          ...getEventById,
          orders: filteredOrders,
        }
        handleExport(eventAndOrderByFilter, _format, eventAndOrderById)
      }
    }

    const handleMultipleEventExport = (_format) => {
      const uniqueEventIds = [...new Set(currentOrders.map((item) => item.eid))]
      const listEventSortById = uniqueEventIds
        .map((id) => eventAndOrderById?.find((item) => item.id === id))
        .filter(Boolean)

      const orderRefs = currentOrders.map((item) => item.ref)
      const listTotalOrderAfterFilter = listEventSortById.map((event) => {
        const filteredOrders = orderRefs
          .map((ref) => event.orders.find((order) => order.ref === ref))
          .filter(Boolean)

        return {
          ...event,
          orders: filteredOrders,
        }
      })

      handleExportMultipleEventAndOrder(listTotalOrderAfterFilter, _format, eventAndOrderById)
    }

    const handleFilterListOrder = () => {
      if (eventId?.length === 1) {
        handleSingleEventExport(format)
      } else {
        handleMultipleEventExport(format)
      }
    }

    handleFilterListOrder()
  }

  return (
    <div
      id='manage-order-page'
      className='custom-scrollbar'
      style={{ height: 'calc(100vh - 160px)' }}
    >
      <Row className='my-3 ps-3 align-items-center'>
        <Col>
          <span className='fw-bold text-orange fs-2'>Manage Order</span>
        </Col>
        <Col className='d-flex align-items-center text-uppercase justify-content-end'>
          <Button
            role='button'
            className='btn-export rounded-0 ms-3 shadow-none'
            onClick={() => filterBeforeExport(FORMAT_1)}
            outline
          >
            Export List
          </Button>
          <Button
            role='button'
            className='btn-export rounded-0 ms-3 shadow-none'
            onClick={() => filterBeforeExport(FORMAT_2)}
            outline
          >
            Export Matrix
          </Button>
        </Col>
      </Row>

      <Row className='align-items-center ps-3 mb-3'>
        <Col>
          <OrderSearch
            searchString={searchString}
            onChange={onChangeSearch}
            styles={{ marginTop: 0 }}
          />
        </Col>
        <Col className='d-flex align-items-center'>
          <Pagination
            filter={filter}
            onHandlePrev={handlePrev}
            onHandleNext={handleNext}
            currentOrders={currentOrders}
          />
        </Col>
      </Row>

      <Row className='ps-3'>
        <Col>
          <SelectFilter
            filter={filter}
            listEventGroupIdByHost={listEventGroupIdByHost}
            onChangeEvent={handleChangeEvent}
            onChangeEventStatus={handleChangeEventStatus}
            onChangePaymentStatus={handlePaymentStatus}
            onChangeRangeDate={handleChangeDate}
            handleClearFilter={handleClearFilter}
          />
        </Col>
      </Row>

      <div className='row-top'>
        <div className='d-flex list-event-note align-items-center justify-content-between px-3'>
          <div>
            Click on Names for Order Details and on <i className='icon-link'></i> for Payment
            Details.
          </div>
          {rangeDate[0].startDate && rangeDate[0].endDate && (
            <div className='text-center'>
              Listing orders from{' '}
              <strong className='text-orange'>
                {moment(rangeDate[0].startDate).format('DD/MM/YYYY')}
              </strong>{' '}
              to{' '}
              <strong className='text-orange'>
                {moment(rangeDate[0].endDate).format('DD/MM/YYYY')}
              </strong>
            </div>
          )}
        </div>

        {loadingGetEventGroupId ? (
          <LoadingComponent className='mt-5' message={'Loading get events by group Id...'} />
        ) : loadingGetEventAndOrder ? (
          <LoadingComponent className='mt-5' message={'Loading get orders....'} />
        ) : currentOrders?.length ? (
          <OrderList listOrder={currentOrders} setSorting={setSorting} />
        ) : (
          <EmptyStateMessage message='No Orders Found' />
        )}
      </div>
    </div>
  )
}

export default WithErrorBoundary(ManageOrder)
