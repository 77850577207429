import PropTypes from 'prop-types'
import React from 'react'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'

export default function ModalConfirm(props) {
  const { modal, toggleModal, handleConfirm, title = '' } = props

  return (
    <Modal isOpen={modal} toggle={toggleModal} className='modal-cancel' centered>
      <ModalHeader toggle={toggleModal} className='pt-1 pb-1 text-left'>
        Cancel this {title}
      </ModalHeader>

      <ModalBody>Do you want to cancel this {title} ?</ModalBody>

      <ModalFooter>
        <button
          type='button'
          className='btn-no btn-secondary rounded-pill p-0'
          onClick={toggleModal}
          style={{ minWidth: '100px', height: '40px' }}
        >
          No
        </button>
        <button
          type='button'
          className='btn-yes btn-primary rounded-pill p-0'
          onClick={handleConfirm}
          style={{ minWidth: '100px', height: '40px' }}
        >
          Yes
        </button>
      </ModalFooter>
    </Modal>
  )
}

ModalConfirm.propTypes = {
  modal: PropTypes.bool,
  toggleModal: PropTypes.func,
  handleConfirm: PropTypes.func,
}
