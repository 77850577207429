/* eslint-disable array-callback-return */
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useToasts } from 'react-toast-notifications'

import deliveryApi from '_api/delivery'
import { DEFAULT_DELIVERY_OPTION } from '_utils/constant'
import { getCreateEventData, getUserInfo, setCreateEventData } from '_utils/localData'

import { idGenerator } from '../../../utils/function'
import DeliveryOptionsModal from './DeliveryOptionsModal'

export const AddDeliveryOption = (props) => {
  const { hiddenDelivery: defaultVal, setValue, getValues } = props
  const { addToast } = useToasts()

  const userInfo = getUserInfo()

  if (!userInfo) {
    addToast('User info not found', { appearance: 'error', autoDismiss: true })
    return null
  }

  const { id: userId } = userInfo

  const eventData = getCreateEventData() || {}
  const { deliveryOption = {} } = eventData
  const isEventShop = !!eventData?.shopId

  const [checked, setChecked] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [deliveryZones, setDeliveryZones] = useState([])
  const [deliveryTime, setDeliveryTime] = useState(
    new Date(new Date().getTime() + 24 * 8 * 60 * 60 * 1000)
  )
  const [errorMessageDeliveryZone, setErrorMessageDeliveryZone] = useState('')
  const [deliveryHourStart, setDeliveryHourStart] = useState('10AM')
  const [deliveryHourEnd, setDeliveryHourEnd] = useState('1PM')
  const [zoneErrors, setZoneErrors] = useState({})

  const validatePrice = (price) => price >= 0
  const validateZoneName = (name) => name.trim() !== ''

  const validateDeliveryZone = (zone) => {
    const errors = {}

    if (!validateZoneName(zone.name)) {
      errors.name = 'Name is required'
    }

    if (!validatePrice(zone.price)) {
      errors.price = 'Price must be greater than 0'
    }

    return errors
  }

  const toggleModal = () => {
    setOpenModal(!openModal)
  }

  const handleChange = (e) => {
    const isCheck = e.target.checked
    setChecked(isCheck)
    if (isCheck) {
      setOpenModal(true)

      setCreateEventData({
        ...eventData,
        addDelivery: true,
      })
    } else {
      setValue('addDelivery', false)
      setCreateEventData({
        ...eventData,
        addDelivery: false,
      })
    }
  }

  const onChangeZoneDelivery = (index, type, value) => {
    const updatedZones = [...deliveryZones]
    updatedZones[index][type] = value
    setDeliveryZones(updatedZones)

    // Update errors only for the changed zone
    const errors = { ...zoneErrors }
    errors[index] = validateDeliveryZone(updatedZones[index])
    setZoneErrors(errors)
  }

  const hasValidationErrors = () =>
    deliveryZones.some((zone) => {
      const errors = validateDeliveryZone(zone)
      return Object.keys(errors).length > 0
    })

  const handleOnClickOk = () => {
    const data = deliveryZones.filter((item) => {
      if (item.name && item.price >= 0) {
        return item
      }
    })

    if (data.length === 0) {
      setErrorMessageDeliveryZone('Please add at least one delivery zone')
      return
    }

    setErrorMessageDeliveryZone('')

    if (hasValidationErrors()) {
      return
    }

    // eslint-disable-next-line no-shadow
    const deliveryOption = {
      deliveryZones: data,
      deliveryTime: moment(deliveryTime).format('DD-MM-YYYY'),
      deliveryHourStart,
      deliveryHourEnd,
    }

    setDeliveryZones(JSON.parse(JSON.stringify(data)))
    setCreateEventData({
      ...eventData,
      deliveryOption,
      addDelivery: true,
    })
    setValue('deliveryOption', deliveryOption)
    setValue('addDelivery', true)
    toggleModal()
  }

  const handleOnRemoveDeliveryZone = (index) => {
    deliveryZones.splice(index, 1)

    if (deliveryZones.length === 0) {
      deliveryZones.push({ name: '', price: 0, id: idGenerator() })
      setDeliveryZones(JSON.parse(JSON.stringify([...deliveryZones])))
    } else {
      setDeliveryZones(JSON.parse(JSON.stringify([...deliveryZones])))
    }
  }

  const handleAddMoreDeliveryZone = () => {
    setDeliveryZones(
      JSON.parse(
        JSON.stringify([...deliveryZones, { ...DEFAULT_DELIVERY_OPTION, id: idGenerator() }])
      )
    )
  }

  const handleRemoveRemember = async () => {
    try {
      await deliveryApi.updateRememberedDeliveryZones(
        userId,
        { rememberedDeliveryZones: [] },
        isEventShop
      )
    } catch ({ msgResp }) {
      addToast(msgResp, { appearance: 'error', autoDismiss: true })
    }
  }

  const onChangeTimeDelivery = (e) => {
    setDeliveryTime(e)
  }

  useEffect(() => {
    let isMounted = true
    if (deliveryOption && Object.keys(deliveryOption).length && !isEventShop) {
      // eslint-disable-next-line no-shadow
      const { deliveryZones, deliveryTime, deliveryHourStart, deliveryHourEnd } = deliveryOption

      const zonesWithId = (deliveryZones || []).map((item) => ({
        ...item,
        id: idGenerator(),
      }))

      setDeliveryZones(zonesWithId)

      const deliveryTimeSplit = deliveryTime.split(' ')[0].split('-').reverse()
      setDeliveryTime(
        new Date(deliveryTimeSplit[0], deliveryTimeSplit[1] - 1, deliveryTimeSplit[2])
      )

      setDeliveryHourStart(deliveryHourStart)
      setDeliveryHourEnd(deliveryHourEnd)
    } else if (
      deliveryOption &&
      deliveryOption?.deliveryZones &&
      deliveryOption?.deliveryZones?.length === 0
    ) {
      setDeliveryZones([{ name: '', price: 0, id: idGenerator() }])
    } else {
      const getRemembered = async () => {
        try {
          const { msgResp } = await deliveryApi.getRememberedDeliveryZones(
            eventData?.shopId,
            isEventShop
          )

          const zonesWithId = msgResp.map((item) => ({
            ...item,
            id: idGenerator(),
          }))

          if (zonesWithId.length === 0 && isMounted) {
            zonesWithId.push({ name: '', price: 0, id: idGenerator() })

            setDeliveryZones(zonesWithId)
          } else if (isMounted) {
            setDeliveryZones(zonesWithId)
          }
        } catch (error) {
          addToast('Failed to fetch remembered delivery zones', {
            appearance: 'error',
            autoDismiss: true,
          })

          if (isMounted) {
            setDeliveryZones([{ name: '', price: 0, id: idGenerator() }])
          }
        }
      }
      getRemembered()
    }

    return () => {
      isMounted = false
    }
  }, [Object.keys(deliveryOption).length, checked, eventData?.shopId])

  useEffect(() => {
    setChecked(!!defaultVal)
    if (!defaultVal) {
      setValue('deliveryOption', {})
      setValue('addDelivery', false)
      setCreateEventData({
        ...eventData,
        addDelivery: false,
      })
    }
  }, [defaultVal])

  useEffect(() => {
    if (
      getValues()?.collectionDate &&
      getValues()?.collectionTimeStart &&
      getValues()?.collectionTimeEnd
    ) {
      setDeliveryTime(getValues()?.collectionDate)
      setDeliveryHourStart(getValues()?.collectionTimeStart)
      setDeliveryHourEnd(getValues()?.collectionTimeEnd)
      setValue('collectionDate', null)
      setValue('collectionTimeStart', '')
      setValue('collectionTimeEnd', '')
    }
  }, [
    getValues()?.collectionDate,
    getValues()?.collectionTimeStart,
    getValues()?.collectionTimeEnd,
  ])

  return (
    <div className='havebg'>
      <div className='row'>
        <div className='col-12'>
          <div className='custom-control custom-checkbox'>
            <input
              id='deliveryOptionCheckBox'
              type='checkbox'
              onChange={(e) => handleChange(e)}
              className='custom-control-input'
              checked={checked}
            />
            <label className='custom-control-label' htmlFor='deliveryOptionCheckBox'>
              Add Delivery Options
            </label>
          </div>
          <small className='error'></small>
        </div>
      </div>
      {openModal && (
        <DeliveryOptionsModal
          modal={openModal}
          toggle={toggleModal}
          handleOnClickOk={handleOnClickOk}
          handleRemoveRemember={handleRemoveRemember}
          deliveryZones={deliveryZones}
          deliveryTime={deliveryTime}
          onChangeZoneDelivery={onChangeZoneDelivery}
          onChangeTimeDelivery={onChangeTimeDelivery}
          handleOnRemoveDeliveryZone={handleOnRemoveDeliveryZone}
          handleAddMoreDeliveryZone={handleAddMoreDeliveryZone}
          deliveryHourStart={deliveryHourStart}
          setDeliveryHourStart={setDeliveryHourStart}
          deliveryHourEnd={deliveryHourEnd}
          setDeliveryHourEnd={setDeliveryHourEnd}
          errorMessageDeliveryZone={errorMessageDeliveryZone}
          zoneErrors={zoneErrors}
          hasValidationErrors={hasValidationErrors}
          {...props}
        />
      )}
    </div>
  )
}
