const useLogout = () => {
  const preKeys = ['isShareGroup', 'USER_FROM_COHOOT']
  for (const key in localStorage) {
    if (!preKeys.includes(key)) {
      localStorage.removeItem(key)
    }
  }

  // localStorage.clear()
  window.location.assign('/')
}

export default useLogout
