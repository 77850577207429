import classNames from 'classnames'
import PropTypes from 'prop-types'
import React, { useEffect, useRef, useState } from 'react'
import { Tooltip } from 'reactstrap'

const TruncatedText = ({
  text,
  id,
  className = '',
  style = {},
  isHasContainer = false,
  isTooltip = true,
}) => {
  const [isOverflowing, setIsOverflowing] = useState(false)
  const [tooltipOpen, setTooltipOpen] = useState(false)
  const textRef = useRef(null)

  const safeId = `tooltip-${
    id
      ?.toString()
      .toLowerCase()
      .replace(/[^a-z0-9]/g, '') || Math.random().toString(36).substr(2, 9)
  }`

  useEffect(() => {
    const checkOverflow = () => {
      const element = textRef.current
      if (element) {
        const isTextOverflowing = element.offsetWidth < element.scrollWidth
        setIsOverflowing(isTextOverflowing)
      }
    }

    checkOverflow()
    const timer = setTimeout(checkOverflow, 100)
    window.addEventListener('resize', checkOverflow)

    return () => {
      window.removeEventListener('resize', checkOverflow)
      clearTimeout(timer)
    }
  }, [text])

  const toggleTooltip = () => {
    setTooltipOpen(!tooltipOpen)
  }

  const TruncateContainer = ({ children }) => {
    if (isHasContainer) {
      return <div style={{ position: 'relative', width: '100%', ...style }}>{children}</div>
    }

    return <>{children}</>
  }

  return (
    <TruncateContainer>
      <span
        className={classNames('truncate px-0', className)}
        style={{
          background: 'transparent',
          ...(isHasContainer ? { position: 'unset', padding: 0 } : { position: 'unset' }),
        }}
        ref={textRef}
        id={safeId}
        onMouseEnter={() => isOverflowing && isTooltip && setTooltipOpen(true)}
        onMouseLeave={() => setTooltipOpen(false)}
      >
        {text}
      </span>
      {((isTooltip && isOverflowing) || (isTooltip && tooltipOpen)) && (
        <Tooltip
          isOpen={tooltipOpen}
          target={safeId}
          toggle={toggleTooltip}
          placement='top'
          className='tooltip'
          container='body'
          fade={false}
          delay={{ show: 0, hide: 100 }}
          popperConfig={{
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [0, 8],
                },
              },
              {
                name: 'preventOverflow',
                options: {
                  boundary: 'viewport',
                  padding: 8,
                },
              },
            ],
          }}
        >
          {text}
        </Tooltip>
      )}
    </TruncateContainer>
  )
}

TruncatedText.propTypes = {
  text: PropTypes.string.isRequired,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isTooltip: PropTypes.bool,
}

export default TruncatedText
