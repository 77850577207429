/* eslint-disable function-paren-newline */
import { getDeliveryByBuyer } from '_redux/modules/delivery'
import { getListCombinedPayment } from '_redux/modules/event'
import { getMyOrders } from '_redux/modules/order'
import { DELIVERY_OPENED } from '_utils/constant'
import {
  convertTimeStringWithDuration,
  convertTimeStringWithDurationTwoPart,
  convertToCurrentGMT,
  sortListMyDelivery,
  sortListMyOrder,
} from '_utils/function'
import { getUserInfo } from '_utils/localData'
import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import LoadingOverlay from '_components/LoadingOverlay'
import { getMulListDeliveryCreatedByHost } from '../../../redux/modules/delivery'
import { getListShopPaid } from '../../../redux/modules/shop'
import BoxAddDeliveryByBuyer from '../components/BoxAddDeliveryByBuyer'
import ItemDeliveryBooking from '../components/ItemDeliveryBooking'
import ItemOrder from '../components/ItemOrder'
import ModalDelivery from '../components/ModalDelivery'

const now = moment().unix()

function ListMyPurchases() {
  const history = useHistory()
  const dispatch = useDispatch()
  const userInfo = getUserInfo()
  if (!userInfo) {
    history.push('/sign-in')
    return null
  }
  const { id: userId } = userInfo
  const lastTypeRef = useRef(null)
  const [isLoading, setIsLoading] = useState(false)
  const [isShowModal, setIsShowModal] = useState(false)
  const [deliveryInfo, setDeliveryInfo] = useState(null)

  const { myOrders, listHostId, listHostDetail } = useSelector((state) => state.order)

  const { listDeliveryCreatedByHost, listDeliveryByBuyer } = useSelector((state) => state.delivery)
  const { listShop } = useSelector((state) => state.shop)

  const initialOrders = async () => {
    setIsLoading(true)
    await dispatch(getMyOrders)
    await dispatch(getListShopPaid())
    await dispatch(getDeliveryByBuyer(userId))
    setIsLoading(false)
  }

  const initListCombined = async (_myOrders, _listDeliveryByBuyer) => {
    if (_myOrders?.length && _listDeliveryByBuyer?.length) {
      const listCombinedPaymentRef = []
      _myOrders.forEach((element) => {
        const { combinedPaymentRef = '' } = element
        if (!listCombinedPaymentRef.includes(combinedPaymentRef)) {
          if (combinedPaymentRef) {
            listCombinedPaymentRef.push(combinedPaymentRef)
          }
        }
      })
      _listDeliveryByBuyer.forEach((element) => {
        const { combinedPaymentRef = '' } = element
        if (!listCombinedPaymentRef.includes(combinedPaymentRef)) {
          if (combinedPaymentRef) {
            listCombinedPaymentRef.push(combinedPaymentRef)
          }
        }
      })
      if (listCombinedPaymentRef.length) {
        await dispatch(getListCombinedPayment(listCombinedPaymentRef))
      }
    }
  }

  const addDelivery = async (hostId, entityId) => {
    const { listDeliveryCreated } = listDeliveryCreatedByHost.find((item) => item.hostId === hostId)

    const { deliveryZones } = listDeliveryCreated[0]

    const { eCreatedUserName, eCreatedUserPhotoUrl } = listHostDetail?.find(
      (item) => item.eCreatedUserId === hostId
    )

    const newListDeliveryCreated = listDeliveryCreated
      .sort((a, b) => b.deliveryTimestamp - a.deliveryTimestamp)
      .filter(
        (item) =>
          (item.groupId === entityId || item.shopId === entityId) &&
          item.deliveryStatus === DELIVERY_OPENED &&
          item.deliveryTimestamp > now
      )
      .map((item) => ({
        ...item,
        deliveryTime: convertTimeStringWithDuration(item.deliveryTime, item.deliveryDuration),
      }))

    setDeliveryInfo({
      deliveryZones: newListDeliveryCreated[0]?.deliveryZones || deliveryZones,
      listDeliveryCreated: newListDeliveryCreated,
      eCreatedUserName,
      eCreatedUserPhotoUrl,
      entityId,
    })
    setIsShowModal(true)
  }

  const handleToDeliveryDetail = (_ref, dShopId) => {
    let url = `/event/delivery-detail?ref=${_ref}`
    if (dShopId) {
      url += `&shopId=${dShopId}`
    }
    history.push(url)
  }

  const filterValidDeliveries = (deliveryHost) => {
    const listCreated = deliveryHost.listDeliveryCreated

    return listCreated.filter((delivery) => {
      const isStatusOpen = delivery.deliveryStatus === DELIVERY_OPENED
      const isFutureDelivery = delivery.deliveryTimestamp > now
      const hasDeliveryZones = delivery.deliveryZones && delivery.deliveryZones.length > 0

      return isStatusOpen && isFutureDelivery && hasDeliveryZones
    })
  }

  const renderHostDetail = (hostDetail, index, _lastTypeRef) => {
    const {
      eCreatedUserName,
      eCreatedUserPhotoUrl,
      eCreatedUserId,
      eGroupName,
      eGroupId,
      eShopId,
      eShopName,
    } = hostDetail

    const currentType = eGroupId ? 'Delivery Group' : 'Delivery Shop'
    const showHeader = currentType !== _lastTypeRef.current
    _lastTypeRef.current = currentType

    return (
      <React.Fragment key={index}>
        {showHeader && (
          <p className='mb-2' style={{ fontSize: 16, fontWeight: 600 }}>
            {currentType}
          </p>
        )}
        <BoxAddDeliveryByBuyer
          createdUserPhotoUrl={eCreatedUserPhotoUrl}
          createdUserName={eCreatedUserName}
          groupName={eGroupName}
          hostId={eCreatedUserId}
          groupId={eGroupId}
          addDelivery={addDelivery}
          shopId={eShopId}
          shopName={eShopName}
          hideHeader={true}
        />
      </React.Fragment>
    )
  }

  const renderBoxAddDelivery = () => {
    if (
      listHostDetail &&
      listHostDetail.length &&
      listDeliveryCreatedByHost &&
      listDeliveryCreatedByHost.length === listHostId.length
    ) {
      const listFiltered = listHostDetail.filter((hostDetail) => {
        const deliveryHost = listDeliveryCreatedByHost.find(
          (delivery) => delivery.hostId === hostDetail.eCreatedUserId
        )

        if (!deliveryHost) return false

        const validDeliveries = filterValidDeliveries(deliveryHost)

        const hasValidGroupDelivery = validDeliveries.some(
          (delivery) => delivery.groupId === hostDetail.eGroupId
        )
        const hasValidShopDelivery = validDeliveries.some(
          (delivery) => delivery.shopId === hostDetail.eShopId
        )

        return hasValidGroupDelivery && hasValidShopDelivery
      })

      return listFiltered.map((hostDetail, index) =>
        renderHostDetail(hostDetail, index, lastTypeRef)
      )
    }
    return null
  }

  useEffect(() => {
    initialOrders()
  }, [])

  useEffect(() => {
    if (!listShop.length) {
      dispatch(getListShopPaid())
    }
  }, [listShop.length])

  useEffect(() => {
    initListCombined(myOrders, listDeliveryByBuyer)
  }, [JSON.stringify(myOrders), JSON.stringify(listDeliveryByBuyer)])

  useEffect(() => {
    if (listHostId && listHostId.length > 0 && listShop && listShop.length > 0) {
      const hostShopMap = listHostId.map((hostId) => ({
        hostId,
        listShop: listShop.filter((shop) => shop.createdUserId === hostId).map((shop) => shop.id),
      }))

      dispatch(getMulListDeliveryCreatedByHost(hostShopMap))
    }
  }, [listHostId, listShop])

  return (
    <React.Fragment>
      {isLoading && <LoadingOverlay />}
      {!isLoading && (
        <div className='group-list group-list--event mt-3'>
          {renderBoxAddDelivery()}

          {listDeliveryByBuyer &&
            !!listDeliveryByBuyer.length &&
            sortListMyDelivery(listDeliveryByBuyer).listDeliveryActiveSort.map((item, index) => (
              <ItemDeliveryBooking
                key={index}
                createdUserPhotoUrl={item.dCreatedUserPhotoUrl}
                createdUserName={item.dCreatedUserName}
                groupName={item.dGroupName}
                shopName={item.dShopName}
                deliveryTimeString={convertTimeStringWithDurationTwoPart(
                  item.dDeliveryTime,
                  item.dDeliveryDuration
                )}
                deliveryTime={convertToCurrentGMT(item.dDeliveryTime)}
                bookerStatus={item.bookerStatus}
                status={item.status}
                combinedPaymentRef={item.combinedPaymentRef || null}
                dRef={item.ref}
                onClick={() => handleToDeliveryDetail(item.ref, item?.dShopId)}
              />
            ))}

          {(!myOrders || !myOrders?.length) && (
            <p className='fw-bold text-center my-3' style={{ fontSize: '1rem' }}>
              No purchase found.
            </p>
          )}
          {myOrders &&
            !!myOrders.length &&
            sortListMyOrder(myOrders).listOrderActiveSort.map((o, i) => (
              <ItemOrder key={o.id || i} order={o} />
            ))}

          {listDeliveryByBuyer &&
            !!listDeliveryByBuyer.length &&
            sortListMyDelivery(listDeliveryByBuyer).listDeliveryDisableSort.map((item, index) => (
              <ItemDeliveryBooking
                key={index}
                createdUserPhotoUrl={item.dCreatedUserPhotoUrl}
                createdUserName={item.dCreatedUserName}
                groupName={item.dGroupName}
                shopName={item.dShopName}
                deliveryTimeString={convertTimeStringWithDurationTwoPart(
                  item.dDeliveryTime,
                  item.dDeliveryDuration
                )}
                deliveryTime={convertToCurrentGMT(item.dDeliveryTime)}
                bookerStatus={item.bookerStatus}
                status={item.status}
                combinedPaymentRef={item.combinedPaymentRef || null}
                dRef={item.ref}
                onClick={() => handleToDeliveryDetail(item.ref)}
                isDisable={true}
              />
            ))}

          {myOrders &&
            !!myOrders.length &&
            sortListMyOrder(myOrders).listOrderDisableSort.map((o, i) => (
              <ItemOrder key={o.id || i} order={o} />
            ))}
        </div>
      )}
      {isShowModal && (
        <ModalDelivery
          isShowModal={isShowModal}
          deliveryInfo={deliveryInfo}
          toggle={() => setIsShowModal(false)}
        />
      )}
    </React.Fragment>
  )
}

export default ListMyPurchases
