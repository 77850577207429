import moment from 'moment'
import React, { useContext, useEffect, useState } from 'react'

import deliveryApi from '_api/delivery'
import {
  callNewDeliveryCreatedByHost,
  getListDeliveryCreatedByHost,
  getScheduledDelivery,
} from '_redux/modules/delivery'
import { getMyGroups } from '_redux/modules/group'
import { DEFAULT_DELIVERY_OPTION, GROUP_IS_HOST, LIST_TIME } from '_utils/constant'
import { convertTimeStringWithDuration, convertToCurrentGMT, convertToGMT0 } from '_utils/function'
import { getUserInfo } from '_utils/localData'
import { useDispatch, useSelector } from 'react-redux'
import { useToasts } from 'react-toast-notifications'

import WithErrorBoundary from '_components/WithErrorBoundary'

import { SelectIdGroupContext } from '_context/SelectGroupContext'
import BoxDeliveryDateByHost from '_pages/Event/components/BoxDeliveryDateByHost'
import DeliveryOptionsModal from '_pages/Event/components/DeliveryOptionsModal'
import MyScheduledDelivery from '_pages/Event/components/MyScheduleDelivery'
import LoadingComponent from '../../../components/LoadingComponent'
import { addToListDeliveryCreatedByHost } from '../../../redux/modules/delivery'
import './style.scss'

const now = moment().unix()

const validatePrice = (price) => price >= 0
const validateZoneName = (name) => name.trim() !== ''

const validateDeliveryZone = (zone) => {
  const errors = {}

  if (!validateZoneName(zone.name)) {
    errors.name = 'Name is required'
  }

  if (!validatePrice(zone.price)) {
    errors.price = 'Price must be greater than 0'
  }

  return errors
}

const DeliveryList = () => {
  const { infoGroupSelected } = useContext(SelectIdGroupContext)
  const dispatch = useDispatch()
  const { addToast } = useToasts()

  const userInfo = getUserInfo()
  if (!userInfo) {
    addToast('User info not found', { appearance: 'error', autoDismiss: true })
    return null
  }
  const { id: userId } = userInfo
  const { listDeliveryCreatedByHost, allScheduleDelivery } = useSelector((state) => state.delivery)

  let { listDeliveryCreated = null } =
    listDeliveryCreatedByHost.find((item) => item.hostId === userId) || {}

  listDeliveryCreated = listDeliveryCreated
    ? listDeliveryCreated
        .filter((item) => item)
        .sort((a, b) => a.deliveryTimestamp - b.deliveryTimestamp)
    : null

  const { listScheduleDelivery = null } =
    allScheduleDelivery.find((item) => item.hostId === userId) || {}

  const { myGroups } = useSelector((state) => state.group)
  const [isLoading, setIsLoading] = useState(false)
  const [openModalAddDelivery, setOpenModalAddDelivery] = useState(false)
  const [timeReRender, setTimeReRender] = useState(0)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [errorMessageDeliveryZone, setErrorMessageDeliveryZone] = useState('')
  const [deliveryZones, setDeliveryZones] = useState([])

  const [deliveryHourStart, setDeliveryHourStart] = useState('10AM')
  const [deliveryHourEnd, setDeliveryHourEnd] = useState('1PM')
  const [timeGetScheduleDelivery, setTimeGetScheduleDelivery] = useState(0)
  const [zoneErrors, setZoneErrors] = useState({})

  const filteredListDeliveryCreated = listDeliveryCreated?.filter(
    (item) => item.groupId === infoGroupSelected?.groupId
  )

  const toggleModalAddDelivery = () => {
    setOpenModalAddDelivery(!openModalAddDelivery)
  }

  useEffect(() => {
    let isMounted = true

    const getEvents = async () => {
      if (isMounted) setIsLoading(true)
      try {
        await dispatch(getMyGroups)
        await Promise.all([
          dispatch(getScheduledDelivery(userId)),
          dispatch(getListDeliveryCreatedByHost(userId, '')),
        ])
      } catch (error) {
        if (isMounted) {
          addToast(error?.message, { appearance: 'error', autoDismiss: true })
        }
      }
      if (isMounted) setIsLoading(false)
    }

    getEvents()

    return () => {
      isMounted = false
    }
  }, [infoGroupSelected])

  const [optionGroup, setOptionGroup] = useState([])
  const [selectedGroup, setSelectedGroup] = useState('')

  const [deliveryTime, setDeliveryTime] = useState(
    new Date(new Date().getTime() + 24 * 60 * 60 * 1000)
  )

  useEffect(() => {
    if (infoGroupSelected?.groupId && myGroups) {
      const hostForGroup = myGroups?.filter((group) => group.isHost === GROUP_IS_HOST)
      setOptionGroup([...hostForGroup])
      setSelectedGroup(infoGroupSelected?.groupId)
    }
  }, [infoGroupSelected?.groupId, myGroups])

  useEffect(() => {
    dispatch(getListDeliveryCreatedByHost(userId, ''))
  }, [timeReRender])

  useEffect(() => {
    if (!openModalAddDelivery) {
      setErrorMessageDeliveryZone('')
    }
  }, [openModalAddDelivery])

  const hasValidationErrors = () =>
    deliveryZones.some((zone) => {
      const errors = validateDeliveryZone(zone)
      return Object.keys(errors).length > 0
    })

  const handleOnClickOk = async () => {
    setIsSubmitting(true)
    const deliveryStartIndex = LIST_TIME.findIndex((item) => item.text === deliveryHourStart)
    const deliveryEndIndex = LIST_TIME.findIndex((item) => item.text === deliveryHourEnd)
    const timeStart = LIST_TIME[deliveryStartIndex].value
    const timeEnd = LIST_TIME[deliveryEndIndex].value
    const deliveryDuration = (timeEnd - timeStart) * 3600
    if (deliveryDuration < 0) {
      addToast('Invalid delivery time duration', { appearance: 'error', autoDismiss: true })
      setIsSubmitting(false)
      return
    }

    const rememberedDeliveryZones = deliveryZones
      .map(({ name, price }) => ({ name, price }))
      .filter((item) => item.name && item.price >= 0)

    if (!rememberedDeliveryZones.length) {
      setErrorMessageDeliveryZone('Please fill in all delivery zones')
      setIsSubmitting(false)
      return
    }

    const deliveryTimeLocal = `${moment(deliveryTime).format('DD-MM-YYYY')} ${timeStart}:00`

    const deliveryTimestamp = moment(deliveryTimeLocal, 'DD-MM-YYYY HH:mm').unix()

    if (deliveryTimestamp < now) {
      setIsSubmitting(false)
      addToast('Delivery time must after current time', { appearance: 'error', autoDismiss: true })
      return
    }

    const deliveryTimeGMT0 = convertToGMT0(deliveryTimeLocal)

    if (!rememberedDeliveryZones?.length) {
      return addToast('Please add at least one delivery zone', {
        appearance: 'error',
        autoDismiss: true,
      })
    }

    if (hasValidationErrors()) {
      setIsSubmitting(false)
      return
    }

    try {
      const { msgResp } = await deliveryApi.createDeliveryByHost({
        groupId: selectedGroup,
        deliveryTime: deliveryTimeGMT0,
        deliveryDuration,
      })

      dispatch(addToListDeliveryCreatedByHost(userId, msgResp))

      dispatch(callNewDeliveryCreatedByHost())
      setTimeReRender((prevState) => prevState + 1)

      await deliveryApi.updateRememberedDeliveryZones(userId, {
        rememberedDeliveryZones,
      })
      addToast('Added delivery', { appearance: 'success', autoDismiss: true })
    } catch (error) {
      setIsSubmitting(false)
      addToast(error?.msgResp, { appearance: 'error', autoDismiss: true })
      return
    }
    setIsSubmitting(false)
    toggleModalAddDelivery()
  }

  const onChangeTimeDelivery = (e) => {
    setDeliveryTime(e)
  }

  const onChangeZoneDelivery = (index, type, value) => {
    const updatedZones = [...deliveryZones]
    updatedZones[index][type] = value
    setDeliveryZones(updatedZones)

    // Update errors only for the changed zone
    const errors = { ...zoneErrors }
    errors[index] = validateDeliveryZone(updatedZones[index])
    setZoneErrors(errors)
  }

  const handleAddMoreDeliveryZone = () => {
    setDeliveryZones(JSON.parse(JSON.stringify([...deliveryZones, DEFAULT_DELIVERY_OPTION])))
  }

  const handleOnRemoveDeliveryZone = (index) => {
    deliveryZones.splice(index, 1)
    setDeliveryZones(JSON.parse(JSON.stringify([...deliveryZones])))
  }

  const onOpenAddDelivery = async () => {
    const { msgResp } = await deliveryApi.getRememberedDeliveryZones()
    if (msgResp.length > 0) {
      setDeliveryZones(msgResp)
    } else {
      setDeliveryZones(JSON.parse(JSON.stringify([...deliveryZones, DEFAULT_DELIVERY_OPTION])))
    }
    toggleModalAddDelivery()
  }

  const renderListDeliveryActive = () => {
    if (filteredListDeliveryCreated?.length > 0) {
      return filteredListDeliveryCreated
        .filter((ele) => ele.deliveryTimestamp >= now)
        .map((item, index) => (
          <BoxDeliveryDateByHost
            key={index}
            createdUserPhotoUrl={item.createdUserPhotoUrl}
            createdUserName={item.createdUserName}
            entityName={item.groupName || item.shopName}
            deliveryTimeString={convertTimeStringWithDuration(
              item.deliveryTime,
              item.deliveryDuration
            )}
            deliveryTime={convertToCurrentGMT(item.deliveryTime)}
            deliveryId={item.id}
          />
        ))
    }

    return null
  }

  const renderListDeliveryClosed = () => {
    if (filteredListDeliveryCreated?.length > 0) {
      return filteredListDeliveryCreated
        .filter((ele) => ele.deliveryTimestamp < now)
        .map((item, index) => (
          <BoxDeliveryDateByHost
            key={`past-${index}`}
            createdUserPhotoUrl={item.createdUserPhotoUrl}
            createdUserName={item.createdUserName}
            entityName={item.groupName || item.shopName}
            deliveryTimeString={convertTimeStringWithDuration(
              item.deliveryTime,
              item.deliveryDuration
            )}
            deliveryTime={convertToCurrentGMT(item.deliveryTime)}
            deliveryId={item.id}
            greyOut={true}
          />
        ))
    }

    return null
  }

  const renderListScheduleDelivery = () => {
    if (listScheduleDelivery?.length > 0) {
      return listScheduleDelivery
        ?.filter((delivery) => delivery?.groupId === infoGroupSelected?.groupId)
        ?.map((delivery, index) => (
          <MyScheduledDelivery
            key={`scheduled-${index}`}
            delivery={delivery}
            hostId={userId}
            setTimeGetScheduleDelivery={setTimeGetScheduleDelivery}
          />
        ))
    }

    return null
  }

  useEffect(() => {
    dispatch(getScheduledDelivery(userId))
  }, [timeGetScheduleDelivery])

  return (
    <div
      className='row-top my-events-page custom-scrollbar'
      style={{
        height: 'calc(100vh - 160px)',
      }}
    >
      {isLoading && (
        <LoadingComponent
          message={'Loading get delivery list...'}
          style={{
            marginTop: '20%',
          }}
        />
      )}
      {!isLoading ? (
        <>
          <div className='hosted__event-list-desktop size-width-desktop-col-2'>
            <h3 className='hosted__event-list-desktop-title'>Manage Delivery</h3>
            <div
              style={{ width: '100px', marginLeft: '89%' }}
              className='text-add-delivery d-flex flex-row-reverse mt-2 mb-2'
              onClick={onOpenAddDelivery}
            >
              Add delivery
            </div>
            <div className='hosted__event-list-desktop-item-grid'>
              {renderListDeliveryActive()}
              {renderListDeliveryClosed()}
              {renderListScheduleDelivery()}
            </div>

            {filteredListDeliveryCreated?.length === 0 &&
              !listScheduleDelivery?.length &&
              !isLoading && <div>You don&apos;t have any deliveries!</div>}

            {openModalAddDelivery && (
              <DeliveryOptionsModal
                modal={openModalAddDelivery}
                toggle={toggleModalAddDelivery}
                handleOnClickOk={handleOnClickOk}
                deliveryZones={deliveryZones}
                deliveryTime={deliveryTime}
                onChangeZoneDelivery={onChangeZoneDelivery}
                onChangeTimeDelivery={onChangeTimeDelivery}
                handleAddMoreDeliveryZone={handleAddMoreDeliveryZone}
                handleOnRemoveDeliveryZone={handleOnRemoveDeliveryZone}
                deliveryHourStart={deliveryHourStart}
                setDeliveryHourStart={setDeliveryHourStart}
                deliveryHourEnd={deliveryHourEnd}
                setDeliveryHourEnd={setDeliveryHourEnd}
                selectedGroup={selectedGroup}
                setSelectedGroup={setSelectedGroup}
                optionGroup={optionGroup}
                isSubmitting={isSubmitting}
                errorMessageDeliveryZone={errorMessageDeliveryZone}
                zoneErrors={zoneErrors}
                hasValidationErrors={hasValidationErrors}
              />
            )}
          </div>
        </>
      ) : null}
    </div>
  )
}

export default WithErrorBoundary(DeliveryList)
