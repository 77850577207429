import deliveryApi from '_api/delivery'
import reportApi from '_api/report'
import userApi from '_api/user'
import { setUserInfo } from '_utils/localData'
import { DEFAULT_KEY, generateCacheTTL } from 'redux-cache'

const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS'
const GET_ADDRESS_DELIVERY_BOOKER = 'GET_ADDRESS_DELIVERY_BOOKER'
const GET_USER_INFO = 'GET_USER_INFO'
const USER_FIRST_LOGIN = 'USER_FIRST_LOGIN'
const USER_LOGOUT = 'USER_LOGOUT'
const UPDATE_USER_INFO_NEW_REGISTER = 'UPDATE_USER_INFO_NEW_REGISTER'

const initialState = {
  [DEFAULT_KEY]: null,
  notifications: [],
  addressDeliveryBooking: null,
  user: {},
  firstLogin: false,
}

export const userLogout = () => (dispatch) => {
  dispatch({
    type: USER_LOGOUT,
    payload: {
      firstLogin: false,
    },
  })
}

export const userFirstLogin = () => (dispatch) => {
  dispatch({
    type: USER_FIRST_LOGIN,
    payload: {
      firstLogin: true,
    },
  })
}

export const getNotifications = async (dispatch, getState) => {
  // const isCacheValid = checkCacheValid(getState, 'notification')
  // if (isCacheValid) {
  //   return null
  // }
  const { user } = getState()
  const { notifications } = user
  if (notifications && notifications.length > 0) return
  try {
    const { msgResp: listNotification } = await userApi.getNotifications()
    dispatch({
      type: GET_NOTIFICATIONS,
      payload: { notifications: listNotification },
    })
  } catch (error) {}
}

export const getRememberedDeliveryAddress = () => async (dispatch, getState) => {
  const { user } = getState()
  const { addressDeliveryBooking } = user
  if (addressDeliveryBooking) return
  try {
    const { msgResp } = await deliveryApi.getRememberedDeliveryAddress()
    dispatch({
      type: GET_ADDRESS_DELIVERY_BOOKER,
      payload: { addressDeliveryBooking: msgResp },
    })
  } catch (error) {}
}

export const updateRememberedDeliveryAddress = (address) => async (dispatch) => {
  dispatch({
    type: GET_ADDRESS_DELIVERY_BOOKER,
    payload: { addressDeliveryBooking: address },
  })
}

export const fetchUserInfo = () => async (dispatch) => {
  try {
    const { msgResp } = await userApi.getUser()
    dispatch({
      type: GET_USER_INFO,
      payload: { user: msgResp },
    })
    setUserInfo(msgResp)
  } catch (error) {
    reportApi.report({ message: `${error.msgCode} - ${error.msgResp}` })
    throw new Error(error)
  }
}

export const updateUserInfoNewRegister = (user) => async (dispatch) => {
  dispatch({
    type: UPDATE_USER_INFO_NEW_REGISTER,
    payload: { user },
  })
}

const user = (state = initialState, action) => {
  switch (action.type) {
    case GET_NOTIFICATIONS: {
      return {
        ...state,
        [DEFAULT_KEY]: generateCacheTTL(),
        ...action.payload,
      }
    }
    case UPDATE_USER_INFO_NEW_REGISTER:
    case USER_LOGOUT:
    case USER_FIRST_LOGIN:
    case GET_USER_INFO:
    case GET_ADDRESS_DELIVERY_BOOKER:
      return {
        ...state,
        ...action.payload,
      }
    default:
      return state
  }
}

export default user
