import LoadingOverlay from '_components/LoadingOverlay'
import ShopList from '_pages/Shop/ShopList'
import { getMyOrders } from '_redux/modules/order'
import { ITEMS_PER_PAGE } from '_utils/constant'
import React, { createContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { getListEventsOpening } from '../../redux/modules/event'
import ListAllEvent from '../Event/ListAllEvent'

export const AllPageContext = createContext()

function AllPage() {
  const dispatch = useDispatch()

  const [loading, setLoading] = useState(false)
  const [currentPageShop, setCurrentPageShop] = useState(1)
  const [currentPageEvent, setCurrentPageEvent] = useState(1)
  const [searchShop, setSearchShop] = useState('')
  const [searchEvent, setSearchEvent] = useState('')
  const [errorMessage, setErrorMessage] = useState('')

  const { eventsPublic } = useSelector((state) => state.event)
  const { listShop } = useSelector((state) => state.shop)

  useEffect(() => {
    let isMounted = true

    const fetchData = async () => {
      try {
        setLoading(true)
        await dispatch(getListEventsOpening())
      } catch (error) {
        setErrorMessage(error.message)
      } finally {
        if (isMounted) {
          setLoading(false)
        }
      }
    }

    fetchData()
    dispatch(getMyOrders)

    return () => {
      isMounted = false
    }
  }, [])

  useEffect(() => {
    if (!searchEvent) {
      setCurrentPageEvent(1)
    }
    if (!searchShop) {
      setCurrentPageShop(1)
    }
  }, [searchEvent, searchShop])

  const numberOfPagesEvent = Math.ceil(eventsPublic.length / ITEMS_PER_PAGE)
  const numberOfPagesShop = Math.ceil(listShop.length / ITEMS_PER_PAGE)

  const onPageChangeEvent = (page) => setCurrentPageEvent(page)

  const onPageChangeShop = (page) => setCurrentPageShop(page)

  const onSearchShop = (value) => setSearchShop(value)

  const onSearchEvent = (value) => setSearchEvent(value)

  const sharedValues = {
    shop: {
      listShop,
      numberOfPagesShop,
      onPageChangeShop,
      currentPageShop,
      onSearchShop,
      searchShop,
    },
    event: {
      listEvent: eventsPublic,
      numberOfPagesEvent,
      onPageChangeEvent,
      currentPageEvent,
      onSearchEvent,
      searchEvent,
    },
  }

  if (loading) return <LoadingOverlay />

  if (!loading && errorMessage) return <div>{errorMessage}</div>

  if (!loading && !listShop?.length && !eventsPublic?.length) {
    return (
      <h6 className='text-center fw-bold f-24 w-100 mt-5' style={{ fontSize: '1rem' }}>
        No Event List {listShop?.length ? '& Shop List' : null}
      </h6>
    )
  }

  return (
    <div className='all-page container'>
      <AllPageContext.Provider value={sharedValues}>
        {/* Event list */}
        {eventsPublic?.length ? (
          <>
            <ListAllEvent />
          </>
        ) : null}

        {/* Shop list */}
        {listShop?.length ? (
          <>
            <hr style={{ margin: '5rem auto', borderTop: '1px solid black' }} /> <ShopList />
          </>
        ) : null}
      </AllPageContext.Provider>
    </div>
  )
}

export default AllPage
