/* eslint-disable no-unused-vars */

import { CollapseContext } from '_context/CollapseMenuContext'
import { SelectIdGroupContext } from '_context/SelectGroupContext'
import useQuery from '_hooks/useQuery'
import { getGroupDetailById } from '_redux/modules/group'
import { GROUP_IS_ADMIN, GROUP_IS_HOST } from '_utils/constant'
import { setGroupIdSelected } from '_utils/localData'
import { useContext, useEffect, useState } from 'react'
import { AiFillSetting } from 'react-icons/ai'
import {
  BsFillCalendarEventFill,
  BsFillCaretLeftFill,
  BsFillCaretRightFill,
  BsJournalRichtext,
} from 'react-icons/bs'
import { FaCompass, FaDollarSign, FaTruck } from 'react-icons/fa'
import { IoNotifications } from 'react-icons/io5'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { NavLink } from 'react-router-dom'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import { getMyGroups } from '../../../redux/modules/group'
import CustomSelectBox from './CustomSelectBox'
import './style.scss'

const menu = [
  {
    id: 1,
    path: '/dashboard',
    icon: <IoNotifications />,
    title: 'Dashboard',
  },
  {
    id: 2,
    path: '/event/list-hosted-by-group',
    icon: <BsFillCalendarEventFill />,
    title: 'Hosted Event',
  },
  {
    id: 3,
    path: '/manage-orders',
    icon: <FaDollarSign />,
    title: 'Events Orders',
  },
  {
    id: 4,
    path: '/event/delivery-list',
    icon: <FaTruck />,
    title: 'Delivery',
  },
  {
    id: 5,
    path: '/delivery-booking',
    icon: <BsJournalRichtext />,
    title: 'Delivery Orders',
  },
  {
    id: 6,
    path: '/members/member-list',
    icon: <FaCompass />,
    title: 'Members',
  },
  {
    id: 7,
    path: '/settings',
    icon: <AiFillSetting />,
    title: 'Settings',
  },
]

function MenuItem({ item, isCollapse }) {
  const location = useLocation()

  const checkActiveStatus = (path) =>
    (location.pathname === '/event/manage-orders' && path === '/event/list-hosted-by-group') ||
    (location.pathname === '/event/manage-delivery' && path === '/event/delivery-list') ||
    (location.pathname.includes('/members/member-detail') && path.includes('/members/member-list'))

  const isActive = checkActiveStatus(item.path)

  return (
    <li className='d-flex fw-bold align-items-end mb-2 menu-item' key={item.id}>
      <NavLink
        to={item.path}
        className={`text-uppercase ${isActive ? 'text-orange' : null}`}
        activeClassName='text-orange'
        exact
      >
        <span className='me-2 icons'>{item.icon}</span>
        <span>{isCollapse ? null : item.title}</span>
        {isCollapse && <span className='tooltip'>{item.title}</span>}
      </NavLink>
    </li>
  )
}

function LeftMenu() {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(true)
  const history = useHistory()
  const { setInfoGroupSelected, infoGroupSelected } = useContext(SelectIdGroupContext)
  const { isCollapse, toggleCollapse } = useContext(CollapseContext)
  const params = useQuery()
  const { myGroups } = useSelector((state) => state.group)

  const listGroupWithAdmin = myGroups
    ?.filter((item) => item.isHost === GROUP_IS_HOST || item.isAdmin === GROUP_IS_ADMIN)
    ?.sort((a, b) => a?.createdAt - b?.createdAt)

  const isEditingOrder = params.get('id')

  const handleSelectGroup = (value) => {
    const groupSelected = listGroupWithAdmin?.find((group) => group.id === value.id)

    setGroupIdSelected(value.id)
    dispatch(getGroupDetailById(value.id))
    setInfoGroupSelected({
      groupId: groupSelected?.gid || groupSelected?.id,
      groupName: groupSelected?.name,
      totalMembers: groupSelected?.totalMembers,
    })

    const currentUrl = window.location.href
    const manageOrdersUrl = `${
      process.env.REACT_APP_APP_URL
    }/event/manage-orders?eventId=${params.get('eventId')}`
    const manageDeliveryUrl = `${
      process.env.REACT_APP_APP_URL
    }/event/manage-delivery?id=${params.get('id')}`
    const memberDetailUrl = `${
      process.env.REACT_APP_APP_URL
    }/members/member-detail?gid=${params.get('gid')}&uid=${params.get('uid')}`

    if (currentUrl === manageOrdersUrl) {
      history.push('/event/list-hosted-by-group')
    } else if (currentUrl === manageDeliveryUrl) {
      history.push('/event/delivery-list')
    } else if (currentUrl === memberDetailUrl) {
      history.push('/members/member-list')
    }
  }

  useEffect(() => {
    if (myGroups === undefined || myGroups === null) {
      setLoading(true)
      dispatch(getMyGroups)
    }
  }, [])

  useEffect(() => {
    if (myGroups !== undefined && myGroups !== null) {
      setLoading(false)
    }
  }, [myGroups])

  return (
    <div className='d-flex'>
      <div className={`${isCollapse ? 'ms-2' : ''} d-flex flex-column gap-3 px-2 w-100`}>
        <CustomSelectBox
          options={listGroupWithAdmin ?? []}
          selectedValue={infoGroupSelected}
          onChange={(value) => handleSelectGroup(value)}
          isDisabled={isEditingOrder}
          isLoading={loading}
          isCollapse={isCollapse}
        />
        <ul className={`${!isCollapse ? 'px-2' : 'ps-2'} mb-0 list-menu`}>
          {menu.map((item, i) => (
            <MenuItem key={i} item={item} isCollapse={isCollapse} />
          ))}
        </ul>
      </div>
      <div className='style-menu' onClick={toggleCollapse}>
        <div className='group'>
          <span className='text-uppercase'>menu</span>
          <span className='d-inline-block'>
            {isCollapse ? <BsFillCaretRightFill /> : <BsFillCaretLeftFill />}
          </span>
        </div>
      </div>
    </div>
  )
}

export default LeftMenu
