/* eslint-disable no-confusing-arrow */
/* eslint-disable function-paren-newline */
import deliveryApi from '_api/delivery'
import eventApi from '_api/event'
import serviceApi from '_api/service'
import { clearDataPurchaseDetail, clearMyOrder } from '_redux/modules/order'
import { DOMAIN, STATUS_PAID } from '_utils/constant'
import { getDomain, setDomain } from '_utils/localData'
import { debounce } from 'lodash'

const GET_DELIVERY_DETAIL = 'GET_DELIVERY_DETAIL'
const GET_EVENT_AND_DELIVERY = 'GET_EVENT_AND_DELIVERY'
const UPDATE_DELIVERY_DETAIL = 'UPDATE_DELIVERY_DETAIL'
const UPDATE_EVENT_AND_DELIVERY = 'UPDATE_EVENT_AND_DELIVERY'
const CLEAR_EVENT_AND_DELIVERY = 'CLEAR_EVENT_AND_DELIVERY'
const CLEAR_DELIVERY_DETAIL = 'CLEAR_DELIVERY_DETAIL'
const GET_DELIVERY_BY_BUYER = 'GET_DELIVERY_BY_BUYER'
const GET_DELIVERY_BY_HOST = 'GET_DELIVERY_BY_HOST'
const GET_DELIVERY_BY_HOST_AND_DATE = 'GET_DELIVERY_BY_HOST_AND_DATE'
const UPDATE_LIST_DELIVERY = 'UPDATE_LIST_DELIVERY'
const LIST_DELIVERY_CREATED_BY_HOST = 'LIST_DELIVERY_CREATED_BY_HOST'
const GET_DELIVERY_AND_DELIVERY_BOOKING = 'GET_DELIVERY_AND_DELIVERY_BOOKING'
const GET_SCHEDULED_DELIVERY = 'GET_SCHEDULED_DELIVERY'
const UPDATE_DELIVERY_PAYMENT_STATUS = 'UPDATE_DELIVERY_PAYMENT_STATUS'
const CLEAR_CURRENT_DELIVERY_AND_DELIVERY_BOOKING = 'CLEAR_CURRENT_DELIVERY_AND_DELIVERY_BOOKING'
const CLEAR_LIST_DELIVERY_AND_DELIVERY_BOOKING = 'CLEAR_LIST_DELIVERY_AND_DELIVERY_BOOKING'
const LOADING_DELIVERY_START = 'LOADING_DELIVERY_START'
const LOADING_DELIVERY_END = 'LOADING_DELIVERY_END'
const CLEAR_DELIVERY_CACHE = 'CLEAR_DELIVERY_CACHE'
const LOADING_DELIVERY_BOOKING_START = 'LOADING_DELIVERY_BOOKING_START'
const LOADING_DELIVERY_BOOKING_END = 'LOADING_DELIVERY_BOOKING_END'
const ADD_SCHEDULED_DELIVERY = 'ADD_SCHEDULED_DELIVERY'

const initialState = {
  listDeliveryDetail: [],
  currentDeliveryDetail: {},
  errorGetDeliveryDetail: null,
  listEventAndDelivery: [],
  listDeliveryByHost: [],
  listDeliveryByBuyer: [],
  listDeliveryByHostAndDate: [],
  callNewAPIListDelivery: false,
  currentEventAndDelivery: {},
  errorGetEventAndDelivery: null,
  listDeliveryCreatedByHost: [],
  callNewAPIDeliveryCreatedByHost: false,
  listDeliveryAndDeliveryBooking: [],
  currentDeliveryAndDeliveryBooking: {},
  errorDeliveryAndDeliveryBooking: null,
  allScheduleDelivery: [],
  loadingDelivery: true,
  deliveryCache: null,
}

export const clearDeliveryDetail = () => async (dispatch) => {
  dispatch({
    type: CLEAR_DELIVERY_DETAIL,
    payload: { listDeliveryDetail: [] },
  })
}

export const clearEventAndDelivery = () => async (dispatch) => {
  dispatch({
    type: CLEAR_EVENT_AND_DELIVERY,
    payload: { listEventAndDelivery: [] },
  })
}

export const callNewDeliveryCreatedByHost = () => async (dispatch) => {
  dispatch({
    type: LIST_DELIVERY_CREATED_BY_HOST,
    payload: { callNewAPIDeliveryCreatedByHost: true },
  })
}

export const clearDeliveryCreatedByHost = () => async (dispatch) => {
  dispatch({
    type: LIST_DELIVERY_CREATED_BY_HOST,
    payload: { listDeliveryCreatedByHost: [] },
  })
}

export const addToListDeliveryCreatedByHost = (hostId, deliveryCreated) => (dispatch, getState) => {
  const { delivery } = getState()
  const { listDeliveryCreatedByHost = [] } = delivery

  const index = listDeliveryCreatedByHost.findIndex((item) => item.hostId === hostId)

  if (index !== -1) {
    const updatedList = [...listDeliveryCreatedByHost]
    updatedList[index] = {
      ...updatedList[index],
      listDeliveryCreated: [deliveryCreated, ...updatedList[index].listDeliveryCreated],
    }

    dispatch({
      type: LIST_DELIVERY_CREATED_BY_HOST,
      payload: {
        listDeliveryCreatedByHost: updatedList,
        deliveryCache: updatedList,
      },
    })
  } else {
    const newDeliveryData = {
      hostId,
      listDeliveryCreated: [deliveryCreated],
    }

    const updatedList = [...listDeliveryCreatedByHost, newDeliveryData]

    dispatch({
      type: LIST_DELIVERY_CREATED_BY_HOST,
      payload: {
        listDeliveryCreatedByHost: updatedList,
        deliveryCache: updatedList,
      },
    })
  }
}

const pendingPromises = new Map()
const shopIdSet = new Set()

const debouncedGetListDeliveryCreatedByHost = debounce(
  async (dispatch, getState, hostId, resolve, reject) => {
    try {
      const { delivery } = getState()
      let { listDeliveryCreatedByHost = [] } = delivery
      const { deliveryCache } = delivery

      const shopIds = Array.from(shopIdSet)

      if (deliveryCache) {
        dispatch({
          type: LIST_DELIVERY_CREATED_BY_HOST,
          payload: {
            listDeliveryCreatedByHost: deliveryCache,
            callNewAPIDeliveryCreatedByHost: false,
          },
        })
        dispatch({ type: LOADING_DELIVERY_END })
        resolve()
        return
      }

      let domainLocal = getDomain(DOMAIN)
      if (!domainLocal) {
        const parser = document.createElement('a')
        parser.href = process.env.REACT_APP_APP_URL

        if (DOMAIN !== parser.hostname) {
          const { msgResp } = await serviceApi.getGroupIdByDomainName(DOMAIN, STATUS_PAID)
          domainLocal = msgResp.groupId
          setDomain(DOMAIN, domainLocal)
        }
      }

      // Batch API calls for both group and shops
      const promises = [
        deliveryApi.listDeliveryCreatedByHost(hostId, domainLocal, false),
        ...(shopIds.length > 0
          ? shopIds.map((shopId) =>
              deliveryApi.listDeliveryCreatedByHost(hostId, domainLocal, true, shopId)
            )
          : []),
      ].filter(Boolean)

      dispatch({ type: LOADING_DELIVERY_START })

      const responses = await Promise.all(promises)

      // Combine all responses
      const listDeliveryCreated = responses.reduce(
        (acc, response) => [...acc, ...response.msgResp],
        []
      )

      // Update state
      const index = listDeliveryCreatedByHost.findIndex((item) => item.hostId === hostId)
      if (index !== -1) {
        listDeliveryCreatedByHost[index] = { hostId, listDeliveryCreated }
      } else {
        listDeliveryCreatedByHost = [...listDeliveryCreatedByHost, { hostId, listDeliveryCreated }]
      }

      dispatch({
        type: LIST_DELIVERY_CREATED_BY_HOST,
        payload: {
          listDeliveryCreatedByHost,
          deliveryCache: listDeliveryCreatedByHost,
          callNewAPIDeliveryCreatedByHost: false,
        },
      })

      // Cleanup
      pendingPromises.clear()
      shopIdSet.clear()
      dispatch({ type: LOADING_DELIVERY_END })
      resolve()
    } catch (error) {
      console.error('Error in getListDeliveryCreatedByHost:', error)
      dispatch({ type: LOADING_DELIVERY_END })
      reject(error)
    }
  },
  100
)

export const clearDeliveryCache = () => ({
  type: CLEAR_DELIVERY_CACHE,
})

export const getListDeliveryCreatedByHost =
  (hostId, shopId = '') =>
  async (dispatch, getState) => {
    if (shopId) {
      shopIdSet.add(shopId)
    }

    const promise = new Promise((resolve, reject) => {
      dispatch({ type: LOADING_DELIVERY_START })
      debouncedGetListDeliveryCreatedByHost(dispatch, getState, hostId, resolve, reject)
    })

    pendingPromises.set(hostId, promise)
    return promise
  }

export const getMulListDeliveryCreatedByHost = (hostShopMap) => async (dispatch) => {
  if (!hostShopMap || hostShopMap.length === 0) {
    console.error('hostShopMap is empty or undefined')
    return
  }

  try {
    const domainLocal = getDomain(DOMAIN)

    const getDeliveryCreatedByHost = async (hostId, _shopIds) => {
      const responseGroup = await deliveryApi.listDeliveryCreatedByHost(
        hostId,
        domainLocal,
        false,
        ''
      )
      const listGroupDeliveryCreated = responseGroup.msgResp || []

      const responseShops = await Promise.all(
        _shopIds.map((shopId) =>
          deliveryApi.listDeliveryCreatedByHost(hostId, domainLocal, true, shopId)
        )
      )

      const listShopDeliveryCreated = responseShops.flatMap((response) => response.msgResp || [])

      const modifiedListDeliveries = [
        ...listGroupDeliveryCreated.map((delivery) => ({
          ...delivery,
          deliveryZones: (delivery.deliveryZones || []).map((zone) => ({
            ...zone,
            type: 'group',
          })),
        })),
        ...listShopDeliveryCreated.map((delivery) => ({
          ...delivery,
          deliveryZones: (delivery.deliveryZones || []).map((zone) => ({
            ...zone,
            type: 'shop',
          })),
        })),
      ]

      return {
        hostId,
        listDeliveryCreated: modifiedListDeliveries,
      }
    }

    const data = await Promise.all(
      hostShopMap.map(({ hostId, listShop }) => getDeliveryCreatedByHost(hostId, listShop))
    )

    const combinedListDeliveryCreatedByHost = data.reduce(
      (acc, { hostId, listDeliveryCreated }) => {
        if (!acc[hostId]) {
          acc[hostId] = []
        }
        acc[hostId] = [...acc[hostId], ...listDeliveryCreated]
        return acc
      },
      {}
    )

    const finalListDeliveryCreatedByHost = Object.values(combinedListDeliveryCreatedByHost).flat()

    const reducedListDeliveryCreatedByUser = Object.values(
      finalListDeliveryCreatedByHost.reduce((acc, delivery) => {
        const { createdUserId } = delivery
        if (!acc[createdUserId]) {
          acc[createdUserId] = {
            hostId: createdUserId,
            listDeliveryCreated: [],
          }
        }
        acc[createdUserId].listDeliveryCreated.push(delivery)
        return acc
      }, {})
    )

    dispatch({
      type: LIST_DELIVERY_CREATED_BY_HOST,
      payload: {
        listDeliveryCreatedByHost: reducedListDeliveryCreatedByUser,
      },
    })
  } catch (e) {
    console.error('Error in getMulListDeliveryCreatedByHost:', e)
  }
}
export const getDeliveryByBuyer = (buyerId) => async (dispatch, getState) => {
  try {
    const { delivery } = getState()
    const { listDeliveryByBuyer = [] } = delivery

    if (listDeliveryByBuyer.length) {
      return
    }
    let newListDeliveryByBuyer = []

    let domainLocal = getDomain(DOMAIN)
    const parser = document.createElement('a')
    parser.href = process.env.REACT_APP_APP_URL

    if (!domainLocal && DOMAIN !== parser.hostname) {
      const { msgResp } = await serviceApi.getGroupIdByDomainName(DOMAIN, STATUS_PAID)
      const { groupId } = msgResp

      domainLocal = groupId
      setDomain(DOMAIN, groupId)
    }

    const [{ msgResp: listDelivery }, { msgResp: listShopEventDelivery }] = await Promise.all([
      deliveryApi.listDeliveryByBuyer(buyerId, domainLocal, false),
      deliveryApi.listDeliveryByBuyer(buyerId, domainLocal, true),
    ])

    const combinedListDelivery = [...listDelivery, ...listShopEventDelivery]

    if (combinedListDelivery.length) {
      newListDeliveryByBuyer = combinedListDelivery.sort(
        (a, b) => a.dDeliveryTimestamp - b.dDeliveryTimestamp
      )
    }

    dispatch({
      type: GET_DELIVERY_BY_BUYER,
      payload: {
        listDeliveryByBuyer: newListDeliveryByBuyer,
      },
    })
  } catch (error) {}
}

export const clearDeliveryByBuyer = () => async (dispatch) => {
  dispatch({
    type: GET_DELIVERY_BY_BUYER,
    payload: {
      listDeliveryByBuyer: [],
    },
  })
}

export const getDeliveryDetail =
  (ref, shopId = null) =>
  async (dispatch, getState) => {
    const deliveryShop = !!shopId
    try {
      const { delivery } = getState()
      let { listDeliveryDetail } = delivery

      const index = listDeliveryDetail.findIndex((item) => item.ref === ref)

      if (index !== -1) {
        dispatch({
          type: GET_DELIVERY_DETAIL,
          payload: {
            errorGetDeliveryDetail: null,
            currentDeliveryDetail: listDeliveryDetail[index],
          },
        })
        return
      }

      let newDeliveryDetail = null

      const { msgResp: deliveryDetail } = await deliveryApi.getDeliveryBooking(ref, deliveryShop)

      if (deliveryDetail) {
        newDeliveryDetail = deliveryDetail
        const { dCreatedUserId } = deliveryDetail
        await dispatch(getListDeliveryCreatedByHost(dCreatedUserId, shopId))
        listDeliveryDetail = [...listDeliveryDetail, deliveryDetail]
      }

      dispatch({
        type: GET_DELIVERY_DETAIL,
        payload: {
          listDeliveryDetail,
          currentDeliveryDetail: newDeliveryDetail,
          errorGetDeliveryDetail: null,
        },
      })
    } catch (error) {
      dispatch({
        type: GET_DELIVERY_DETAIL,
        payload: {
          errorGetDeliveryDetail: error,
        },
      })
    }
  }

export const getDeliveryByHost = (hostId) => async (dispatch, getState) => {
  try {
    const { delivery } = getState()
    const { listDeliveryByHost = [] } = delivery

    if (listDeliveryByHost.length) {
      return
    }
    let newListDeliveryByHost = []

    const { msgResp: listDelivery } = await deliveryApi.listDeliveryByHost(hostId)
    if (listDelivery && listDelivery.length) {
      newListDeliveryByHost = listDelivery
    }
    dispatch({
      type: GET_DELIVERY_BY_HOST,
      payload: {
        listDeliveryByHost: newListDeliveryByHost,
      },
    })
  } catch (error) {}
}

export const updateListDelivery =
  (ref, hostId, deliveryTime, status, bookerStatus) => async (dispatch, getState) => {
    const { delivery } = getState()
    const { listDeliveryByHostAndDate = [], listDeliveryByHost = [] } = delivery
    const indexHD = listDeliveryByHostAndDate.findIndex(
      (item) => item.hostId === hostId && item.deliveryTime === deliveryTime
    )
    if (indexHD !== -1) {
      const { listDelivery } = listDeliveryByHostAndDate[indexHD]
      for (let indexD = 0; indexD < listDelivery.length; indexD++) {
        if (listDelivery[indexD].ref === ref) {
          listDelivery[indexD].status = status
          listDelivery[indexD].bookerStatus = bookerStatus
        }
      }
    }
    if (listDeliveryByHost.length) {
      for (let index = 0; index < listDeliveryByHost.length; index++) {
        if (listDeliveryByHost[index].ref === ref) {
          listDeliveryByHost[index].status = status
          listDeliveryByHost[index].bookerStatus = bookerStatus
        }
      }
    }
    dispatch(clearDeliveryDetail())
    dispatch(clearMyOrder())
    dispatch(clearDataPurchaseDetail())
    dispatch({
      type: UPDATE_LIST_DELIVERY,
      payload: {
        listDeliveryByHost,
        listDeliveryByHostAndDate,
        callNewAPIListDelivery: true,
      },
    })
  }

// update bookerStatus
export const updateDeliveryBookerStatus = (ref, bookerStatus) => async (dispatch, getState) => {
  try {
    const { delivery } = getState()
    const { listDeliveryDetail } = delivery

    const indexDelivery = listDeliveryDetail.findIndex((item) => item.ref === ref)

    if (indexDelivery !== -1) {
      listDeliveryDetail[indexDelivery].bookerStatus = bookerStatus
    }

    dispatch({
      type: UPDATE_DELIVERY_DETAIL,
      payload: { listDeliveryDetail },
    })
  } catch (error) {}
}

export const updateDeliveryDetail = (ref, data) => async (dispatch, getState) => {
  try {
    const { delivery } = getState()
    const { listDeliveryDetail } = delivery
    const indexDelivery = listDeliveryDetail.findIndex((item) => item.ref === ref)

    if (indexDelivery !== -1) {
      listDeliveryDetail[indexDelivery] = {
        ...listDeliveryDetail[indexDelivery],
        ...data,
      }
    }

    dispatch({
      type: UPDATE_DELIVERY_DETAIL,
      payload: { listDeliveryDetail },
    })
  } catch (error) {}
}

export const getEventAndDelivery = (eid) => async (dispatch, getState) => {
  try {
    const { delivery, event } = getState()
    let { listEventAndDelivery = [] } = delivery
    const { callNewAPIListDelivery } = delivery
    const { eventAndOrderById = [] } = event

    const indexED = listEventAndDelivery.findIndex((item) => item.id === eid)
    const indexEO = eventAndOrderById.findIndex((item) => item.id === eid)

    if (indexED !== -1) {
      dispatch({
        type: GET_EVENT_AND_DELIVERY,
        payload: {
          errorGetEventAndDelivery: null,
          currentEventAndDelivery: listEventAndDelivery[indexED],
        },
      })
      if (!callNewAPIListDelivery) {
        return
      }
    }
    let newEventAndDelivery = {}
    if (indexEO !== -1) {
      const { pickupTimestamp, pickupTime, totalOrderCount, title, deliveryStatus } =
        eventAndOrderById[indexEO]

      newEventAndDelivery = {
        ...newEventAndDelivery,
        pickupTimestamp,
        pickupTime,
        totalOrderCount,
        id: eid,
        title,
        deliveryStatus,
      }
    } else if (indexED !== -1) {
      const { pickupTimestamp, pickupTime, totalOrderCount, title, deliveryStatus } =
        listEventAndDelivery[indexED]

      newEventAndDelivery = {
        ...newEventAndDelivery,
        pickupTimestamp,
        pickupTime,
        totalOrderCount,
        id: eid,
        title,
        deliveryStatus,
      }
    } else {
      const { msgResp: eventDetail } = await eventApi.getEventDetail({
        eid,
      })
      if (eventDetail) {
        const { pickupTimestamp, pickupTime, totalOrderCount, title, deliveryStatus } = eventDetail

        newEventAndDelivery = {
          ...newEventAndDelivery,
          pickupTimestamp,
          pickupTime,
          totalOrderCount,
          id: eid,
          title,
          deliveryStatus,
        }
      }
    }
    const { msgResp: listDelivery } = await deliveryApi.listDeliveryByEvent(eid)
    if (listDelivery) {
      newEventAndDelivery = {
        ...newEventAndDelivery,
        listDelivery,
      }
      if (indexED !== -1) {
        listEventAndDelivery[indexED] = newEventAndDelivery
      } else {
        listEventAndDelivery = [...listEventAndDelivery, newEventAndDelivery]
      }
    }
    dispatch({
      type: GET_EVENT_AND_DELIVERY,
      payload: {
        listEventAndDelivery,
        currentEventAndDelivery: newEventAndDelivery,
        errorGetEventAndDelivery: null,
        callNewAPIListDelivery: false,
      },
    })
  } catch (error) {
    dispatch({
      type: GET_EVENT_AND_DELIVERY,
      payload: {
        errorGetEventAndDelivery: error,
        callNewAPIListDelivery: false,
      },
    })
  }
}

const pendingDeliveryPromises = new Map()
const deliveryIdSet = new Set()
const debouncedGetListDeliveryAndDeliveryBooking = debounce(
  async (
    dispatch,
    getState,
    deliveryIds,
    resolve,
    reject,
    deliveryShop = false,
    isDesktop = false
  ) => {
    try {
      const { delivery } = getState()
      const { listDeliveryAndDeliveryBooking = [] } = delivery
      const updatedListDeliveryAndDeliveryBooking = [...listDeliveryAndDeliveryBooking]

      const arrayDeliveryIds = Array.from(deliveryIds)

      const promises = arrayDeliveryIds.map(async (deliveryId) => {
        const { msgResp: deliveryAndDeliveryBooking } = await deliveryApi.getDelivery(
          deliveryId,
          true,
          deliveryShop
        )
        return deliveryAndDeliveryBooking
      })

      const results = await Promise.all(promises)

      results.forEach((newDeliveryAndDeliveryBooking) => {
        if (Object.keys(newDeliveryAndDeliveryBooking).length) {
          const index = updatedListDeliveryAndDeliveryBooking.findIndex(
            (item) => item.id === newDeliveryAndDeliveryBooking.id
          )
          if (index !== -1) {
            updatedListDeliveryAndDeliveryBooking[index] = newDeliveryAndDeliveryBooking
          } else {
            updatedListDeliveryAndDeliveryBooking.push(newDeliveryAndDeliveryBooking)
          }
        }
      })

      const lastElement = results[results.length - 1]

      const allDeliveryBookings = results.reduce((acc, curr) => {
        if (curr.deliveryBookings && Array.isArray(curr.deliveryBookings)) {
          return [...acc, ...curr.deliveryBookings]
        }
        return acc
      }, [])

      lastElement.deliveryBookings = allDeliveryBookings

      dispatch({
        type: GET_DELIVERY_AND_DELIVERY_BOOKING,
        payload: {
          listDeliveryAndDeliveryBooking: updatedListDeliveryAndDeliveryBooking,
          currentDeliveryAndDeliveryBooking: isDesktop ? lastElement : results[0],
          errorDeliveryAndDeliveryBooking: null,
          callNewAPIListDelivery: false,
        },
      })

      pendingDeliveryPromises.clear()
      deliveryIdSet.clear()

      dispatch({ type: LOADING_DELIVERY_BOOKING_END })
      resolve()
    } catch (error) {
      dispatch({
        type: GET_DELIVERY_AND_DELIVERY_BOOKING,
        payload: {
          errorDeliveryAndDeliveryBooking: error,
          callNewAPIListDelivery: false,
        },
      })

      dispatch({ type: LOADING_DELIVERY_BOOKING_END })
      dispatch({ type: LOADING_DELIVERY_END })
      reject(error)
    } finally {
      deliveryIdSet.clear()
      dispatch({ type: LOADING_DELIVERY_BOOKING_END })
      dispatch({ type: LOADING_DELIVERY_END })
      resolve()
    }
  },
  100
)

export const getListDeliveryAndDeliveryBooking =
  (deliveryId, deliveryShop = false, isDesktop = false) =>
  async (dispatch, getState) => {
    deliveryIdSet.add(deliveryId)

    const promise = new Promise((resolve, reject) => {
      dispatch({ type: LOADING_DELIVERY_BOOKING_START })

      debouncedGetListDeliveryAndDeliveryBooking(
        dispatch,
        getState,
        deliveryIdSet,
        resolve,
        reject,
        deliveryShop,
        isDesktop
      )
    })

    pendingDeliveryPromises.set(deliveryId, promise)
    return promise
  }

export const clearCurrentDeliveryAndDeliveryBooking = () => async (dispatch) => {
  dispatch({
    type: CLEAR_CURRENT_DELIVERY_AND_DELIVERY_BOOKING,
    payload: { currentDeliveryAndDeliveryBooking: {} },
  })
}

export const clearListDeliveryAndDeliveryBooking = () => async (dispatch) => {
  dispatch({
    type: CLEAR_LIST_DELIVERY_AND_DELIVERY_BOOKING,
    payload: { listDeliveryAndDeliveryBooking: [] },
  })
}

export const updateDeliveryInfo = (deliveryId, data) => async (dispatch, getState) => {
  try {
    const { delivery } = getState()
    const { listDeliveryAndDeliveryBooking, currentDeliveryAndDeliveryBooking } = delivery

    const updatedCurrentDeliveryAndDeliveryBooking = {
      ...currentDeliveryAndDeliveryBooking,
      ...data,
    }

    const indexDelivery = listDeliveryAndDeliveryBooking.findIndex((item) => item.id === deliveryId)

    if (indexDelivery !== -1) {
      listDeliveryAndDeliveryBooking[indexDelivery] = {
        ...listDeliveryAndDeliveryBooking[indexDelivery],
        ...data,
      }
    }

    const updatedListDeliveryAndDeliveryBooking = listDeliveryAndDeliveryBooking.map((item) =>
      item.id === deliveryId ? { ...item, ...data } : item
    )

    dispatch(clearDeliveryByBuyer())
    dispatch(clearDeliveryCreatedByHost())

    dispatch({
      type: GET_DELIVERY_AND_DELIVERY_BOOKING,
      payload: {
        listDeliveryAndDeliveryBooking: updatedListDeliveryAndDeliveryBooking,
        currentDeliveryAndDeliveryBooking: updatedCurrentDeliveryAndDeliveryBooking,
      },
    })
  } catch (error) {
    console.error('Error updating delivery info:', error)
  }
}

export const deleteDelivery = (deliveryId, hostId) => async (dispatch, getState) => {
  try {
    const { delivery } = getState()
    const { listDeliveryAndDeliveryBooking = [], listDeliveryCreatedByHost = [] } = delivery

    const indexDD = listDeliveryAndDeliveryBooking.findIndex((item) => item.id === deliveryId)
    const indexDB = listDeliveryCreatedByHost.findIndex((item) => item.hostId === hostId)

    const newListDeliveryAndDeliveryBooking = listDeliveryAndDeliveryBooking
    const newListDeliveryCreatedByHost = listDeliveryCreatedByHost

    if (indexDD !== -1) {
      newListDeliveryAndDeliveryBooking.splice(indexDD, 1)
    }

    if (indexDB !== -1) {
      const { listDeliveryCreated: newListDeliveryCreated } = newListDeliveryCreatedByHost[indexDB]
      const indexD = newListDeliveryCreated.findIndex((item) => item.id === deliveryId)
      newListDeliveryCreated.splice(indexD, 1)
      newListDeliveryCreatedByHost[indexDB].listDeliveryCreated = newListDeliveryCreated
    }

    dispatch(clearDeliveryByBuyer())

    dispatch({
      type: GET_DELIVERY_AND_DELIVERY_BOOKING,
      payload: {
        listDeliveryAndDeliveryBooking: [...newListDeliveryAndDeliveryBooking],
        listDeliveryCreatedByHost: [...newListDeliveryCreatedByHost],
      },
    })
  } catch (error) {}
}

// update status and bookerStatus of delivery booking manage page
export const updateDeliveryPaymentStatus =
  (deliveryId, refDeliveryBooking, status, bookerStatus) => async (dispatch, getState) => {
    const { delivery } = getState()
    const { listDeliveryAndDeliveryBooking = [], currentDeliveryAndDeliveryBooking } = delivery

    const index = listDeliveryAndDeliveryBooking.findIndex((item) => item.id === deliveryId)

    if (index !== -1) {
      const { deliveryBookings } = listDeliveryAndDeliveryBooking[index]

      deliveryBookings.forEach((booking) => {
        if (booking.ref === refDeliveryBooking) {
          booking.status = status
          booking.bookerStatus = bookerStatus
        }
      })

      if (currentDeliveryAndDeliveryBooking?.deliveryBookings) {
        currentDeliveryAndDeliveryBooking.deliveryBookings.forEach((booking) => {
          if (booking.ref === refDeliveryBooking) {
            booking.status = status
            booking.bookerStatus = bookerStatus
          }
        })
      }

      if (currentDeliveryAndDeliveryBooking?.shopEventDeliveryBookings) {
        currentDeliveryAndDeliveryBooking.shopEventDeliveryBookings.forEach((booking) => {
          if (booking.ref === refDeliveryBooking) {
            booking.status = status
            booking.bookerStatus = bookerStatus
          }
        })
      }
    }

    dispatch(clearDeliveryDetail())
    dispatch(clearMyOrder())
    dispatch(clearDataPurchaseDetail())
    dispatch(clearDeliveryByBuyer())

    dispatch({
      type: UPDATE_DELIVERY_PAYMENT_STATUS,
      payload: {
        listDeliveryAndDeliveryBooking,
        callNewAPIDeliveryCreatedByHost: true,
      },
    })
  }

export const updateDeliveryComment = (deliveryId, ref, comment) => async (dispatch, getState) => {
  const { delivery } = getState()
  const { listDeliveryAndDeliveryBooking = [], currentDeliveryAndDeliveryBooking } = delivery

  // Cập nhật listDeliveryAndDeliveryBooking
  const updatedList = listDeliveryAndDeliveryBooking.map((item) => {
    if (item.id === deliveryId) {
      return {
        ...item,
        deliveryBookings: item.deliveryBookings.map((booking) =>
          booking.ref === ref ? { ...booking, comment } : booking
        ),
      }
    }
    return item
  })

  // Cập nhật currentDeliveryAndDeliveryBooking
  let updatedCurrent = { ...currentDeliveryAndDeliveryBooking }
  if (updatedCurrent.id === deliveryId) {
    updatedCurrent = {
      ...updatedCurrent,
      shopEventDeliveryBookings: (updatedCurrent?.shopEventDeliveryBookings || []).map((booking) =>
        booking.ref === ref ? { ...booking, comment } : booking
      ),
      deliveryBookings: (updatedCurrent?.deliveryBookings || []).map((booking) =>
        booking.ref === ref ? { ...booking, comment } : booking
      ),
    }
  }

  dispatch({
    type: GET_DELIVERY_AND_DELIVERY_BOOKING,
    payload: {
      listDeliveryAndDeliveryBooking: updatedList,
      currentDeliveryAndDeliveryBooking: updatedCurrent,
    },
  })
}
// listDeliveryAndDeliveryBooking
export const updateDeliveryCombinedPayment =
  (combinedPaymentRef, status, bookerStatus) => async (dispatch, getState) => {
    try {
      const { delivery, event } = getState()
      const { listDeliveryAndDeliveryBooking = [], currentDeliveryAndDeliveryBooking } = delivery
      const { listCombinedPayments = [] } = event
      const combinedPayment = listCombinedPayments.find(
        (item) => item.combinedPaymentRef === combinedPaymentRef
      )

      if (!combinedPayment) return

      const updatedCurrentDeliveryAndDeliveryBooking = {
        ...currentDeliveryAndDeliveryBooking,
        deliveryBookings: (currentDeliveryAndDeliveryBooking?.deliveryBookings || []).map(
          (booking) => {
            const shouldUpdate = combinedPayment.deliveryCombinedPayment.some(
              (item) => item.ref === booking.ref
            )

            return shouldUpdate ? { ...booking, status, bookerStatus } : booking
          }
        ),
        shopEventDeliveryBookings: (
          currentDeliveryAndDeliveryBooking?.shopEventDeliveryBookings || []
        ).map((booking) => {
          const shouldUpdate = combinedPayment.deliveryCombinedPayment.some(
            (item) => item.ref === booking.ref
          )
          return shouldUpdate ? { ...booking, status, bookerStatus } : booking
        }),
      }

      const updatedDeliveries = listDeliveryAndDeliveryBooking.map((_delivery) => {
        const updatedBookings = _delivery.deliveryBookings.map((booking) => {
          const shouldUpdate = combinedPayment.deliveryCombinedPayment.some(
            (item) => item.did === _delivery.id && item.ref === booking.ref
          )

          return shouldUpdate ? { ...booking, status, bookerStatus } : booking
        })

        return updatedBookings.some(
          (booking, index) => booking !== _delivery.deliveryBookings[index]
        )
          ? { ..._delivery, deliveryBookings: updatedBookings }
          : _delivery
      })

      dispatch(clearMyOrder())
      dispatch(clearDeliveryDetail())
      dispatch(clearDeliveryByBuyer())
      dispatch({
        type: UPDATE_EVENT_AND_DELIVERY,
        payload: {
          listDeliveryAndDeliveryBooking: updatedDeliveries,
          currentDeliveryAndDeliveryBooking: updatedCurrentDeliveryAndDeliveryBooking,
          callNewAPIListDelivery: true,
        },
      })
    } catch (e) {
      console.error('Error updating delivery combined payment:', e)
    }
  }

export const getScheduledDelivery = (hostId) => async (dispatch, getState) => {
  try {
    const { delivery } = getState()
    const { allScheduleDelivery = [] } = delivery

    const index = allScheduleDelivery.findIndex((item) => item.hostId === hostId)

    if (index !== -1) {
      return
    }

    let domainLocal = getDomain(DOMAIN)
    const parser = document.createElement('a')
    parser.href = process.env.REACT_APP_APP_URL

    if (!domainLocal && DOMAIN !== parser.hostname) {
      const { msgResp } = await serviceApi.getGroupIdByDomainName(DOMAIN, STATUS_PAID)
      const { groupId } = msgResp

      domainLocal = groupId
      setDomain(DOMAIN, groupId)
    }

    let newScheduleDelivery = {}
    const { msgResp: listScheduleDelivery } = await deliveryApi.listScheduledDeliveries(
      hostId,
      domainLocal
    )
    newScheduleDelivery = {
      hostId,
      listScheduleDelivery,
    }
    dispatch({
      type: GET_SCHEDULED_DELIVERY,
      payload: { allScheduleDelivery: [...allScheduleDelivery, newScheduleDelivery] },
    })
  } catch (error) {}
}

export const clearScheduledDelivery = (dispatch) => {
  dispatch({
    type: GET_SCHEDULED_DELIVERY,
    payload: { allScheduleDelivery: [] },
  })
}

export const deleteScheduledDelivery = (hostId, id) => (dispatch, getState) => {
  try {
    const { delivery } = getState()
    const { allScheduleDelivery } = delivery

    const newAllScheduleDelivery = allScheduleDelivery

    const indexHost = newAllScheduleDelivery.findIndex((item) => item.hostId === hostId)

    if (indexHost !== -1) {
      const { listScheduleDelivery = [] } = newAllScheduleDelivery[indexHost]
      const indexDel = listScheduleDelivery.findIndex((item) => item.id === id)
      if (indexDel !== -1) {
        listScheduleDelivery.splice(indexDel, 1)
      }
      newAllScheduleDelivery[indexHost].listScheduleDelivery = listScheduleDelivery
    }

    dispatch({
      type: GET_SCHEDULED_DELIVERY,
      payload: {
        allScheduleDelivery: [...newAllScheduleDelivery],
      },
    })
  } catch (error) {}
}

export const updateScheduledDelivery = (hostId, id, data) => (dispatch, getState) => {
  try {
    const { delivery } = getState()
    const { allScheduleDelivery } = delivery

    const newAllScheduleDelivery = allScheduleDelivery

    const indexHost = newAllScheduleDelivery.findIndex((item) => item.hostId === hostId)

    if (indexHost !== -1) {
      const { listScheduleDelivery = [] } = newAllScheduleDelivery[indexHost]
      const indexDel = listScheduleDelivery.findIndex((item) => item.id === id)
      if (indexDel !== -1) {
        listScheduleDelivery[indexDel] = {
          ...listScheduleDelivery[indexDel],
          ...data,
        }
      }
      newAllScheduleDelivery[indexHost].listScheduleDelivery = listScheduleDelivery
    }

    dispatch({
      type: GET_SCHEDULED_DELIVERY,
      payload: {
        allScheduleDelivery: [...newAllScheduleDelivery],
      },
    })
  } catch (error) {}
}

export const addScheduledDelivery = (hostId, data) => (dispatch, getState) => {
  try {
    const { delivery } = getState()
    const { allScheduleDelivery } = delivery

    const newAllScheduleDelivery = allScheduleDelivery

    const indexHost = newAllScheduleDelivery.findIndex((item) => item.hostId === hostId)

    if (indexHost !== -1) {
      const { listScheduleDelivery = [] } = newAllScheduleDelivery[indexHost]
      newAllScheduleDelivery[indexHost].listScheduleDelivery = [...listScheduleDelivery, data]
    }

    dispatch({
      type: ADD_SCHEDULED_DELIVERY,
      payload: {
        allScheduleDelivery: [...newAllScheduleDelivery],
      },
    })
  } catch (error) {}
}

const delivery = (state = initialState, action) => {
  switch (action.type) {
    case ADD_SCHEDULED_DELIVERY:
      return {
        ...state,
        ...action.payload,
      }
    case LOADING_DELIVERY_START:
      return {
        ...state,
        loadingDelivery: true,
      }
    case LOADING_DELIVERY_END:
      return {
        ...state,
        loadingDelivery: false,
      }
    case LOADING_DELIVERY_BOOKING_START:
      return {
        ...state,
        loadingDeliveryBooking: true,
      }
    case LOADING_DELIVERY_BOOKING_END:
      return {
        ...state,
        loadingDeliveryBooking: false,
      }
    case CLEAR_CURRENT_DELIVERY_AND_DELIVERY_BOOKING:
    case CLEAR_LIST_DELIVERY_AND_DELIVERY_BOOKING:
    case UPDATE_DELIVERY_PAYMENT_STATUS:
    case GET_DELIVERY_DETAIL:
    case UPDATE_DELIVERY_DETAIL:
    case GET_EVENT_AND_DELIVERY:
    case UPDATE_EVENT_AND_DELIVERY:
    case CLEAR_EVENT_AND_DELIVERY:
    case CLEAR_DELIVERY_DETAIL:
    case LIST_DELIVERY_CREATED_BY_HOST:
    case GET_DELIVERY_BY_BUYER:
    case GET_DELIVERY_BY_HOST_AND_DATE:
    case GET_DELIVERY_BY_HOST:
    case UPDATE_LIST_DELIVERY:
    case GET_DELIVERY_AND_DELIVERY_BOOKING:
    case GET_SCHEDULED_DELIVERY: {
      return {
        ...state,
        ...action.payload,
      }
    }
    default:
      return state
  }
}

export default delivery
