import axiosClient from './axiosClient'

const deliveryApi = {
  getRememberedDeliveryZones: (shopId, isEventShop = false) => {
    if (isEventShop && !shopId) {
      // Trả về Promise.reject nếu là eventShop nhưng không có shopId
      return Promise.reject(new Error('ShopId is required for event shop'))
    }

    const url = isEventShop
      ? `/get-shop-event-remembered-delivery-zones${shopId ? `?shopId=${shopId}` : ''}`
      : '/get-remembered-delivery-zones'

    return axiosClient.get(url)
  },
  createDeliveryBooking: (data) => {
    const copyData = { ...data }

    const url =
      copyData.deliveryZone.type === 'shop'
        ? '/create-shop-event-delivery-booking'
        : '/create-delivery-booking'

    // avoid to error "Too many attributes"
    const newData = {
      ...copyData,
      deliveryZone: {
        ...copyData.deliveryZone,
        type: undefined,
      },
    }
    return axiosClient.post(url, newData)
  },
  getListDeliveryBookingsByEventId: (eid) => {
    const url = `/list-delivery-bookings-by-event?eid=${eid}`
    return axiosClient.get(url)
  },
  getDeliveryBooking: (ref, deliveryShop = false) => {
    const endPoint = !deliveryShop ? 'get-delivery-booking' : 'get-shop-event-delivery-booking'
    const url = `/${endPoint}?ref=${ref}`
    return axiosClient.get(url)
  },
  updateDeliveryBooking: (ref, data, deliveryShop = false) => {
    const endPoint = !deliveryShop
      ? 'update-delivery-booking'
      : 'update-shop-event-delivery-booking'
    const url = `/${endPoint}?ref=${ref}`
    return axiosClient.patch(url, data)
  },
  // update bookerStatus of Delivery booking
  updateDeliveryBookerStatus: (ref, data, deliveryShop = false) => {
    const endPoint = !deliveryShop
      ? 'update-delivery-booker-status'
      : 'update-shop-event-delivery-booker-status'
    const url = `/${endPoint}?ref=${ref}`
    return axiosClient.patch(url, data)
  },
  listDeliveryByEvent: (eid) => {
    const url = `/list-delivery-bookings-by-event?eid=${eid}`
    return axiosClient.get(url)
  },
  updateDeliveryBookingStatus: (ref, data, deliveryShop = false) => {
    const endPoint = !deliveryShop
      ? 'update-delivery-booking-status'
      : 'update-shop-event-delivery-booking-status'
    const url = `/${endPoint}?ref=${ref}`
    return axiosClient.patch(url, data)
  },
  getRememberedDeliveryAddress: (isEventShop = false) => {
    const url = !isEventShop
      ? '/get-remembered-delivery-address'
      : '/get-shop-event-remembered-delivery-address'
    return axiosClient.get(url)
  },
  updateRememberedDeliveryZones: (userId, data, isEventShop = false, shopId = null) => {
    const endPoint = isEventShop
      ? 'update-shop-event-remember-delivery-zones'
      : `update-remembered-delivery-zones?id=${userId}`
    const url = `/${endPoint}`
    return axiosClient.put(url, isEventShop ? { ...data, shopId } : data)
  },
  updateRememberedDeliveryAddress: (data, isEventShop = false) => {
    const url = isEventShop
      ? '/update-shop-event-remembered-delivery-address'
      : '/update-remembered-delivery-address'
    return axiosClient.put(url, data)
  },
  listDeliveryByBuyer: (buyerId, groupId, isEventShop = false) => {
    const endPoint = !isEventShop
      ? 'list-delivery-bookings-by-buyer'
      : 'list-shop-event-delivery-bookings-by-buyer'
    const url = `/${endPoint}?buyerId=${buyerId}`
    if (groupId) {
      axiosClient.interceptors.request.use((config) => {
        config.headers['group-id'] = groupId
        return config
      })
    }
    return axiosClient.get(url)
  },
  listDeliveryByHost: (hostId, deliveryTime = '') => {
    let url = ''
    if (deliveryTime) {
      url = `/list-delivery-bookings-by-host?hostId=${hostId}&deliveryTime=${deliveryTime}`
    } else {
      url = `/list-delivery-bookings-by-host?hostId=${hostId}`
    }
    return axiosClient.get(url)
  },

  createDeliveryByHost: (data, isEventShop) => {
    const url = !isEventShop ? '/create-delivery' : '/create-shop-event-delivery'

    if ('isEventShop' in data) {
      delete data.isEventShop
    }

    return axiosClient.post(url, data)
  },
  listDeliveryCreatedByHost: (hostId, groupId, deliveryShop = false, shopId = '') => {
    const endPoint =
      deliveryShop || shopId ? 'list-shop-event-deliveries-by-shop' : 'list-deliveries-by-host-id'

    const url = `/${endPoint}`

    if (groupId) {
      axiosClient.interceptors.request.use((config) => {
        config.headers['group-id'] = groupId
        return config
      })
    }

    const params = shopId ? { shopId } : { hostId }

    return axiosClient.get(url, { params })
  },
  getDelivery: (deliveryId, getDeliveryBooking = false, deliveryShop = false) => {
    const endPoint = !deliveryShop ? 'get-delivery' : 'get-shop-event-delivery'
    const url = `/${endPoint}?id=${deliveryId}${getDeliveryBooking ? '&getDeliveryBooking=1' : ''}`
    return axiosClient.get(url)
  },
  updateDeliveryStatus: (deliveryId, data, deliveryShop = false) => {
    const endPoint = !deliveryShop ? 'update-delivery' : 'update-shop-event-delivery'
    const url = `/${endPoint}?id=${deliveryId}`
    return axiosClient.patch(url, data)
  },
  deleteDelivery: (deliveryId, deliveryShop = false) => {
    const endPoint = !deliveryShop ? 'delete-delivery' : 'delete-shop-event-delivery'
    const url = `/${endPoint}?id=${deliveryId}`
    return axiosClient.delete(url)
  },
  updateDeliveryByHost: (deliveryId, data, deliveryShop = false) => {
    const endPoint = !deliveryShop ? 'update-delivery' : 'update-shop-event-delivery'
    const url = `/${endPoint}?id=${deliveryId}`
    return axiosClient.patch(url, data)
  },
  listScheduledDeliveries: (hostId) => {
    const url = `/list-scheduled-deliveries-by-host-id?hostId=${hostId}`
    return axiosClient.get(url)
  },
  deleteScheduledDelivery: (id) => {
    const url = `/delete-scheduled-delivery?id=${id}`
    return axiosClient.delete(url)
  },
  updateScheduledDelivery: (id, data) => {
    const url = `/update-scheduled-delivery?id=${id}`
    return axiosClient.patch(url, data)
  },
}

export default deliveryApi
