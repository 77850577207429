/* eslint-disable multiline-ternary */
import { yupResolver } from '@hookform/resolvers/yup'
import axios from 'axios'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useToasts } from 'react-toast-notifications'
import { Form, Spinner } from 'reactstrap'
import * as Yup from 'yup'

import authApi from '_api/auth'
import reportApi from '_api/report'
import userApi from '_api/user'
import InputField from '_components/Input'
import Password from '_components/Password'
import imgTop from '_images/img_top.svg'
import logoLeft from '_images/logo_left.svg'
import { userFirstLogin } from '_redux/modules/user'
import { POSTAL_CODE_MAX_VALUE } from '_utils/constant'
import { arrayToString, generateSecretCode } from '_utils/function'
import { setUserInfo, setUserToken } from '_utils/localData'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { updateUserInfoNewRegister } from '../../redux/modules/user'
import FormConnect from './FormConnect'
import SignUpOption from './SignUpOption'
import './style.scss'

const validationSchema = Yup.object().shape({
  firstName: Yup.string('Invalid first name').required('Invalid first name'),
  lastName: Yup.string('Invalid last name').required('Invalid last name'),
  email: Yup.string().email('Invalid email').required('Invalid email'),
  phone: Yup.string('Invalid phone')
    .typeError('Phone must be a number')
    .min(8, 'Phone number at least 8 digits')
    .max(16, 'Phone number with at most 16 digits')
    .required('Invalid phone'),
  postalCode: Yup.string()
    .max(POSTAL_CODE_MAX_VALUE, 'Invalid postal code')
    .nullable()
    .notRequired(),
})

const SignUpPage = () => {
  const history = useHistory()
  const { addToast } = useToasts()
  const [isSignUpWithPassword, setIsSignUpWithPassword] = useState(false)
  const [isShowFormConnect, setIsShowFormConnect] = useState(false)
  const [avatarFileUpload, setAvatarFileUpload] = useState('')
  const [avatarFileUrl, setAvatarFileUrl] = useState('')
  const [isPINForm, setIsPINForm] = useState(false)
  const [firstPin, setFirstPin] = useState(new Array(4))
  const [secondPin, setSecondPin] = useState(new Array(4))
  const [pinErrorMessage, setPinErrorMessage] = useState('')
  const dispatch = useDispatch()

  const secretCode = generateSecretCode()

  const {
    register,
    getValues,
    setError,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    mode: 'all',
    resolver: yupResolver(validationSchema),
  })

  const onUserImageUploaded = (event) => {
    const { files } = event.target
    if (files) {
      const img = files[0]
      setAvatarFileUrl(URL.createObjectURL(img))
      setAvatarFileUpload(files)
    }
  }

  const validatePIN = (firstPIN, secondPIN) => {
    if ((firstPIN && firstPIN.some((item) => item === undefined)) || firstPIN[0] === undefined) {
      setPinErrorMessage('Invalid password')
      return false
    }
    if ((secondPIN && secondPIN.some((item) => item === undefined)) || secondPIN[0] === undefined) {
      setPinErrorMessage('Invalid re-type password')
      return false
    }
    const isValid =
      firstPin.length === secondPIN.length &&
      firstPin.every((value, index) => value === secondPIN[index])

    return isValid
  }

  const registerNewAccount = async (data = {}) => {
    try {
      const res = await authApi.register(data)
      return res.msgResp.token
    } catch (error) {
      addToast(error.msgResp, { appearance: 'error', autoDismiss: true })
    }
  }

  const uploadPhoto = async (photo, token) => {
    try {
      const formData = new FormData()
      for (let i = 0; i < photo.length; i++) {
        formData.append('type', 1)
        formData.append('file', photo[i])
      }
      const res = await axios.post(`${process.env.REACT_APP_API_URL}/upload-photo`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Keep-AIive': secretCode,
        },
      })
      return res.data.msgResp.url
    } catch (error) {
      reportApi.report({ message: `[Signup 01]${error.msgCode} - ${error.msgResp}` })
    }
  }

  const updateProfile = async (userInfo, token) => {
    try {
      const res = await axios.patch(`${process.env.REACT_APP_API_URL}/update-user`, userInfo, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Keep-AIive': secretCode,
        },
      })
      userInfo = res.data.msgResp
    } catch (error) {
      reportApi.report({ message: `[Signup 02]${error.msgCode} - ${error.msgResp}` })
    }
  }

  const onSubmit = async (data) => {
    const { firstName, lastName, email, phone, postalCode } = data || {}
    let name = ''
    if (firstName && lastName) {
      name = `${firstName.trim()} ${lastName.trim()}`
    }
    const newData = {
      name,
      phone,
      postalCode,
      email: email.toLowerCase().trim(),
    }
    const isValid = validatePIN(firstPin, secondPin)
    if (isValid) {
      const isShareGroup = localStorage.getItem('isShareGroup')
      const userInfo = newData
      userInfo.password = arrayToString(secondPin)
      const token = await registerNewAccount(userInfo)
      if (avatarFileUpload) {
        const photoUrl = await uploadPhoto(avatarFileUpload, userInfo)
        userInfo.photoUrl = photoUrl
      }
      delete userInfo.email
      delete userInfo.password
      await updateProfile(userInfo, token)
      await setUserToken(token)
      const { msgResp } = await userApi.getUser()
      const premiumAccountFalse = { ...msgResp, features: { premiumAccount: false } }
      setUserInfo(premiumAccountFalse)
      dispatch(updateUserInfoNewRegister(premiumAccountFalse))
      dispatch(userFirstLogin())

      if (isShareGroup) {
        history.push(`/group/join/${isShareGroup.replace('/group/join/', '').trim()}`)
      } else {
        history.push('/welcome')
      }
    }
  }

  const onNextFunc = () => {
    const firstName = getValues('firstName')
    const lastName = getValues('lastName')
    const email = getValues('email')
    const phone = getValues('phone')
    if (!firstName) {
      setError('firstName', { type: 'invalid', message: 'Invalid first name' })
      return
    }
    if (firstName) {
      const splitFName = firstName.split(' ')
      if (splitFName.length > 1 && splitFName[1]) {
        setError('firstName', { type: 'invalid', message: 'First Name only one word' })
        return
      }
    }
    if (!lastName) {
      setError('lastName', { type: 'invalid', message: 'Invalid last name' })
      return
    }
    if (!email) {
      setError('email', { type: 'invalid', message: 'Invalid email' })
      return
    }
    if (!phone) {
      setError('phone', { type: 'invalid', message: 'Invalid phone' })
      return
    }
    authApi
      .searchUser(email.toLowerCase().trim())
      .then((res) => {
        if (!res.msgResp.uid) {
          if (Object.keys(errors).length === 0) setIsPINForm(true)
        } else {
          setError('email', { type: 'invalid', message: 'Email is taken' })
        }
      })
      .catch(({ msgResp, msgCode }) => {
        reportApi.report({ message: `[Signup 03]${msgCode} - ${msgResp}` })
      })
  }

  const onPinChangedFirst = (pinEntry, index) => {
    const newPin = [...firstPin]
    newPin[index] = pinEntry
    setFirstPin(newPin)
  }
  const onPinChangedSePIN = (pinEntry, index) => {
    const newPin = [...secondPin]
    newPin[index] = pinEntry
    setSecondPin(newPin)
  }

  return (
    <div className='p-login min-vh-100'>
      <div className='page-login page-login--screen'>
        <div className='main-top align-self-start'>
          <div className='container'>
            <div className='main-logo text-left'>
              <img src={logoLeft} />
            </div>
            <div className='main-img text-center'>
              <img src={imgTop} />
            </div>
          </div>
        </div>
        {!isSignUpWithPassword ? (
          isShowFormConnect ? (
            <FormConnect />
          ) : (
            <SignUpOption
              setIsSignUpWithPassword={setIsSignUpWithPassword}
              setIsShowFormConnect={setIsShowFormConnect}
            />
          )
        ) : (
          <div className='main-content'>
            <Form onSubmit={handleSubmit(onSubmit)} className='container'>
              {!isPINForm ? (
                <>
                  <h2 className='c-ttl c-ttl--01'>
                    Create profile to connect<span>to the community nearest to you.</span>
                  </h2>
                  <div className='c-form c-form--email'>
                    <div className='upload-image' style={{ width: 107 }}>
                      <img
                        id='userAvatarImg'
                        className='card-img-top'
                        src={avatarFileUrl}
                        alt=''
                        style={{ zIndex: 999 }}
                      />
                      <div className='upload-image-txt'>
                        <i className='icon-capture'></i>
                        <span>
                          Add Profile
                          <br />
                          Picture
                        </span>
                      </div>
                      <div className='u-file-upload__item'>
                        <div className='u-file-upload__wrap'>
                          <a className='c-upload preview-images-zone'>
                            <input
                              id='userAvatarInput'
                              type='file'
                              accept='image/*'
                              name='file_source_01'
                              size='40'
                              className='inputFile'
                              data-error='#error-file_source_01'
                              onChange={onUserImageUploaded}
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                    <small className='errorMessage'></small>
                    <div id='nameFieldGroup' className='form-group'>
                      <InputField
                        className='form-control'
                        id='name'
                        placeholder='First Name'
                        name='firstName'
                        type='text'
                        register={register('firstName')}
                      />
                      {errors?.firstName && (
                        <small className='errorMessage'>{errors.firstName.message}</small>
                      )}
                    </div>
                    <div id='nameFieldGroup' className='form-group'>
                      <InputField
                        className='form-control'
                        id='name'
                        placeholder='Last Name'
                        name='lastName'
                        type='text'
                        register={register('lastName')}
                      />
                      {errors?.lastName && (
                        <small className='errorMessage'>{errors.lastName.message}</small>
                      )}
                    </div>
                    <div id='emailFieldGroup' className='form-group'>
                      <InputField
                        className='form-control'
                        id='email'
                        name='email'
                        type='text'
                        placeholder='E-mail'
                        register={register('email')}
                      />
                      {errors?.email && (
                        <small className='errorMessage'>{errors.email.message}</small>
                      )}
                    </div>
                    <div className='form-group'>
                      <InputField
                        className='form-control'
                        id='phone'
                        name='phone'
                        placeholder='Mobile Number'
                        register={register('phone')}
                      />
                      {errors?.phone && (
                        <small className='errorMessage'>{errors.phone.message}</small>
                      )}
                    </div>
                    <div className='form-group'>
                      <InputField
                        className='form-control'
                        id='postalCode'
                        name='postalCode'
                        type='number'
                        placeholder='Postal Code'
                        register={register('postalCode')}
                      />
                      {errors?.postalCode && (
                        <small className='errorMessage'>{errors.postalCode.message}</small>
                      )}
                    </div>
                    <div className='form-group'>
                      <button className='btn btn--main' type='button' onClick={onNextFunc}>
                        NEXT
                      </button>
                    </div>
                  </div>
                </>
              ) : (
                <div className='c-form c-form--email'>
                  <div className='c-txt-org text-center'>PIN</div>
                  <div id='form-pin'>
                    <Password
                      isFirstPin={true}
                      pinLength={4}
                      onPinChanged={onPinChangedFirst}
                      pin={firstPin}
                    />
                    <div className='c-txt-gray text-center'>Re-type PIN</div>
                    <Password pinLength={4} onPinChanged={onPinChangedSePIN} pin={secondPin} />
                    {pinErrorMessage && <small className='errorMessage'>{pinErrorMessage}</small>}

                    <div className='form-group'>
                      <button className='btn btn--main' type='submit' disabled={isSubmitting}>
                        {isSubmitting ? <Spinner color='light' size='sm' /> : 'CONNECT NOW'}
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </Form>
          </div>
        )}
      </div>
    </div>
  )
}

export default SignUpPage
