/* eslint-disable function-paren-newline */
/* eslint-disable no-confusing-arrow */
/* eslint-disable no-useless-return */
import eventApi from '_api/event'
import groupApi from '_api/group'
import orderApi from '_api/order'
import serviceApi from '_api/service'
import { clearDeliveryDetail } from '_redux/modules/delivery'
import { clearPastPurchasedMember } from '_redux/modules/group'
import {
  clearDataPurchaseDetail,
  clearDataPurchaseDetailByEid,
  clearMyOrder,
} from '_redux/modules/order'
import { DOMAIN, STATUS_PAID } from '_utils/constant'
import { getDomain, getUserInfo, setDomain } from '_utils/localData'
import { debounce } from 'lodash'
import { DEFAULT_KEY, generateCacheTTL } from 'redux-cache'

const UPDATE_PAYABLE_STATUS = 'UPDATE_PAYABLE_STATUS'
const GET_LIST_EVENTS = 'GET_LIST_EVENTS'
const GET_LIST_EVENT_GROUP_ID_BY_HOST = 'GET_LIST_EVENT_GROUP_ID_BY_HOST'
const GET_LIST_SCHEDULED_EVENTS = 'GET_LIST_SCHEDULED_EVENTS'
const DELETE_LIST_SCHEDULED_EVENTS = 'DELETE_LIST_SCHEDULED_EVENTS'
const GET_LIST_SCHEDULED_EVENTS_ERROR = 'GET_LIST_SCHEDULED_EVENTS_ERROR'
const GET_MY_EVENTS = 'GET_MY_EVENTS'
const GET_ALL_EVENTS_OPENING_REQUEST = 'GET_ALL_EVENTS_OPENING_REQUEST'
const GET_ALL_EVENTS_OPENING_SUCCESS = 'GET_ALL_EVENTS_OPENING_SUCCESS'
const GET_ALL_EVENTS_OPENING_FAIL = 'GET_ALL_EVENTS_OPENING_FAIL'
const GET_SHARE_EVENT_CODE = 'GET_SHARE_EVENT_CODE'
const CLEAR_EVENT = 'CLEAR_EVENT'
const DELETE_EVENT = 'DELETE_EVENT'
const GET_EVENT_BY_ID = 'GET_EVENT_BY_ID'
const GET_EVENT_AND_ORDER_BUY_EID = 'GET_EVENT_AND_ORDER_BUY_EID'
const CREATE_EVENT = 'CREATE_EVENT'
const UPDATE_EVENT = 'UPDATE_EVENT'
const UPDATE_STATUS_EVENT = 'UPDATE_STATUS_EVENT'
const UPDATE_EVENT_ORDER = 'UPDATE_EVENT_ORDER'
const UPDATE_STATUS_ORDER = 'UPDATE_STATUS_ORDER'
const UPDATE_TOTAL_ORDER_COUNT_WHEN_ORDER = 'UPDATE_TOTAL_ORDER_COUNT_WHEN_ORDER'
const UPDATE_TOTAL_ORDER_COUNT_WHEN_CANCEL_ORDER = 'UPDATE_TOTAL_ORDER_COUNT_WHEN_CANCEL_ORDER'
const UPDATE_TOTAL_ORDER_COUNT_WHEN_UPDATE_ORDER = 'UPDATE_TOTAL_ORDER_COUNT_WHEN_UPDATE_ORDER'
const CLEAR_EVENT_ORDER = 'CLEAR_EVENT_ORDER'
const GET_COMBINED_PAYMENT = 'GET_COMBINED_PAYMENT'
const CLEAR_MY_EVENTS = 'CLEAR_MY_EVENTS'
const CLEAR_ALL_EVENTS = 'CLEAR_ALL_EVENTS'
const GET_EVENTS_CLOSE_PER_MONTH_BY_HOST = 'GET_EVENTS_CLOSE_PER_MONTH_BY_HOST'
const GET_EVENTS_COLLECTION_PER_MONTH_BY_HOST = 'GET_EVENTS_COLLECTION_PER_MONTH_BY_HOST'
const GET_LIST_EVENT_AND_DELIVERY = 'GET_LIST_EVENT_AND_DELIVERY'
const GET_LIST_SCHEDULED_EVENTS_BY_GROUP_ID = 'GET_LIST_SCHEDULED_EVENTS_BY_GROUP_ID'
const GET_LIST_EVENTS_UN_AUTH = 'GET_LIST_EVENTS_UN_AUTH'
const GET_ALL_EVENTS_BY_GROUP = 'GET_ALL_EVENTS_BY_GROUP'
const CLEAR_ALL_EVENTS_OPENING = 'CLEAR_ALL_EVENTS_OPENING'
const GET_LIST_EVENT_PUBLIC = 'GET_LIST_EVENT_PUBLIC'
const PAYMENT_SCHEDULE_EVENT = 'PAYMENT_SCHEDULE_EVENT'
const UPDATE_DELIVERY_PAYMENT_STATUS = 'UPDATE_DELIVERY_PAYMENT_STATUS'
const GET_MY_SHOP_EVENTS = 'GET_MY_SHOP_EVENTS'
const UPDATE_DELIVERY_COMMENT_DESKTOP = 'UPDATE_DELIVERY_COMMENT_DESKTOP'
const SET_INITIAL_DATA_LOADED = 'SET_INITIAL_DATA_LOADED'
const SET_LOADING_GET_EVENT_GROUP_ID = 'SET_LOADING_GET_EVENT_GROUP_ID'
const SET_LOADING_GET_EVENT_AND_ORDER = 'SET_LOADING_GET_EVENT_AND_ORDER'
const ADD_EVENT_CLOSE_PER_MONTH_BY_HOST = 'ADD_EVENT_CLOSE_PER_MONTH_BY_HOST'
const ADD_EVENT_COLLECTION_PER_MONTH_BY_HOST = 'ADD_EVENT_COLLECTION_PER_MONTH_BY_HOST'
const REMOVE_EVENT_CLOSE_PER_MONTH_BY_HOST = 'REMOVE_EVENT_CLOSE_PER_MONTH_BY_HOST'
const REMOVE_EVENT_COLLECTION_PER_MONTH_BY_HOST = 'REMOVE_EVENT_COLLECTION_PER_MONTH_BY_HOST'
const UPDATE_EVENT_CLOSE_PER_MONTH_BY_HOST = 'UPDATE_EVENT_CLOSE_PER_MONTH_BY_HOST'
const UPDATE_EVENT_COLLECTION_PER_MONTH_BY_HOST = 'UPDATE_EVENT_COLLECTION_PER_MONTH_BY_HOST'
const LOADING_GET_EVENT_AND_ORDER_END = 'LOADING_GET_EVENT_AND_ORDER_END'

// Modify initial state to check sessionStorage
const initialState = {
  [DEFAULT_KEY]: null,
  allEvents: [],
  myEvents: [],
  myShopEvents: [],
  newGroup: null,
  eventById: null,
  eventAndOrderById: [],
  callNewAPIListOrder: false,
  loadingAllEventsOpening: true,
  errorAllEventsOpening: null,
  errorGetEventAndOrder: null,
  allScheduleEvent: [],
  allScheduleEventByGroupId: [],
  errorAllScheduleEvent: null,
  listCombinedPayments: [],
  listEventGroupIdByHost: [],
  listEventsClosePerMonthByHost: null,
  listEventsCollectionPerMonthByHost: null,
  listEventAndDelivery: [],
  eventsPublic: [], // for API 162,
  currentPlanScheduleEvent: null,
  loadingGetListEventOrder: true,
  loadingGetListCombinedPayment: true,
  loadingGetEventAndOrder: true,
  loadingGetEventGroupId: true,
  initialDataLoaded: sessionStorage.getItem('initialDataLoaded') === 'true',
  selectedEntity: null,
  eventsMyGroupBuy: [],
}

export const setSelectedEntity = (selectedEntity) => (dispatch) => {
  dispatch({
    type: 'SET_SELECTED_ENTITY',
    payload: { selectedEntity },
  })
}

// Add new action creator
export const setInitialDataLoaded = (loaded) => (dispatch) => {
  // Store in sessionStorage so it clears on page refresh
  if (loaded) {
    sessionStorage.setItem('initialDataLoaded', 'true')
  } else {
    sessionStorage.removeItem('initialDataLoaded')
  }

  dispatch({
    type: SET_INITIAL_DATA_LOADED,
    payload: { initialDataLoaded: loaded },
  })
}

export const updateOrderNote = (eventId, ref, note) => (dispatch, getState) => {
  const { eventAndOrderById } = getState().event

  const updatedEventAndOrderById = eventAndOrderById.map((item) => {
    if (item.id !== eventId) return item

    const updatedOrders = item.orders.map((order) =>
      order.ref === ref ? { ...order, comment: note } : order
    )

    return { ...item, orders: updatedOrders }
  })

  dispatch({
    type: UPDATE_EVENT_ORDER,
    payload: { eventAndOrderById: updatedEventAndOrderById },
  })
}

export const paymentScheduleEvent =
  ({ packageId, packageType, startDate }) =>
  async (dispatch) => {
    try {
      const { msgResp } = await eventApi.paymentScheduleEvent({
        packageId,
        packageType,
        startDate,
      })

      if (msgResp?.paymentLink) {
        window.open(msgResp.paymentLink, '_blank')
      }

      dispatch({
        type: PAYMENT_SCHEDULE_EVENT,
        payload: { currentPlanScheduleEvent: msgResp },
      })
    } catch (error) {
      throw new Error(error?.msgResp || error.message)
    }
  }

export const getListEventsOpeningMyGroupBuy = () => async (dispatch) => {
  try {
    const { msgResp } = await eventApi.getListEventsOpeningMyGroupBuy()

    dispatch({
      type: GET_LIST_EVENTS,
      payload: { eventsMyGroupBuy: msgResp },
    })
  } catch (error) {
    throw new Error(error?.msgResp || error.message)
  }
}

export const getListEventsOpening = () => async (dispatch) => {
  try {
    const { msgResp } = await eventApi.getListEventsOpening()

    dispatch({
      type: GET_LIST_EVENTS,
      payload: { eventsPublic: msgResp },
    })
  } catch (error) {
    throw new Error(error?.msgResp || error.message)
  }
}

export const getAllScheduleEventByGroupId = (groupId) => async (dispatch) => {
  try {
    const { msgResp: newAllScheduleEvent } = await eventApi.listMyScheduledEvents()
    const allScheduleEventByGroupId = newAllScheduleEvent?.filter(
      (item) => item.groupId === groupId
    )
    dispatch({
      type: GET_LIST_SCHEDULED_EVENTS_BY_GROUP_ID,
      payload: { allScheduleEventByGroupId },
    })
  } catch (error) {
    throw new Error(error)
  }
}

export const getListEventAndDelivery = (groupId) => async (dispatch) => {
  try {
    const { msgResp } = await eventApi.getListEventAndDelivery(groupId)

    dispatch({
      type: GET_LIST_EVENT_AND_DELIVERY,
      payload: { listEventAndDelivery: msgResp },
    })
  } catch (error) {
    throw new Error(error)
  }
}

export const getEventGroupIdByHost = (groupId, date) => async (dispatch) => {
  dispatch({
    type: SET_LOADING_GET_EVENT_GROUP_ID,
    payload: { loadingGetEventGroupId: true },
  })
  const userInfo = getUserInfo()
  let listEventGroupIdByHost = []
  try {
    const response = await eventApi.getListEventsByGroupId(groupId, date)

    // if (!response.msgResp.length) {
    //   dispatch({
    //     type: 'GET_EVENT_AND_ORDER_BUY_EID',
    //     payload: {
    //       loadingGetEventAndOrder: true,
    //     },
    //   })
    // }

    if (response.msgResp) {
      listEventGroupIdByHost = response.msgResp.filter(
        (_event) => _event.createdUserId === userInfo.id
      )
    }
    dispatch({
      type: GET_LIST_EVENT_GROUP_ID_BY_HOST,
      payload: { listEventGroupIdByHost },
    })
  } catch (error) {
    throw new Error(error.msgResp)
  } finally {
    dispatch({
      type: SET_LOADING_GET_EVENT_GROUP_ID,
      payload: { loadingGetEventGroupId: false },
    })
  }
}

export const callNewListOrder = () => async (dispatch) => {
  dispatch({
    type: GET_EVENT_AND_ORDER_BUY_EID,
    payload: { callNewAPIListOrder: true },
  })
}

export const getAllEventsOpening = async (dispatch, getState) => {
  dispatch({
    type: GET_ALL_EVENTS_OPENING_REQUEST,
    payload: { loadingAllEventsOpening: true },
  })
  try {
    const { event } = getState()
    const { allEvents } = event

    // Not call API from server
    if (allEvents && allEvents.length > 0) {
      dispatch({
        type: GET_ALL_EVENTS_OPENING_SUCCESS,
        payload: { loadingAllEventsOpening: false, allEvents },
      })
      return
    }

    // Call API from server
    let domainLocal = getDomain(DOMAIN)
    const parser = document.createElement('a')
    parser.href = process.env.REACT_APP_APP_URL

    if (!domainLocal && DOMAIN !== parser.hostname) {
      const { msgResp } = await serviceApi.getGroupIdByDomainName(DOMAIN, STATUS_PAID)
      const { groupId } = msgResp

      domainLocal = groupId
      setDomain(DOMAIN, groupId)
    }

    const { msgResp: newAllEventsOpening } = await eventApi.getListEventsOpening(domainLocal)
    dispatch({
      type: GET_ALL_EVENTS_OPENING_SUCCESS,
      payload: { loadingAllEventsOpening: false, allEvents: newAllEventsOpening },
    })
  } catch (error) {
    dispatch({
      type: GET_ALL_EVENTS_OPENING_FAIL,
      payload: { loadingAllEventsOpening: false, errorAllEventsOpening: error },
    })
  }
}

export const clearAllEventsOpening = () => (dispatch) => {
  dispatch({
    type: CLEAR_ALL_EVENTS_OPENING,
    payload: { allEvents: [] },
  })
  // clearAllEventsOpeningLocal()
}

export const getListEventUnAuth = () => async (dispatch) => {
  try {
    const { msgResp } = await eventApi.getListEventUnAuth(10, 1)
    dispatch({
      type: GET_LIST_EVENTS_UN_AUTH,
      payload: { allEvents: msgResp.events },
    })
  } catch (e) {}
}

export const getScheduledEvent = async (dispatch, getState) => {
  try {
    const { event } = getState()
    const { allScheduleEvent } = event

    // Not call API from server
    if (allScheduleEvent && allScheduleEvent.length > 0) {
      return
    }

    // Call API from server
    let domainLocal = getDomain(DOMAIN)
    const parser = document.createElement('a')
    parser.href = process.env.REACT_APP_APP_URL

    if (!domainLocal && DOMAIN !== parser.hostname) {
      const { msgResp } = await serviceApi.getGroupIdByDomainName(DOMAIN, STATUS_PAID)
      const { groupId } = msgResp

      domainLocal = groupId
      setDomain(DOMAIN, groupId)
    }
    const { msgResp: newAllScheduleEvent } = await eventApi.listMyScheduledEvents(domainLocal)

    dispatch({
      type: GET_LIST_SCHEDULED_EVENTS,
      payload: { allScheduleEvent: newAllScheduleEvent },
    })
  } catch (error) {
    dispatch({
      type: GET_LIST_SCHEDULED_EVENTS,
      payload: { errorAllScheduleEvent: error },
    })
  }
}

export const clearScheduledEvent = (dispatch) => {
  dispatch({
    type: DELETE_LIST_SCHEDULED_EVENTS,
    payload: { allScheduleEvent: [] },
  })
}

export const deleteScheduledEvent =
  (id, isDesktop = false) =>
  (dispatch, getState) => {
    try {
      const { event } = getState()
      const { allScheduleEvent, allScheduleEventByGroupId } = event

      if (isDesktop) {
        const newAllScheduleEventByGroupId = allScheduleEventByGroupId.filter(
          (item) => item.id !== id
        )
        dispatch({
          type: GET_LIST_SCHEDULED_EVENTS_BY_GROUP_ID,
          payload: { allScheduleEventByGroupId: newAllScheduleEventByGroupId },
        })
      } else {
        const newAllScheduleEvent = allScheduleEvent.filter((item) => item.id !== id)
        dispatch({
          type: GET_LIST_SCHEDULED_EVENTS,
          payload: { allScheduleEvent: newAllScheduleEvent },
        })
      }
    } catch (error) {}
  }

export const getMyEvents = async (dispatch, getState) => {
  try {
    const { event, group } = getState()
    const { myEvents, allEvents } = event
    const { myGroups } = group
    const userInfo = getUserInfo()

    // Return cached data if available
    if (myEvents?.length) {
      return
    }

    // Use existing allEvents if available
    if (allEvents?.length > 0 && myEvents === null) {
      const newMyEvents = allEvents.filter((_event) => _event.createdUserId === userInfo.id)
      dispatch({ type: GET_MY_EVENTS, payload: { myEvents: newMyEvents } })
      return
    }

    // Get domain info
    let domainLocal = getDomain(DOMAIN)
    if (!domainLocal) {
      const parser = document.createElement('a')
      parser.href = process.env.REACT_APP_APP_URL

      if (DOMAIN !== parser.hostname) {
        const { msgResp } = await serviceApi.getGroupIdByDomainName(DOMAIN, STATUS_PAID)
        const { groupId } = msgResp
        domainLocal = groupId
        setDomain(DOMAIN, groupId)
      }
    }

    // Get list of events
    const { msgResp: listEvents } = await eventApi.getListMyEvents(domainLocal)

    // Get all orders in parallel
    const eventsWithOrders = await Promise.all(
      listEvents.map(async (_event) => {
        const { msgResp: orders } = await orderApi.getListOrdersByEventId(_event.id)
        return {
          ..._event,
          orders,
          groupName: myGroups?.find((g) => g.gid === _event.groupId)?.name,
        }
      })
    )

    // If group names are not available, fetch them in parallel
    if (!myGroups) {
      // Step 1: Extract unique group IDs
      const uniqueGroupIds = [...new Set(eventsWithOrders.map((e) => e.groupId))]

      // Step 2: Filter eventsWithOrders to include only unique group IDs
      const uniqueEventsWithOrders = uniqueGroupIds.map((groupId) =>
        eventsWithOrders.find((e) => e.groupId === groupId)
      )

      const groupPromises = uniqueEventsWithOrders.map(async (e) => {
        const { msgResp: groupInfo } = await groupApi.getById(e.groupId)
        return {
          ...e,
          groupName: groupInfo.name,
        }
      })
      const eventsWithGroupNames = await Promise.all(groupPromises)
      const myFilteredEvents = eventsWithGroupNames.filter(
        (e) => e.createdUserEmail === userInfo?.email
      )
      dispatch({
        type: GET_MY_EVENTS,
        payload: { myEvents: myFilteredEvents },
      })
    } else {
      const myFilteredEvents = eventsWithOrders.filter(
        (e) => e.createdUserEmail === userInfo?.email
      )
      dispatch({
        type: GET_MY_EVENTS,
        payload: { myEvents: myFilteredEvents },
      })
    }
  } catch (error) {
    throw new Error(error.msgResp)
  }
}

export const getMyShopEvents = async (dispatch, getState) => {
  try {
    const { event, shop } = getState()
    const { myShopEvents, allEvents } = event
    const { myShops } = shop
    const userInfo = getUserInfo()

    if (allEvents?.length > 0 && myShopEvents === null) {
      const newMyEvents = allEvents.filter((_event) => _event.createdUserId === userInfo.id)
      dispatch({ type: GET_MY_EVENTS, payload: { myEvents: newMyEvents } })
    } else {
      let events = []
      const isCallApiShopEvent = true

      const { msgResp: listEvents } = await eventApi.getListMyEvents(null, isCallApiShopEvent)

      const eventPromises = listEvents.map(async (_event) => {
        const { msgResp: orders } = await orderApi.getListOrdersByEventId(_event.id)
        const newEvent = { ..._event, orders }

        newEvent.shopName = myShops.find((shopItem) => shopItem.id === _event.shopId)?.name

        return newEvent
      })

      events = await Promise.all(eventPromises)

      const newMyEvents = events.filter((item) => item.createdUserEmail === userInfo.email)

      dispatch({
        type: GET_MY_SHOP_EVENTS,
        payload: { myShopEvents: newMyEvents },
      })
    }
  } catch (error) {
    // server return error "Event not found" => ignore
    if (error.msgCode !== 61203) {
      throw new Error(error.msgResp)
    }
  }
}

export const getShareEventCode = (eventId) => async (dispatch, getState) => {
  try {
    const { event } = getState()
    const { myEvents } = event
    const { msgResp } = await eventApi.getShareEventCode(eventId)
    const newMyEvents = myEvents
    const indexInMyEvents = newMyEvents.filter((item) => item.id === eventId)
    if (indexInMyEvents !== -1) {
      newMyEvents[indexInMyEvents].shareCode = msgResp.shareCode
      dispatch({
        type: GET_SHARE_EVENT_CODE,
        payload: { myEvents: [...newMyEvents] },
      })
    }
  } catch (error) {}
}

export const clearGroup = () => ({
  type: CLEAR_EVENT,
})

export const updateDataEventAndOrder = (eventId) => async (dispatch, getState) => {
  try {
    const { event } = getState()
    const { eventAndOrderById } = event
    const newEventAndOrderById = eventAndOrderById
    const index = newEventAndOrderById.findIndex((item) => item.id === eventId)
    if (index !== -1) {
      newEventAndOrderById[index].updated = true
    }
    dispatch({
      type: UPDATE_EVENT_ORDER,
      payload: { eventAndOrderById: newEventAndOrderById },
    })
  } catch (error) {}
}

export const clearEventAndOrder = () => async (dispatch) => {
  dispatch({
    type: CLEAR_EVENT_ORDER,
    payload: { eventAndOrderById: [] },
  })
}

export const clearMyEvents = () => async (dispatch) => {
  dispatch({
    type: CLEAR_MY_EVENTS,
    payload: { myEvents: [] },
  })
}

export const clearAllEvents = () => async (dispatch) => {
  dispatch({
    type: CLEAR_ALL_EVENTS,
    payload: { allEvents: [] },
  })
}

export const updateEventWhenOrder = (newOrder) => async (dispatch, getState) => {
  try {
    const { event } = getState()
    const { allEvents, myEvents } = event
    const { eid, details } = newOrder

    let count = 0
    for (const key in details) {
      if (Object.hasOwnProperty.call(details, key)) {
        count += details[key][0].quantity
      }
    }

    const indexEvent = allEvents?.findIndex((item) => item.id === eid)
    if (allEvents.length && indexEvent !== -1) {
      allEvents[indexEvent].totalOrderCount += count
    }

    const indexInMyEvents = myEvents?.findIndex((item) => item.id === eid)
    if (myEvents.length && indexInMyEvents !== -1) {
      myEvents[indexInMyEvents].totalOrderCount += count
    }

    dispatch({
      type: UPDATE_TOTAL_ORDER_COUNT_WHEN_ORDER,
      payload: {
        allEvents: [...allEvents],
        myEvents: [...myEvents],
      },
    })
  } catch (error) {}
}

export const updateTotalOrderCountWhenCancelOrder = (orderDetail) => async (dispatch, getState) => {
  try {
    const { event } = getState()
    const { allEvents, myEvents } = event
    const { event: eventDetail, productOrder } = orderDetail
    const totalOrderCount = productOrder?.allProductPerOrder.reduce((a, b) => a + b.pQuantity, 0)

    const indexInAllEvents = allEvents?.findIndex((item) => item.id === eventDetail.id)
    if (allEvents.length && indexInAllEvents !== -1) {
      allEvents[indexInAllEvents].totalOrderCount -= totalOrderCount
    }

    const indexInMyEvents = myEvents?.findIndex((item) => item.id === eventDetail.id)
    if (myEvents.length && indexInMyEvents !== -1) {
      myEvents[indexInMyEvents].totalOrderCount -= totalOrderCount
    }
    dispatch({
      type: UPDATE_TOTAL_ORDER_COUNT_WHEN_CANCEL_ORDER,
      payload: {
        allEvents: [...allEvents],
        myEvents: [...myEvents],
      },
    })
  } catch (error) {}
}

export const updateTotalOrderCountWhenUpdateOrder = (orderDetail) => async (dispatch, getState) => {
  try {
    const { event } = getState()
    const { allEvents, myEvents } = event
    const { eid, details, initTotalOrderCount } = orderDetail

    let count = 0
    for (const key in details) {
      if (Object.hasOwnProperty.call(details, key)) {
        count += details[key][0].quantity
      }
    }

    count -= initTotalOrderCount

    const indexEvent = allEvents?.findIndex((item) => item.id === eid)
    if (allEvents.length && indexEvent !== -1) {
      allEvents[indexEvent].totalOrderCount += count
    }

    const indexInMyEvents = myEvents?.findIndex((item) => item.id === eid)
    if (myEvents.length && indexInMyEvents !== -1) {
      myEvents[indexInMyEvents].totalOrderCount += count
    }
    dispatch({
      type: UPDATE_TOTAL_ORDER_COUNT_WHEN_UPDATE_ORDER,
      payload: {
        allEvents: [...allEvents],
        myEvents: [...myEvents],
      },
    })
  } catch (error) {}
}

export const updateOrderStatus =
  (eventId, ref, status, buyerStatus = null) =>
  async (dispatch, getState) => {
    try {
      const { event } = getState()
      const { eventAndOrderById } = event

      const updatedEventAndOrderById = eventAndOrderById.map((item) => {
        if (item.id !== eventId) return item

        const updatedOrders = item.orders.map((order) =>
          order.ref === ref ? { ...order, status, buyerStatus } : order
        )

        return { ...item, orders: updatedOrders }
      })

      dispatch(clearDataPurchaseDetailByEid(eventId))
      dispatch(clearMyOrder())
      dispatch(clearPastPurchasedMember())
      dispatch({
        type: UPDATE_STATUS_ORDER,
        payload: {
          eventAndOrderById: updatedEventAndOrderById,
          callNewAPIListOrder: true,
        },
      })
    } catch (error) {}
  }

const pendingPromises = new Map()
const listEventId = new Set()

const debouncedGetEventAndOrder = debounce(
  async (dispatch, getState, eventIds, eventShop = false, isByAdmin = false) => {
    try {
      const { event } = getState()
      const { eventAndOrderById = [] } = event
      const updatedEventAndOrderById = [...eventAndOrderById]
      const uniqueEventIds = Array.from(eventIds)

      // Tạo một mảng các Promise cho mỗi eventId
      const promises = uniqueEventIds.map(async (eventId) => {
        const { msgResp: _event } = await eventApi.getEventDetail({
          eid: eventId,
          isGetDeliveryZones: true,
          isEventShop: eventShop,
          isByAdmin,
        })

        const { msgResp: _orderByEid } = await orderApi.getListOrdersByEventId(
          eventId,
          eventShop,
          isByAdmin
        )

        const newEventAndOrderById = { ..._event }

        if (_orderByEid && _orderByEid.length > 0) {
          newEventAndOrderById.orders = _orderByEid
        }

        if (_orderByEid && _orderByEid.length > 0) {
          const orderNo = new Set(_orderByEid.map((order) => order.eOrderNo))
          const orderRef = new Set(_orderByEid.map((order) => order.ref))

          if (orderNo.size !== orderRef.size || Math.max(...orderNo) !== orderRef.size) {
            const { msgCode } = await orderApi.updateOrderNo(eventId)
            if (msgCode && Number(msgCode) % 100 === 0) {
              newEventAndOrderById.orders = _orderByEid
            }
          }
        }

        return newEventAndOrderById
      })

      // Chờ tất cả các Promise hoàn thành
      const results = await Promise.all(promises)

      // Cập nhật state với kết quả mới
      results.forEach((newEventAndOrderById) => {
        const index = updatedEventAndOrderById.findIndex(
          (item) => item.id === newEventAndOrderById.id
        )
        if (index !== -1) {
          updatedEventAndOrderById[index] = newEventAndOrderById
        } else {
          updatedEventAndOrderById.push(newEventAndOrderById)
        }
      })

      dispatch({
        type: GET_EVENT_AND_ORDER_BUY_EID,
        payload: {
          eventAndOrderById: updatedEventAndOrderById,
          callNewAPIListOrder: false,
          errorGetEventAndOrder: null,
        },
      })

      // Xóa các Promise đã hoàn thành khỏi Map
      pendingPromises.clear()
      listEventId.clear()
    } catch (e) {
      dispatch({
        type: GET_EVENT_AND_ORDER_BUY_EID,
        payload: { errorGetEventAndOrder: e },
      })
    } finally {
      dispatch({
        type: GET_EVENT_AND_ORDER_BUY_EID,
        payload: { loadingGetEventAndOrder: false },
      })
    }
  },
  100
) // Debounce trong 100ms

export const getEventAndOrder =
  (eventId, eventShop = false, isByAdmin = false) =>
  async (dispatch, getState) => {
    dispatch({
      type: SET_LOADING_GET_EVENT_AND_ORDER,
      payload: { loadingGetEventAndOrder: true },
    })
    listEventId.add(eventId)
    // Nếu đã có một Promise đang chờ xử lý cho eventId này, trả về Promise đó
    if (pendingPromises.has(eventId)) {
      return pendingPromises.get(eventId)
    }

    const promise = new Promise((resolve) => {
      debouncedGetEventAndOrder(dispatch, getState, listEventId, eventShop, isByAdmin)

      resolve()
    })

    // Lưu Promise vào Map
    pendingPromises.set(eventId, promise)

    return promise
  }

export const getOrderCombinedPayment = (combinedPaymentRef) => async (dispatch, getState) => {
  try {
    const { event } = getState()
    const { listCombinedPayments = [], eventAndOrderById = [] } = event

    const listEventAndOrder = []

    const exitOrderCombinedPayment = listCombinedPayments.findIndex(
      (item) => item.combinedPaymentRef === combinedPaymentRef
    )

    let orderCombinedPayment
    const uniqueOrderCombinedPayment = []

    if (exitOrderCombinedPayment === -1) {
      const { msgResp } = await eventApi.listInvoicesCombinedPayment(combinedPaymentRef)
      const { orders } = msgResp

      // eslint-disable-next-line no-unused-vars
      orderCombinedPayment = orders.map(({ ref, eid, eTitle }) => ({ ref, eid, eTitle }))

      orderCombinedPayment.map((x) =>
        uniqueOrderCombinedPayment.filter((a) => a.ref === x.ref && a.eid === x.eid).length > 0
          ? null
          : uniqueOrderCombinedPayment.push(x)
      )
    } else {
      // eslint-disable-next-line prefer-destructuring
      orderCombinedPayment = listCombinedPayments[exitOrderCombinedPayment].orderCombinedPayment
    }

    if (uniqueOrderCombinedPayment.length) {
      dispatch({
        type: GET_COMBINED_PAYMENT,
        payload: {
          listCombinedPayments: [
            ...listCombinedPayments,
            { combinedPaymentRef, orderCombinedPayment: uniqueOrderCombinedPayment },
          ],
        },
      })
    }

    for (const element of orderCombinedPayment) {
      const { eid } = element
      const exitEvenAndOrder = eventAndOrderById.findIndex((item) => item.id === eid)
      const exitListEO = listEventAndOrder.findIndex((item) => item.id === eid)

      if (exitEvenAndOrder === -1 && exitListEO === -1) {
        const { msgResp: _event } = await eventApi.getEventById(eid)
        const { msgResp: _orderByEid } = await orderApi.getListOrdersByEventId(eid)

        const newEventAndOrderById = {
          ..._event,
          orders: _orderByEid,
        }
        listEventAndOrder.push(newEventAndOrderById)
      }
    }
    if (listEventAndOrder.length) {
      const eventMap = new Map()
      const combinedList = [...eventAndOrderById, ...listEventAndOrder]

      combinedList.forEach((item) => {
        if (
          !eventMap.has(item.id) ||
          (item.orders && item.orders.length > eventMap.get(item.id).orders.length)
        ) {
          eventMap.set(item.id, item)
        }
      })

      // Chuyển Map thành mảng
      const uniqueEventAndOrderById = Array.from(eventMap.values())

      dispatch({
        type: GET_EVENT_AND_ORDER_BUY_EID,
        payload: {
          eventAndOrderById: uniqueEventAndOrderById,
          callNewAPIListOrder: false,
          errorGetEventAndOrder: null,
        },
      })
    }
  } catch (e) {}
}

export const getListEventOrder =
  (listEid, isEventShop = false) =>
  async (dispatch, getState) => {
    try {
      const {
        event: { eventAndOrderById = [] },
      } = getState()
      const listEventAndOrder = []

      const fetchEventAndOrders = async (_eid) => {
        const [eventResponse, orderResponse] = await Promise.all([
          eventApi.getEventById(_eid, isEventShop),
          orderApi.getListOrdersByEventId(_eid, isEventShop),
        ])

        return {
          ...eventResponse.msgResp,
          orders: orderResponse.msgResp,
        }
      }

      const newEvents = await Promise.all(
        listEid
          .filter((_eid) => !eventAndOrderById.some((item) => item.id === _eid))
          .filter((_eid) => !listEventAndOrder.some((item) => item.id === _eid))
          .map(fetchEventAndOrders)
      )

      listEventAndOrder.push(...newEvents)

      if (listEventAndOrder.length) {
        dispatch({
          type: GET_EVENT_AND_ORDER_BUY_EID,
          payload: {
            eventAndOrderById: [...eventAndOrderById, ...listEventAndOrder],
            callNewAPIListOrder: false,
            errorOrderDetail: null,
          },
        })
      }
    } catch (error) {
      dispatch({
        type: GET_EVENT_AND_ORDER_BUY_EID,
        payload: {
          errorOrderDetail: error.message || 'An error occurred while fetching data',
        },
      })
    } finally {
      dispatch({
        type: GET_EVENT_AND_ORDER_BUY_EID,
        payload: { loadingGetEventAndOrder: false },
      })
      dispatch({
        type: GET_COMBINED_PAYMENT,
        payload: { loadingGetListCombinedPayment: false },
      })
    }
  }

export const getListCombinedPayment =
  (listCombinedPaymentRef, isEventShop = false) =>
  async (dispatch, getState) => {
    try {
      const {
        event: { listCombinedPayments = [] },
      } = getState()
      const newListCombinedPayment = []
      const listEid = new Set()

      const fetchCombinedPayment = async (combinedRef) => {
        const { msgResp } = await eventApi.listInvoicesCombinedPayment(combinedRef, isEventShop)
        const {
          orders = null,
          listShopEventOrder = null,
          deliveryBookings = null,
          listShopEventDeliveryBooking = null,
        } = msgResp

        const typeOrder = isEventShop ? listShopEventOrder : orders
        const orderCombinedPayment = typeOrder.map(({ ref, eid, eTitle }) => ({ ref, eid, eTitle }))

        const uniqueOrderCombinedPayment = orderCombinedPayment.filter(
          (x, index, self) => index === self.findIndex((t) => t.ref === x.ref && t.eid === x.eid)
        )

        orderCombinedPayment.forEach(({ eid }) => listEid.add(eid))

        return {
          combinedPaymentRef: combinedRef,
          orderCombinedPayment: uniqueOrderCombinedPayment,
          deliveryCombinedPayment: deliveryBookings || listShopEventDeliveryBooking,
        }
      }

      const newPayments = await Promise.all(
        listCombinedPaymentRef
          .filter(
            (combinedRef) =>
              !listCombinedPayments.some((item) => item.combinedPaymentRef === combinedRef) &&
              !newListCombinedPayment.some((item) => item.combinedPaymentRef === combinedRef)
          )
          .map(fetchCombinedPayment)
      )

      newListCombinedPayment.push(...newPayments)

      if (newListCombinedPayment.length) {
        dispatch({
          type: GET_COMBINED_PAYMENT,
          payload: {
            listCombinedPayments: [...listCombinedPayments, ...newListCombinedPayment],
          },
        })
        await dispatch(getListEventOrder([...listEid], isEventShop))
      }
    } catch (error) {
      console.error('Error fetching combined payment data:', error)
      dispatch({
        type: GET_COMBINED_PAYMENT,
        payload: {
          error: error.message || 'An error occurred while fetching combined payment data',
        },
      })
    }
  }

export const updateOrderCombinedPayment =
  (combinedPaymentRef, status, buyerStatus) => async (dispatch, getState) => {
    try {
      const { event } = getState()
      const { listCombinedPayments = [], eventAndOrderById = [] } = event

      const exitOrderCombinedPayment = listCombinedPayments.findIndex(
        (item) => item.combinedPaymentRef === combinedPaymentRef
      )

      let orderCombinedPayment = []
      if (exitOrderCombinedPayment !== -1) {
        // eslint-disable-next-line prefer-destructuring
        orderCombinedPayment = listCombinedPayments[exitOrderCombinedPayment].orderCombinedPayment
        // get EventAndOrder relate
        orderCombinedPayment.forEach(async (element) => {
          const { eid, ref } = element
          const exitEvenAndOrder = eventAndOrderById.findIndex((item) => item.id === eid)

          if (exitEvenAndOrder !== -1) {
            const updatedOrders = eventAndOrderById[exitEvenAndOrder].orders.map((order) =>
              order.ref === ref ? { ...order, status, buyerStatus } : order
            )

            eventAndOrderById[exitEvenAndOrder] = {
              ...eventAndOrderById[exitEvenAndOrder],
              orders: updatedOrders,
            }
          }
        })
      }
      dispatch(clearDataPurchaseDetail())
      dispatch(clearMyOrder())
      dispatch(clearDeliveryDetail())
      dispatch(clearPastPurchasedMember())
      dispatch({
        type: UPDATE_STATUS_ORDER,
        payload: {
          eventAndOrderById,
          callNewAPIListOrder: true,
        },
      })
    } catch (e) {}
  }

export const clearListCombinedPayment = () => (dispatch) => {
  try {
    dispatch({
      type: GET_COMBINED_PAYMENT,
      payload: { listCombinedPayments: [] },
    })
  } catch (e) {}
}

export const getEventById = (eventId) => async (dispatch, getState) => {
  try {
    const { event } = getState()
    const { myEvents } = event
    let eventNeed = {}
    const indexInMyEvents = myEvents?.filter((item) => item.id === eventId)
    if (indexInMyEvents) {
      eventNeed = indexInMyEvents[0]
    } else {
      await eventApi.getEventById(eventId).then(({ msgResp }) => {
        eventNeed = msgResp
      })
    }
    dispatch({
      type: GET_EVENT_BY_ID,
      payload: { eventById: eventNeed },
    })
  } catch (error) {}
}

export const createEvent = (data) => async (dispatch, getState) => {
  try {
    const { event } = getState()
    const { myEvents, allEvents } = event
    const newMyEvents = myEvents
    const newAllEvents = allEvents
    const { msgResp } = await orderApi.getListOrdersByEventId(data.id)
    const newEvent = {
      ...data,
      orders: msgResp,
    }
    newMyEvents.unshift(newEvent)
    newAllEvents.unshift(newEvent)
    dispatch({
      type: CREATE_EVENT,
      payload: { allEvents: [...newAllEvents], myEvents: [...newMyEvents] },
    })
  } catch (error) {}
}

export const updateEvent = (data) => (dispatch, getState) => {
  try {
    const { event } = getState()
    const { myEvents, allEvents } = event
    // await eventApi.updateEvent(data)
    const newMyEvents = myEvents
    const newAllEvents = allEvents

    const indexInMyEvents = newMyEvents.findIndex((item) => item.id === data.id)
    const indexInAllEvent = newAllEvents.findIndex((item) => item.id === data.id)

    if (indexInMyEvents !== -1) {
      newMyEvents[indexInMyEvents] = { ...newMyEvents[indexInMyEvents], ...data }
    } else {
      newMyEvents.push(data)
    }

    if (indexInAllEvent !== -1) {
      newAllEvents[indexInAllEvent] = { ...newAllEvents[indexInAllEvent], ...data }
    } else {
      newAllEvents.push(data)
    }
    dispatch({
      type: UPDATE_EVENT,
      payload: { myEvents: [...newMyEvents] },
    })
  } catch (error) {}
}
export const updateStatusEvent = (eventId, status) => (dispatch, getState) => {
  const { event } = getState()
  const { eventAndOrderById, myEvents, myShopEvents } = event

  const updatedEventAndOrderById = eventAndOrderById.map((item) => {
    if (item.id !== eventId) return item
    return { ...item, status }
  })

  const updatedMyEvents = myEvents.map((item) => {
    if (item.id !== eventId) return item
    return { ...item, status }
  })

  const updatedMyShopEvents = myShopEvents.map((item) => {
    if (item.id !== eventId) return item

    return { ...item, status }
  })

  dispatch({
    type: UPDATE_STATUS_EVENT,
    payload: {
      eventAndOrderById: updatedEventAndOrderById,
      myEvents: updatedMyEvents,
      myShopEvents: updatedMyShopEvents,
    },
  })
  dispatch({
    type: UPDATE_STATUS_EVENT,
    payload: { allEvents: [] },
  })
}

export const updatePayableStatus = (eventId, status) => (dispatch, getState) => {
  const { event } = getState()
  const { eventAndOrderById } = event

  const updatedEventAndOrderById = eventAndOrderById.map((item) => {
    if (item.id !== eventId) return item

    return { ...item, payable: status }
  })

  dispatch({
    type: UPDATE_PAYABLE_STATUS,
    payload: {
      eventAndOrderById: updatedEventAndOrderById,
    },
  })
}

export const deleteEvent =
  (id, isEventShop = false) =>
  async (dispatch, getState) => {
    try {
      const { event } = getState()
      const { myEvents, allEvents, eventAndOrderById, myShopEvents } = event

      const newMyShopEvents = myShopEvents
      const newMyEvents = myEvents
      const newAllEvents = allEvents
      const newEventAndOrderById = eventAndOrderById

      const indexInMyShopEvents = newMyShopEvents.findIndex((item) => item.id === id)
      const indexInMyEvents = newMyEvents.findIndex((item) => item.id === id)
      const indexInAllEvent = newAllEvents.findIndex((item) => item.id === id)
      const indexInEventAndOrderById = newEventAndOrderById.findIndex((item) => item.id === id)

      if (isEventShop && indexInMyShopEvents !== -1) {
        newMyShopEvents.splice(indexInMyShopEvents, 1)
      }

      if (!isEventShop && indexInMyEvents !== -1) {
        newMyEvents.splice(indexInMyEvents, 1)
      }

      if (indexInAllEvent !== -1) {
        newAllEvents.splice(indexInAllEvent, 1)
      }
      if (indexInEventAndOrderById !== -1) {
        newEventAndOrderById.splice(indexInEventAndOrderById, 1)
      }

      dispatch(clearPastPurchasedMember())
      dispatch({
        type: DELETE_EVENT,
        payload: {
          ...(isEventShop
            ? { myShopEvents: [...newMyShopEvents] }
            : { myEvents: [...newMyEvents] }),
          allEvents: [...newAllEvents],
          eventAndOrderById: [...newEventAndOrderById],
        },
      })
    } catch (error) {}
  }

export const getEventsClosePerMonthByHost = (data, groupId) => async (dispatch) => {
  let listEventsClosePerMonthByHost = []

  try {
    const response = await eventApi.getEventsClosePerMonthByHost(data, groupId)
    if (response?.msgCode) {
      listEventsClosePerMonthByHost = response.msgResp
    }
    dispatch({
      type: GET_EVENTS_CLOSE_PER_MONTH_BY_HOST,
      payload: {
        listEventsClosePerMonthByHost,
      },
    })
  } catch (error) {}
}

export const getEventsCollectionPerMonthByHost = (data, groupId) => async (dispatch) => {
  let listEventsCollectionPerMonthByHost = []

  try {
    const response = await eventApi.getEventsCollectionPerMonthByHost(data, groupId)
    if (response?.msgCode) {
      listEventsCollectionPerMonthByHost = response.msgResp
    }
    dispatch({
      type: GET_EVENTS_CLOSE_PER_MONTH_BY_HOST,
      payload: {
        listEventsCollectionPerMonthByHost,
      },
    })
  } catch (error) {}
}

export const addEventClosePerMonthByHost = (data) => async (dispatch, getState) => {
  const { event } = getState()
  const { listEventsClosePerMonthByHost = [] } = event
  const newListEventsClosePerMonthByHost = [...listEventsClosePerMonthByHost, data]

  dispatch({
    type: ADD_EVENT_CLOSE_PER_MONTH_BY_HOST,
    payload: {
      listEventsClosePerMonthByHost: newListEventsClosePerMonthByHost,
    },
  })
}

export const addEventCollectionPerMonthByHost = (data) => async (dispatch, getState) => {
  const { event } = getState()
  const { listEventsCollectionPerMonthByHost = [] } = event
  const newListEventsCollectionPerMonthByHost = [...listEventsCollectionPerMonthByHost, data]

  dispatch({
    type: ADD_EVENT_COLLECTION_PER_MONTH_BY_HOST,
    payload: {
      listEventsCollectionPerMonthByHost: newListEventsCollectionPerMonthByHost,
    },
  })
}

export const updateEventCollectionPerMonthByHost = (data) => async (dispatch, getState) => {
  const { event } = getState()
  const { listEventsCollectionPerMonthByHost = [] } = event

  const newListEventsCollectionPerMonthByHost = listEventsCollectionPerMonthByHost.map((item) =>
    item.id === data.id ? data : item
  )

  dispatch({
    type: UPDATE_EVENT_COLLECTION_PER_MONTH_BY_HOST,
    payload: {
      listEventsCollectionPerMonthByHost: newListEventsCollectionPerMonthByHost,
    },
  })
}

export const updateEventClosePerMonthByHost = (data) => async (dispatch, getState) => {
  const { event } = getState()
  const { listEventsClosePerMonthByHost = [] } = event

  const newListEventsClosePerMonthByHost = listEventsClosePerMonthByHost.map((item) =>
    item.id === data.id ? data : item
  )

  dispatch({
    type: UPDATE_EVENT_CLOSE_PER_MONTH_BY_HOST,
    payload: {
      listEventsClosePerMonthByHost: newListEventsClosePerMonthByHost,
    },
  })
}

export const removeEventClosePerMonthByHost = (eid) => async (dispatch, getState) => {
  const { event } = getState()
  const { listEventsClosePerMonthByHost = [] } = event
  const newListEventsClosePerMonthByHost = listEventsClosePerMonthByHost.filter(
    (item) => item.id !== eid
  )

  dispatch({
    type: REMOVE_EVENT_CLOSE_PER_MONTH_BY_HOST,
    payload: {
      listEventsClosePerMonthByHost: newListEventsClosePerMonthByHost,
    },
  })
}

export const removeEventCollectionPerMonthByHost = (eid) => async (dispatch, getState) => {
  const { event } = getState()
  const { listEventsCollectionPerMonthByHost = [] } = event
  const newListEventsCollectionPerMonthByHost = listEventsCollectionPerMonthByHost.filter(
    (item) => item.id !== eid
  )

  dispatch({
    type: REMOVE_EVENT_COLLECTION_PER_MONTH_BY_HOST,
    payload: {
      listEventsCollectionPerMonthByHost: newListEventsCollectionPerMonthByHost,
    },
  })
}

export const getAllEventsByGroup = () => async (dispatch) => {
  try {
    const { msgResp } = await eventApi.getAllEventsByGroup()
    dispatch({
      type: GET_ALL_EVENTS_BY_GROUP,
      payload: {
        allEvents: msgResp,
      },
    })
  } catch (error) {
    throw new Error(error.message || error.msgResp)
  }
}

export const updateDeliveryCommentDesktop = (ref, comment) => async (dispatch, getState) => {
  const { event } = getState()
  const { listEventAndDelivery } = event
  const updatedListDelivery = listEventAndDelivery?.deliveryBookings?.map((item) => {
    if (item.ref === ref) {
      return { ...item, comment }
    }
    return item
  })

  const newListEventAndDelivery = {
    ...listEventAndDelivery,
    deliveryBookings: updatedListDelivery,
  }

  dispatch({
    type: UPDATE_DELIVERY_COMMENT_DESKTOP,
    payload: {
      listEventAndDelivery: newListEventAndDelivery,
    },
  })
}

const event = (state = initialState, action) => {
  switch (action.type) {
    case GET_LIST_EVENTS: {
      return {
        ...state,
        [DEFAULT_KEY]: generateCacheTTL(),
        ...action.payload,
      }
    }
    case UPDATE_DELIVERY_COMMENT_DESKTOP: {
      return {
        ...state,
        ...action.payload,
      }
    }
    case GET_MY_EVENTS: {
      return {
        ...state,
        ...action.payload,
      }
    }
    case GET_LIST_EVENT_GROUP_ID_BY_HOST: {
      return {
        ...state,
        ...action.payload,
      }
    }
    case GET_LIST_SCHEDULED_EVENTS: {
      return {
        ...state,
        ...action.payload,
      }
    }
    case GET_LIST_SCHEDULED_EVENTS_ERROR: {
      return {
        ...state,
        ...action.payload,
      }
    }
    case DELETE_LIST_SCHEDULED_EVENTS: {
      return {
        ...state,
        ...action.payload,
      }
    }
    case GET_EVENT_AND_ORDER_BUY_EID: {
      return {
        ...state,
        ...action.payload,
      }
    }
    case GET_EVENT_BY_ID: {
      return {
        ...state,
        ...action.payload,
      }
    }
    case GET_SHARE_EVENT_CODE: {
      return {
        ...state,
        ...action.payload,
      }
    }
    case UPDATE_STATUS_ORDER: {
      return {
        ...state,
        ...action.payload,
      }
    }
    case CREATE_EVENT: {
      return {
        ...state,
        ...action.payload,
      }
    }
    case UPDATE_EVENT: {
      return {
        ...state,
        ...action.payload,
      }
    }
    case CLEAR_EVENT: {
      return {
        ...state,
        newGroup: null,
      }
    }
    case DELETE_EVENT: {
      return {
        ...state,
        ...action.payload,
      }
    }
    case UPDATE_EVENT_ORDER: {
      return {
        ...state,
        ...action.payload,
      }
    }
    case CLEAR_EVENT_ORDER: {
      return {
        ...state,
        ...action.payload,
      }
    }
    case CLEAR_MY_EVENTS: {
      return {
        ...state,
        ...action.payload,
      }
    }
    case CLEAR_ALL_EVENTS: {
      return {
        ...state,
        ...action.payload,
      }
    }
    case GET_ALL_EVENTS_OPENING_REQUEST: {
      return {
        ...state,
        ...action.payload,
      }
    }
    case GET_ALL_EVENTS_OPENING_SUCCESS: {
      return {
        ...state,
        ...action.payload,
      }
    }
    case GET_ALL_EVENTS_OPENING_FAIL: {
      return {
        ...state,
        ...action.payload,
      }
    }
    case UPDATE_TOTAL_ORDER_COUNT_WHEN_ORDER: {
      return {
        ...state,
        ...action.payload,
      }
    }
    case UPDATE_TOTAL_ORDER_COUNT_WHEN_CANCEL_ORDER: {
      return {
        ...state,
        ...action.payload,
      }
    }
    case UPDATE_TOTAL_ORDER_COUNT_WHEN_UPDATE_ORDER: {
      return {
        ...state,
        ...action.payload,
      }
    }
    case UPDATE_STATUS_EVENT: {
      return {
        ...state,
        ...action.payload,
      }
    }
    case GET_COMBINED_PAYMENT: {
      return {
        ...state,
        ...action.payload,
      }
    }
    case GET_EVENTS_CLOSE_PER_MONTH_BY_HOST: {
      return {
        ...state,
        ...action.payload,
      }
    }
    case GET_EVENTS_COLLECTION_PER_MONTH_BY_HOST: {
      return {
        ...state,
        ...action.payload,
      }
    }
    case GET_LIST_EVENT_AND_DELIVERY: {
      return {
        ...state,
        ...action.payload,
      }
    }
    case GET_LIST_SCHEDULED_EVENTS_BY_GROUP_ID: {
      return {
        ...state,
        ...action.payload,
      }
    }
    case GET_LIST_EVENTS_UN_AUTH: {
      return {
        ...state,
        ...action.payload,
      }
    }
    case GET_ALL_EVENTS_BY_GROUP: {
      return {
        ...state,
        ...action.payload,
      }
    }
    case CLEAR_ALL_EVENTS_OPENING: {
      return {
        ...state,
        ...action.payload,
      }
    }
    case GET_LIST_EVENT_PUBLIC: {
      return {
        ...state,
        ...action.payload,
      }
    }
    case PAYMENT_SCHEDULE_EVENT: {
      return {
        ...state,
        ...action.payload,
      }
    }
    case UPDATE_DELIVERY_PAYMENT_STATUS: {
      return {
        ...state,
        ...action.payload,
      }
    }
    case GET_MY_SHOP_EVENTS: {
      return {
        ...state,
        ...action.payload,
      }
    }
    case SET_INITIAL_DATA_LOADED: {
      return {
        ...state,
        ...action.payload,
      }
    }

    case 'SET_SELECTED_ENTITY': {
      return {
        ...state,
        ...action.payload,
      }
    }

    case UPDATE_PAYABLE_STATUS: {
      return {
        ...state,
        ...action.payload,
      }
    }

    case SET_LOADING_GET_EVENT_GROUP_ID: {
      return {
        ...state,
        ...action.payload,
      }
    }

    case SET_LOADING_GET_EVENT_AND_ORDER: {
      return {
        ...state,
        ...action.payload,
      }
    }
    case ADD_EVENT_CLOSE_PER_MONTH_BY_HOST: {
      return {
        ...state,
        ...action.payload,
      }
    }
    case ADD_EVENT_COLLECTION_PER_MONTH_BY_HOST: {
      return {
        ...state,
        ...action.payload,
      }
    }
    case REMOVE_EVENT_CLOSE_PER_MONTH_BY_HOST: {
      return {
        ...state,
        ...action.payload,
      }
    }
    case REMOVE_EVENT_COLLECTION_PER_MONTH_BY_HOST: {
      return {
        ...state,
        ...action.payload,
      }
    }
    case UPDATE_EVENT_CLOSE_PER_MONTH_BY_HOST: {
      return {
        ...state,
        ...action.payload,
      }
    }
    case UPDATE_EVENT_COLLECTION_PER_MONTH_BY_HOST: {
      return {
        ...state,
        ...action.payload,
      }
    }
    case LOADING_GET_EVENT_AND_ORDER_END:
      return {
        ...state,
        loadingGetEventAndOrder: false,
      }
    default:
      return state
  }
}

export default event
