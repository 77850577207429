import eventApi from '_api/event'
import groupApi from '_api/group'
import { getUserInfo, setUserInfo } from '_utils/localData'
import { DEFAULT_KEY, checkCacheValid, generateCacheTTL } from 'redux-cache'

import {
  BUYER_ORDER_CANCELED,
  DONT_EXIST_IN_ARRAY,
  GROUP_APPROVED,
  GROUP_BANNED,
  GROUP_DONT_BAN,
  GROUP_IS_ADMIN,
  GROUP_IS_HOST,
  GROUP_IS_NOT_ADMIN,
  GROUP_IS_NOT_HOST,
  GROUP_REQUESTING_APPROVAL,
  HOST_ORDER_CANCELED,
} from '_utils/constant'

const CREATE_GROUP = 'CREATE_GROUP'
const UPDATE_GROUP = 'UPDATE_GROUP'
const GET_MY_GROUPS = 'GET_MY_GROUPS'
const GET_ALL_GROUPS = 'GET_ALL_GROUPS'
const GET_GROUP_BY_ID = 'GET_GROUP_BY_ID'
const GET_GROUP_BY_ID_WITH_EVENTS = 'GET_GROUP_BY_ID_WITH_EVENTS'
const GET_GROUP_BY_ID_WITH_MEMBERS = 'GET_GROUP_BY_ID_WITH_MEMBERS'
const GET_PAST_PURCHASED_MEMBER = 'GET_PAST_PURCHASED_MEMBER'
const GET_PAST_PURCHASED_MEMBER_ERROR = 'GET_PAST_PURCHASED_MEMBER_ERROR'
const CLEAR_PAST_PURCHASED_MEMBER = 'CLEAR_PAST_PURCHASED_MEMBER'
const CLEAR_GROUP = 'CLEAR_GROUP'
const CREATED_GROUP = 'CREATED_GROUP'
const BAN_MEMBER = 'BAN_MEMBER'
const REMOVE_MEMBER = 'REMOVE_MEMBER'
const CHANGE_ROLE_OF_MEMBER_IN_GROUP = 'CHANGE_ROLE_OF_MEMBER_IN_GROUP'
const JOIN_GROUP = 'JOIN_GROUP'
const APPROVE_JOIN_GROUP = 'APPROVE_JOIN_GROUP'
const APPEND_NEW_EVENT_TO_GROUP = 'APPEND_NEW_EVENT_TO_GROUP'
const REMOVE_EVENT_IN_GROUP = 'REMOVE_EVENT_IN_GROUP'
const UPDATE_EVENT_IN_GROUP = 'UPDATE_EVENT_IN_GROUP'
const UPDATE_TOTAL_ORDER_COUNT = 'UPDATE_TOTAL_ORDER_COUNT'
const UPDATE_TOTAL_ORDER_COUNT_IN_GROUP_WHEN_CANCEL_ORDER =
  'UPDATE_TOTAL_ORDER_COUNT_IN_GROUP_WHEN_CANCEL_ORDER'
const GET_GROUP_WITH_EVENT_BY_ID = 'GET_GROUP_WITH_EVENT_BY_ID'
const STORE_GROUP = 'STORE_GROUP'
const GET_GROUP_FROM_STORE = 'GET_GROUP_FROM_STORE'
// const PAYMENT_CREATE_GROUP = 'PAYMENT_CREATE_GROUP'
const CLEAR_GROUP_BY_ID = 'CLEAR_GROUP_BY_ID'

const initialState = {
  [DEFAULT_KEY]: null,
  allGroups: null,
  myGroups: null,
  newGroup: null,
  pastPurchasedMember: [],
  pastPurchasedMemberError: null,
  groupById: null,
  groupStored: null,
}

export const clearGroupDetailById = () => (dispatch) => {
  dispatch({
    type: CLEAR_GROUP_BY_ID,
    payload: { groupById: null },
  })
}

export const getGroupDetailById = (groupId) => async (dispatch) => {
  try {
    const { msgResp } = await groupApi.getById(groupId)
    dispatch({
      type: GET_GROUP_BY_ID,
      payload: { groupById: msgResp },
    })
  } catch (error) {
    throw new Error(error.msgResp || error.message)
  }
}

const onGetMyGroups = async (dispatch) => {
  try {
    const response = await groupApi.getGroupWithUser()
    if (response?.msgResp?.groups) {
      // @note get list my group joined
      let listMyGroups = response.msgResp.groups.filter(
        (group) => group.approval === GROUP_APPROVED
      )

      listMyGroups = listMyGroups.map((i) => ({
        ...i,
        id: i.gid,
      }))

      dispatch({
        type: GET_MY_GROUPS,
        payload: { myGroups: listMyGroups },
      })
    }
  } catch (error) {
    throw new Error(error.msgResp)
  }
}

export const getMyGroups = async (dispatch, getState) => {
  const isCacheValid = checkCacheValid(getState, 'group')

  if (isCacheValid) {
    return null
  }

  await onGetMyGroups(dispatch)
}

export const getGroupById = (groupId) => async (dispatch, getState) => {
  try {
    const { group } = getState()
    const { myGroups } = group
    const { msgResp: newGroup } = await groupApi.getById(groupId)
    let newMyGroups = []
    // newGroup.events = listEvent
    if (myGroups !== null) {
      newMyGroups = myGroups
      const indexInMyGroup = newMyGroups.findIndex((item) => item.id === groupId)
      newMyGroups[indexInMyGroup] = newGroup
    } else {
      newMyGroups.push(newGroup)
    }
    dispatch({ type: GET_GROUP_BY_ID, payload: { myGroups: newMyGroups } })
  } catch (error) {}
}

export const getGroupWithEventById = (groupId, date) => async (dispatch, getState) => {
  try {
    const { group } = getState()
    let { myGroups } = group
    const index = myGroups?.findIndex((i) => i.gid === groupId) || -1

    if (index !== -1 && myGroups && myGroups[index]?.events?.length) {
      dispatch({
        type: GET_GROUP_WITH_EVENT_BY_ID,
        payload: { groupById: myGroups[index] },
      })
      return
    }
    if (index === -1 && !myGroups) {
      const response = await groupApi.getGroupWithUser()
      if (response?.msgResp?.groups) {
        // @note get list my group joined
        myGroups = response.msgResp.groups.filter((item) => item.approval === GROUP_APPROVED)

        myGroups = myGroups.map((i) => ({
          ...i,
          id: i.gid,
        }))

        dispatch({
          type: GET_MY_GROUPS,
          payload: [...myGroups],
        })
      }
    }
    const { msgResp: listEvent } = await eventApi.getListEventsByGroupId(groupId, date)
    if (index !== -1) {
      myGroups[index].events = listEvent
      dispatch({
        type: GET_GROUP_WITH_EVENT_BY_ID,
        payload: { groupById: { ...myGroups[index] }, myGroups: [...myGroups] },
      })
    } else {
      const groupById = myGroups.filter((item) => item.gid === groupId)[0]
      groupById.events = listEvent
      dispatch({
        type: GET_GROUP_WITH_EVENT_BY_ID,
        payload: { groupById },
      })
    }
  } catch (error) {}
}

export const getGroupByIdWithMembers = (data) => async (dispatch, getState) => {
  try {
    const { group } = getState()
    const { myGroups } = group
    const indexInMyGroup = myGroups?.findIndex((_group) => _group.id === data.id)

    // @note return when the group does not exist
    if (indexInMyGroup === DONT_EXIST_IN_ARRAY) {
      return
    }

    let newMyGroups = []
    let memberResp = []

    if (myGroups !== null) {
      // // @note return when the group already has the event list before dispatch
      // if (myGroups[indexInMyGroup]?.members) {
      //   return
      // }

      const { msgResp } = await groupApi.getGroupWithMembers({ id: data.id })
      memberResp = msgResp.members
      newMyGroups = myGroups
      newMyGroups[indexInMyGroup].members = memberResp
    } else {
      const { msgResp } = await groupApi.getGroupWithMembers({ id: data.id })
      memberResp = msgResp.members
      const { msgResp: newGroup } = await groupApi.getById(data.id)
      newGroup.members = memberResp
      newMyGroups.push({ ...newGroup, gid: data.id })
    }

    dispatch({ type: GET_GROUP_BY_ID_WITH_MEMBERS, payload: { myGroups: newMyGroups } })
  } catch (error) {
    throw new Error(error.message || error.msgResp)
  }
}

export const getAllGroups = () => async (dispatch, getState) => {
  try {
    const { group } = getState()
    let myGroups = []

    // @note return when the redux has  data
    if (group.allGroups !== null) return

    if (group.myGroups === null) {
      const { msgResp: response } = await groupApi.getGroupWithUser()
      myGroups = response.groups
    } else {
      const { myGroups: data } = group
      myGroups = data
    }

    const { msgResp: allGroupsResp } = await groupApi.getListGroup()
    const listGroupsNotJoin = allGroupsResp.filter(
      (x) => myGroups.findIndex((y) => y.gid === x.id) === DONT_EXIST_IN_ARRAY
    )

    const myGroupsDontApproval = myGroups.filter((_group) => _group.approval !== GROUP_APPROVED)

    dispatch({
      type: GET_ALL_GROUPS,
      payload: { allGroups: [...listGroupsNotJoin, ...myGroupsDontApproval] },
    })
  } catch (error) {
    throw new Error(error.message || error.msgResp)
  }
}

export const createGroup = (data) => async (dispatch, getState) => {
  try {
    const { group } = getState()
    let { myGroups } = group

    const currentUser = getUserInfo()
    const newUserGroups = currentUser?.groups || []

    const { groupType, packageId, startDate, packageType, ...restData } = data
    const response = await groupApi.create(restData)

    const newGroup = {
      ...response.msgResp,
      gid: response.msgResp.id,
      isHost: GROUP_IS_HOST,
      isAdmin: GROUP_IS_ADMIN,
      totalEvents: 0,
      totalMembers: 1,
    }
    if (!myGroups) {
      myGroups = [newGroup]
    } else {
      myGroups.push(newGroup)
    }

    setUserInfo({
      ...currentUser,
      groups: [
        ...newUserGroups,
        {
          ...response?.msgResp,
          gid: response.msgResp.id,
          isAdmin: GROUP_IS_ADMIN,
          isHost: GROUP_IS_HOST,
        },
      ],
    })

    dispatch({
      type: CREATE_GROUP,
      payload: { myGroups: Array.isArray(myGroups) ? [...myGroups] : [] },
    })
  } catch (error) {
    throw new Error(error.message || error.msgResp)
  }
}

export const updateGroup = (data) => async (dispatch, getState) => {
  const { group } = getState()

  try {
    await groupApi.updateGroup({
      ...data,
      id: data.id,
    })
  } catch (error) {
    throw new Error(error?.msgResp || error.message)
  }

  const { myGroups } = group
  const newMyGroups = [...myGroups]
  const indexInMyGroup = myGroups?.findIndex((item) => item.id === data.id)

  if (indexInMyGroup !== DONT_EXIST_IN_ARRAY) {
    const newGroup = { ...newMyGroups[indexInMyGroup], ...data.postData }
    newMyGroups[indexInMyGroup] = newGroup
    dispatch({
      type: UPDATE_GROUP,
      payload: { myGroups: newMyGroups },
    })
  }
}

export const updateGroupById = (data) => async (dispatch, getState) => {
  const { group } = getState()
  const { myGroups } = group
  const newMyGroups = myGroups
  const indexInMyGroup = myGroups?.findIndex((item) => item.id === data.id)
  if (indexInMyGroup !== DONT_EXIST_IN_ARRAY) {
    const newGroup = { ...newMyGroups[indexInMyGroup], ...data }
    newMyGroups[indexInMyGroup] = newGroup
    dispatch({
      type: UPDATE_GROUP,
      payload: { myGroups: newMyGroups },
    })
  }
}

export const banMember = (data) => async (dispatch, getState) => {
  try {
    const { group } = getState()
    const { myGroups } = group
    const { userId, banned, groupId } = data
    const indexInMyGroup = myGroups?.findIndex((_group) => _group.id === groupId)
    const indexMem = myGroups[indexInMyGroup]?.members.findIndex((mem) => mem.uid === userId)
    // @note return when the group or user does not exist

    if (indexInMyGroup === DONT_EXIST_IN_ARRAY || indexMem === DONT_EXIST_IN_ARRAY) return

    let newMyGroups = []
    let memberResp = []

    // @note check the existence of group and members
    if (myGroups !== null && myGroups[indexInMyGroup]?.members) {
      newMyGroups = myGroups
    } else {
      const { msgResp } = await groupApi.getGroupWithMembers({ id: groupId })
      memberResp = msgResp.members
      const { msgResp: newGroup } = await groupApi.getById(groupId)
      newGroup.members = memberResp
      newMyGroups.push(newGroup)
    }

    if (banned === GROUP_DONT_BAN) {
      newMyGroups[indexInMyGroup].members[indexMem].banned = GROUP_BANNED
    } else {
      newMyGroups[indexInMyGroup].members[indexMem].banned = GROUP_DONT_BAN
    }

    await groupApi.banMember({
      ...data,
      banned: banned === GROUP_DONT_BAN ? GROUP_BANNED : GROUP_DONT_BAN,
    })
    dispatch({ type: BAN_MEMBER, payload: { myGroups: newMyGroups } })
  } catch (error) {
    throw new Error(error.msgResp)
  }
}

export const removeMember = (data) => async (dispatch, getState) => {
  try {
    const { group } = getState()
    const { myGroups } = group
    const { userId, groupId } = data
    const indexInMyGroup = myGroups?.findIndex((_group) => _group.id === groupId)
    const indexMem = myGroups[indexInMyGroup]?.members.findIndex((mem) => mem.uid === userId)

    // @note return when the group or user does not exist
    if (indexInMyGroup === DONT_EXIST_IN_ARRAY || indexMem === DONT_EXIST_IN_ARRAY) return

    let newMyGroups = []
    let memberResp = []

    // @note check the existence of group and members
    if (myGroups !== null && myGroups[indexInMyGroup]?.members) {
      newMyGroups = myGroups
    } else {
      const { msgResp } = await groupApi.getGroupWithMembers({ id: groupId })
      memberResp = msgResp.members
      const { msgResp: newGroup } = await groupApi.getById(groupId)
      newGroup.members = memberResp
      newMyGroups.push(newGroup)
    }

    const arr1 = newMyGroups[indexInMyGroup].members.slice(
      indexMem + 1,
      newMyGroups[indexInMyGroup].members.length
    )
    const arr2 = newMyGroups[indexInMyGroup].members.slice(0, indexMem)
    newMyGroups[indexInMyGroup].members = [...arr1, ...arr2]
    newMyGroups[indexInMyGroup].totalMembers -= 1

    await groupApi.removeMember(data)
    dispatch({ type: REMOVE_MEMBER, payload: { myGroups: newMyGroups } })
  } catch (error) {
    throw new Error(error.msgResp)
  }
}

export const changeMemberRole = (data) => async (dispatch, getState) => {
  try {
    const { group } = getState()
    const { myGroups } = group
    const { userId, groupId, role } = data
    const indexInMyGroup = myGroups?.findIndex((_group) => _group.id === groupId)
    const indexMem = myGroups[indexInMyGroup]?.members.findIndex((mem) => mem.uid === userId)

    // @note return when the group or user does not exist
    if (indexInMyGroup === DONT_EXIST_IN_ARRAY || indexMem === DONT_EXIST_IN_ARRAY) return

    let newMyGroups = []
    let memberResp = []

    // @note check the existence of group and members
    if (myGroups !== null && myGroups[indexInMyGroup]?.members) {
      newMyGroups = myGroups
    } else {
      const { msgResp } = await groupApi.getGroupWithMembers({ id: groupId })
      memberResp = msgResp.members
      const { msgResp: newGroup } = await groupApi.getById(groupId)
      newGroup.members = memberResp
      newMyGroups.push(newGroup)
    }

    await groupApi.changeGroupRole(data)
    if (role === 'ADMIN') {
      newMyGroups[indexInMyGroup].members[indexMem].isAdmin = GROUP_IS_ADMIN
      newMyGroups[indexInMyGroup].members[indexMem].isHost = GROUP_IS_HOST
    } else if (role === 'HOST') {
      newMyGroups[indexInMyGroup].members[indexMem].isAdmin = GROUP_IS_NOT_ADMIN
      newMyGroups[indexInMyGroup].members[indexMem].isHost = GROUP_IS_HOST
    } else {
      newMyGroups[indexInMyGroup].members[indexMem].isAdmin = GROUP_IS_NOT_ADMIN
      newMyGroups[indexInMyGroup].members[indexMem].isHost = GROUP_IS_NOT_HOST
    }

    dispatch({ type: CHANGE_ROLE_OF_MEMBER_IN_GROUP, payload: { myGroups: newMyGroups } })
  } catch (error) {
    throw new Error(error.msgResp)
  }
}

export const joinGroup = (data) => async (dispatch, getState) => {
  try {
    const { group } = getState()
    const { allGroups } = group
    const indexInAllGroups = allGroups.findIndex((_group) => _group.id === data.groupId)

    // @note return when the group does not exist
    if (indexInAllGroups === DONT_EXIST_IN_ARRAY) return

    await groupApi.joinGroup(data)
    const newAllGroups = allGroups
    newAllGroups[indexInAllGroups].request = GROUP_REQUESTING_APPROVAL
    dispatch({ type: JOIN_GROUP, payload: { allGroups: newAllGroups } })
  } catch (error) {}
}

export const joinGroupWithInvitation = (data) => async (dispatch) => {
  try {
    await groupApi.joinGroup(data)
    await onGetMyGroups(dispatch)
  } catch (error) {
    throw new Error(error.msgResp)
  }
}

export const approveJoinGroup = (data) => async (dispatch, getState) => {
  try {
    const { group } = getState()
    const { myGroups } = group
    const { groupId, userId } = data
    const indexInMyGroup = myGroups.findIndex((_group) => _group.id === groupId)
    const indexMem = myGroups[indexInMyGroup]?.members.findIndex((mem) => mem.uid === userId)

    // @note return when the group does not exist
    if (indexInMyGroup === DONT_EXIST_IN_ARRAY || indexMem === DONT_EXIST_IN_ARRAY) return

    await groupApi.approveGroupJoin(data)
    let newMyGroups = []
    let memberResp = []

    // @note check the existence of group and members
    if (myGroups !== null && myGroups[indexInMyGroup]?.members) {
      newMyGroups = myGroups
    } else {
      const { msgResp } = await groupApi.getGroupWithMembers({ id: groupId })
      memberResp = msgResp.members
      const { msgResp: newGroup } = await groupApi.getById(groupId)
      newGroup.members = memberResp
      newMyGroups.push(newGroup)
    }

    newMyGroups[indexInMyGroup].members[indexMem].approval = GROUP_APPROVED

    dispatch({ type: JOIN_GROUP, payload: { myGroups: newMyGroups } })
  } catch (error) {
    throw new Error(error.msgResp)
  }
}

export const appendNewEventToGroup = (data) => async (dispatch, getState) => {
  const { group } = getState()
  const { myGroups } = group
  const { groupId } = data
  const index = myGroups?.findIndex((item) => item.gid === groupId) || -1
  if (index !== -1) {
    myGroups[index]?.events?.push(data)
    myGroups[index].totalEvents += 1
    dispatch({ type: APPEND_NEW_EVENT_TO_GROUP, payload: { myGroups } })
  }
}

export const updateEventInGroup = (data) => async (dispatch, getState) => {
  const { group } = getState()
  const { myGroups } = group
  const { groupId, id } = data
  const index = myGroups?.findIndex((item) => item.gid === groupId) || -1
  if (index !== -1) {
    const eventIndex = myGroups[index]?.events?.findIndex((k) => k.id === id) || -1
    if (eventIndex !== -1) {
      myGroups[index].events[eventIndex] = data
      dispatch({ type: UPDATE_EVENT_IN_GROUP, payload: { myGroups } })
    }
  }
}

export const removeEventInGroup = (data) => async (dispatch, getState) => {
  const { group } = getState()
  const { myGroups } = group
  const { groupId, id } = data
  const index = myGroups?.findIndex((item) => item.gid === groupId) || -1
  if (index !== undefined && index !== -1) {
    const eventIndex = myGroups[index]?.events?.findIndex((k) => k.id === id) || -1
    myGroups[index]?.events?.splice(eventIndex, 1)
    myGroups[index].totalEvents -= 1
    dispatch({ type: REMOVE_EVENT_IN_GROUP, payload: { myGroups: [...myGroups] } })
  }
}

export const updateTotalOrderCountInGroupWhenOrder = (data) => async (dispatch, getState) => {
  const { group, event } = getState()
  const { myGroups } = group
  const { allEvents } = event
  const { eid, details, representedUserId } = data

  const userId = representedUserId || getUserInfo().id

  if (!myGroups || !myGroups.length) {
    return
  }

  try {
    const index = allEvents.findIndex((item) => item.id === eid)

    if (index !== -1) {
      const detailEvent = allEvents[index]
      const { groupId } = detailEvent
      const indexInMyGroup = myGroups.findIndex((item) => item.id === groupId)
      const indexEventInMyGroup = myGroups[indexInMyGroup]?.events?.findIndex(
        (item) => item.id === eid
      )
      if (indexEventInMyGroup !== -1) {
        let count = 0
        for (const key in details) {
          if (Object.hasOwnProperty.call(details, key)) {
            count += details[key][0].quantity
          }
        }
        myGroups[indexInMyGroup].events[indexEventInMyGroup].totalOrderCount += count

        const indexUser = myGroups[indexInMyGroup]?.members?.findIndex(
          (item) => item.uid === userId
        )
        if (indexUser !== -1) {
          myGroups[indexInMyGroup].members[indexUser].orderCount += count
        }
        dispatch({ type: UPDATE_TOTAL_ORDER_COUNT, payload: { myGroups: [...myGroups] } })
      }
    }
  } catch (error) {}
}

export const updateTotalOrderCountInGroupWhenOrderWhenCancelOrder =
  (orderDetail) => async (dispatch, getState) => {
    const { group } = getState()
    const { myGroups } = group
    const { event: eventDetail, productOrder } = orderDetail

    if (!myGroups || !myGroups.length) {
      return
    }

    const indexInMyGroup = myGroups?.findIndex((item) => item.id === eventDetail.groupId)
    const indexEventInMyGroup = myGroups[indexInMyGroup]?.events?.findIndex(
      (item) => item.id === eventDetail.id
    )

    const count = productOrder.allProductPerOrder.reduce((a, b) => a + b.pQuantity, 0)

    if (indexEventInMyGroup !== -1) {
      myGroups[indexInMyGroup].events[indexEventInMyGroup].totalOrderCount -= count

      const indexUser = myGroups[indexInMyGroup]?.members?.findIndex(
        (item) => item.uid === productOrder.uid
      )
      if (indexUser !== -1 && myGroups[indexInMyGroup].members?.length) {
        myGroups[indexInMyGroup].members[indexUser].orderCount -= count
      }
      dispatch({
        type: UPDATE_TOTAL_ORDER_COUNT_IN_GROUP_WHEN_CANCEL_ORDER,
        payload: { myGroups: [...myGroups] },
      })
    }
  }

export const updateTotalOrderCountInGroupWhenOrderWhenUpdateOrder =
  (orderDetail) => async (dispatch, getState) => {
    const { group, event } = getState()
    const { myGroups } = group
    const { allEvents } = event
    const { eid, details, initTotalOrderCount } = orderDetail

    const userId = getUserInfo().id

    if (!myGroups || !myGroups.length) {
      return
    }

    let count = 0
    for (const key in details) {
      if (Object.hasOwnProperty.call(details, key)) {
        count += details[key][0].quantity
      }
    }

    count -= initTotalOrderCount
    const index = allEvents.findIndex((item) => item.id === eid)

    if (index !== -1) {
      const detailEvent = allEvents[index]
      const { groupId } = detailEvent
      const indexInMyGroup = myGroups.findIndex((item) => item.id === groupId)
      const indexEventInMyGroup = myGroups[indexInMyGroup]?.events?.findIndex(
        (item) => item.id === eid
      )
      if (indexEventInMyGroup !== -1) {
        myGroups[indexInMyGroup].events[indexEventInMyGroup].totalOrderCount += count

        const indexUser = myGroups[indexInMyGroup]?.members?.findIndex(
          (item) => item.uid === userId
        )
        if (indexUser !== -1 && myGroups[indexInMyGroup].members?.length) {
          myGroups[indexInMyGroup].members[indexUser].orderCount += count
        }
        dispatch({ type: UPDATE_TOTAL_ORDER_COUNT, payload: { myGroups: [...myGroups] } })
      }
    }
  }

export const getPastPurchasedMember = (userId, groupId) => async (dispatch, getState) => {
  try {
    const { group } = getState()
    const { pastPurchasedMember } = group
    const index = pastPurchasedMember.findIndex(
      (item) => item.userId === userId && item.groupId === groupId
    )
    if (index !== -1) {
      return null
    }
    let newPastPurchasedMember = {}
    const { msgResp: listOrdersByBuyerInGroup } = await groupApi.getListOrdersByBuyerInGroup(
      userId,
      groupId
    )
    const listOrdersByBuyerInGroupExcludeCancelled = listOrdersByBuyerInGroup.filter(
      (item) => item.status !== HOST_ORDER_CANCELED && item.buyerStatus !== BUYER_ORDER_CANCELED
    )
    newPastPurchasedMember = {
      listOrders: listOrdersByBuyerInGroupExcludeCancelled,
      userId,
      groupId,
    }
    dispatch({
      type: GET_PAST_PURCHASED_MEMBER,
      payload: { pastPurchasedMember: [...pastPurchasedMember, newPastPurchasedMember] },
    })
  } catch (e) {
    dispatch({
      type: GET_PAST_PURCHASED_MEMBER_ERROR,
      payload: { pastPurchasedMemberError: e?.msgResp },
    })
  }
}
export const clearPastPurchasedMember = () => async (dispatch) => {
  try {
    dispatch({
      type: CLEAR_PAST_PURCHASED_MEMBER,
      payload: { pastPurchasedMember: [] },
    })
  } catch (e) {}
}
export const clearGroup = () => ({
  type: CLEAR_GROUP,
})

export const storeGroup = (data) => (dispatch) => {
  localStorage.setItem(STORE_GROUP, JSON.stringify(data))
  dispatch({
    type: STORE_GROUP,
    payload: { groupStored: data },
  })
}

export const getGroupFromStore = () => (dispatch) => {
  const group = JSON.parse(localStorage.getItem(STORE_GROUP))

  if (group) {
    dispatch({
      type: GET_GROUP_FROM_STORE,
      payload: { groupStored: group },
    })
  }
}

const group = (state = initialState, action) => {
  switch (action.type) {
    case GET_GROUP_FROM_STORE:
    case STORE_GROUP:
    case CREATE_GROUP: {
      return {
        ...state,
        ...action.payload,
      }
    }
    case CREATED_GROUP: {
      return {
        ...state,
        ...action.payload,
      }
    }
    case GET_MY_GROUPS: {
      return {
        ...state,
        [DEFAULT_KEY]: generateCacheTTL(),
        myGroups: action.payload.myGroups,
      }
    }
    case GET_GROUP_BY_ID: {
      return {
        ...state,
        ...action.payload,
      }
    }
    case GET_ALL_GROUPS: {
      return {
        ...state,
        ...action.payload,
      }
    }
    case GET_GROUP_BY_ID_WITH_EVENTS: {
      return {
        ...state,
        ...action.payload,
      }
    }
    case GET_GROUP_BY_ID_WITH_MEMBERS: {
      return {
        ...state,
        ...action.payload,
      }
    }
    case GET_PAST_PURCHASED_MEMBER: {
      return {
        ...state,
        ...action.payload,
      }
    }
    case GET_PAST_PURCHASED_MEMBER_ERROR: {
      return {
        ...state,
        ...action.payload,
      }
    }
    case CLEAR_PAST_PURCHASED_MEMBER: {
      return {
        ...state,
        ...action.payload,
      }
    }
    case GET_GROUP_WITH_EVENT_BY_ID: {
      return {
        ...state,
        ...action.payload,
      }
    }
    case UPDATE_GROUP: {
      return {
        ...state,
        ...action.payload,
      }
    }
    case CLEAR_GROUP: {
      return {
        ...state,
        newGroup: null,
      }
    }

    case BAN_MEMBER: {
      return {
        ...state,
        ...action.payload,
      }
    }
    case REMOVE_MEMBER: {
      return {
        ...state,
        ...action.payload,
      }
    }
    case CHANGE_ROLE_OF_MEMBER_IN_GROUP: {
      return {
        ...state,
        ...action.payload,
      }
    }
    case JOIN_GROUP: {
      return {
        ...state,
        ...action.payload,
      }
    }
    case APPROVE_JOIN_GROUP: {
      return {
        ...state,
        ...action.payload,
      }
    }
    case APPEND_NEW_EVENT_TO_GROUP: {
      return {
        ...state,
        ...action.payload,
      }
    }
    case REMOVE_EVENT_IN_GROUP: {
      return {
        ...state,
        ...action.payload,
      }
    }
    case UPDATE_EVENT_IN_GROUP: {
      return {
        ...state,
        ...action.payload,
      }
    }
    case UPDATE_TOTAL_ORDER_COUNT: {
      return {
        ...state,
        ...action.payload,
      }
    }
    case UPDATE_TOTAL_ORDER_COUNT_IN_GROUP_WHEN_CANCEL_ORDER: {
      return {
        ...state,
        ...action.payload,
      }
    }
    case CLEAR_GROUP_BY_ID: {
      return {
        ...state,
        ...action.payload,
      }
    }
    default:
      return state
  }
}

export default group
