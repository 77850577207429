import { LIST_TIME } from '_utils/constant'
import PropTypes from 'prop-types'
import React from 'react'
import { Modal, ModalBody, Spinner } from 'reactstrap'
import { idGenerator } from '../../../utils/function'
import DatePicker from '../components/DatePicker'

const DeliveryOptionsModal = (props) => {
  const {
    modal,
    handleOnClickOk,
    deliveryZones,
    deliveryTime,
    onChangeZoneDelivery = () => {},
    onChangeTimeDelivery,
    handleOnRemoveDeliveryZone = () => {},
    handleAddMoreDeliveryZone = () => {},
    deliveryHourStart,
    setDeliveryHourStart,
    deliveryHourEnd,
    setDeliveryHourEnd,
    toggle,
    selectedEntity = '',
    setSelectedEntity,
    optionsEntity = [],
    isSubmitting = false,
    errorMessageDeliveryZone = '',
    zoneErrors,
    hasValidationErrors = () => {},
  } = props

  const listShop = optionsEntity.filter((item) => item.shopSlug)
  const listGroup = optionsEntity.filter((item) => item.gid)

  return (
    <Modal isOpen={modal} toggle={toggle} centered className='modal-schedule-delivery'>
      <ModalBody>
        <div className='container p-0 '>
          <h5 className='text-center mb-3'>Schedule Delivery</h5>

          {optionsEntity && optionsEntity.length > 0 && (
            <div className='input-delivery-group mb-2'>
              <select
                value={selectedEntity}
                onChange={(e) => setSelectedEntity(e.target.value)}
                key={`select-entity-${selectedEntity}`} // Add a key to ensure proper re-rendering
              >
                {listGroup && listGroup.length > 0 && (
                  <optgroup label='Groups'>
                    {listGroup.map((group) => (
                      <option value={group.id} key={`group-${group.id}`}>
                        {group.name}
                      </option>
                    ))}
                  </optgroup>
                )}
                {listShop && listShop.length > 0 && (
                  <optgroup label='Shops'>
                    {listShop.map((shop) => (
                      <option value={shop.id} key={`shop-${shop.id}`}>
                        {shop.name}
                      </option>
                    ))}
                  </optgroup>
                )}
              </select>
            </div>
          )}

          <div className='input-delivery-date'>
            <DatePicker onChange={onChangeTimeDelivery} value={deliveryTime} />
          </div>

          <div className='group-hour-delivery mt-2 mb-2'>
            <div className='input-delivery-hour d-flex flex-column'>
              <div>Between</div>
              <select
                value={deliveryHourStart}
                onChange={(e) => setDeliveryHourStart(e.target.value)}
              >
                {LIST_TIME.map((item, index) => (
                  <option key={index}>{item.text}</option>
                ))}
              </select>
            </div>

            <div className='pb-4 pt-4 font-weight-bold icon-cross'>-</div>

            <div className='input-delivery-hour'>
              <div>To</div>
              <select value={deliveryHourEnd} onChange={(e) => setDeliveryHourEnd(e.target.value)}>
                {LIST_TIME.map((item, index) => (
                  <option key={index}>{item.text}</option>
                ))}
              </select>
            </div>
          </div>

          <div className={'sub-item-option align-items-center modal-delivery'}>
            <p className='text-left'>Delivery Fee</p>
            {(deliveryZones || [{ name: '', price: 0, id: idGenerator() }]).map((item, index) => (
              <div className='d-flex mb-3' key={`delivery-zone-${item.id || index}`}>
                <div className='sub-item-info col-6 d-flex align-items-center'>
                  <span className='sub-txt'>{index + 1}. </span>
                  <div className='position-relative'>
                    <input
                      className={`form-control form-control-line delivery-option ${
                        zoneErrors?.[index]?.name ? 'is-invalid' : ''
                      }`}
                      placeholder={`Area ${index + 1}`}
                      value={deliveryZones[index].name}
                      onChange={(e) => onChangeZoneDelivery(index, 'name', e.target.value)}
                    />
                    {zoneErrors?.[index]?.name && (
                      <small className='text-danger' style={{ position: 'absolute', fontSize: 13 }}>
                        {zoneErrors[index].name}
                      </small>
                    )}
                  </div>
                </div>
                <div className='sub-item-info col-5 d-flex align-items-center'>
                  <span className='sub-txt'>$ </span>
                  <div className='position-relative'>
                    <input
                      className={`form-control form-control-line delivery-option ${
                        zoneErrors?.[index]?.price ? 'is-invalid' : ''
                      }`}
                      type={'number'}
                      value={Number(deliveryZones[index].price)}
                      onChange={(e) => onChangeZoneDelivery(index, 'price', Number(e.target.value))}
                    />
                    {zoneErrors?.[index]?.price && (
                      <small className='text-danger' style={{ position: 'absolute', fontSize: 13 }}>
                        {zoneErrors[index].price}
                      </small>
                    )}
                  </div>
                </div>
                <div
                  className='d-flex justify-content-end align-items-center col-1'
                  style={{
                    WebkitFilter: 'grayscale(100%)',
                    filter: 'grayscale(100%)',
                  }}
                  onClick={() => handleOnRemoveDeliveryZone(index)}
                >
                  <a className='btn-remove' />
                </div>
              </div>
            ))}
            {errorMessageDeliveryZone && (
              <small className='text-danger font-size-xs'>{errorMessageDeliveryZone}</small>
            )}
            <div className='add-more'>
              <span className='btn-add-more' onClick={handleAddMoreDeliveryZone}>
                Add more
              </span>
            </div>
          </div>
          <div className='row mt-3'>
            <div className='col-6 m-auto'>
              <button
                className='btn btn--org btn--org02'
                onClick={handleOnClickOk}
                disabled={hasValidationErrors()}
              >
                {isSubmitting ? <Spinner color='light' size='sm' /> : 'OK'}
              </button>
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default DeliveryOptionsModal

DeliveryOptionsModal.propTypes = {
  modal: PropTypes.bool,
  handleOnClickOk: PropTypes.func,
  handleRemoveRemember: PropTypes.func,
  deliveryZones: PropTypes.array,
  deliveryTime: PropTypes.object,
  onChangeZoneDelivery: PropTypes.func,
  onChangeDateDelivery: PropTypes.func,
  handleOnRemoveDeliveryZone: PropTypes.func,
  handleAddMoreDeliveryZone: PropTypes.func,
  hideDeliveryTime: PropTypes.bool,
  hideDeliveryZones: PropTypes.bool,
  zoneErrors: PropTypes.object,
  hasValidationErrors: PropTypes.func,
}
