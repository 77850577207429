import { yupResolver } from '@hookform/resolvers/yup'
import groupApi from '_api/group'
import { DeviceUserContext } from '_context/DeviceUserContext'
import { updateGroup } from '_redux/modules/group'
import { getListPackage } from '_redux/modules/package'
import { DESKTOP, PACKAGE_TYPE_PREMIUM_ACCOUNT, RELEASE_DATE_EXAMPLE } from '_utils/constant'
import { getGroupIdSelected } from '_utils/localData'
import React, { useContext, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'
import { Form, Spinner } from 'reactstrap'
import * as Yup from 'yup'

import uploadApi from '_api/upload'
import WithErrorBoundary from '_components/WithErrorBoundary'
import { SelectIdGroupContext } from '_context/SelectGroupContext'
import { getListInvoicePremiumAccount } from '_redux/modules/invoice'
import moment from 'moment'
import GroupContact from './components/GroupContact'
import GroupDescription from './components/GroupDescription'
import GroupName from './components/GroupName'
import GroupQuestion from './components/GroupQuestion'
import GroupSetting from './components/GroupSetting'
import './style.scss'

const Settings = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { addToast } = useToasts()
  const { infoGroupSelected, setInfoGroupSelected } = useContext(SelectIdGroupContext)
  const { checkDeviceUser } = useContext(DeviceUserContext)
  const { listPackage } = useSelector((state) => state.package)
  const { myGroups: listMyGroups } = useSelector((state) => state.group)

  const [dataSelectedGroup, setDataSelectedGroup] = useState()
  const [groupId, setGroupId] = useState(getGroupIdSelected())
  const [contactValue, setContactValue] = useState('')
  const [groupInfo, setGroupInfo] = useState()
  const [groupImg, setGroupImg] = useState('')
  const [groupImgUrl, setGroupUrl] = useState(groupInfo?.photoUrl)
  const [groupType, setGroupType] = useState(groupInfo?.public)
  const [defaultRole, setDefaultRole] = useState(groupInfo?.defaultRole)
  const [autoApproval, setAutoApproval] = useState(groupInfo?.autoApproval)
  const [accessMemberList, setAccessMemberList] = useState(groupInfo?.accessMemberList)
  const [packagePremiumAccount, setPackagePremiumAccount] = useState()

  const validationSchema = Yup.object().shape({
    groupName: Yup.string('Invalid name').required('Invalid name'),
    groupDescription: Yup.string('Invalid description').required('Invalid description'),
    groupQuestion: Yup.string().nullable().notRequired(),
    passCode: Yup.string().nullable().notRequired(),
    groupContact: Yup.string()
      .nullable()
      .test('is-valid-contact', 'Invalid contact format', (value) => {
        if (!value) return true

        if (/^\d{8}$/.test(value)) {
          return true
        }

        if (value.includes('@')) {
          return true
        }

        // Optimize WhatsApp link validation
        if (/^(https?:\/\/)?(www\.)?wa\.me\//.test(value)) {
          return true
        }

        // Optimize website validation
        if (/^(https?:\/\/)?(www\.)?[a-zA-Z0-9-]+\.(com|org|net|com\.sg|org\.sg)$/.test(value)) {
          return true
        }

        if (value.toUpperCase() === 'NA') {
          return true
        }

        return false
      }),
  })

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setValue,
    watch,
  } = useForm({
    mode: 'all',
    resolver: yupResolver(validationSchema),
    defaultValues: {
      groupName: groupInfo?.name,
      groupDescription: groupInfo?.description,
      groupQuestion: groupInfo?.question,
      passCode: groupInfo?.passcode,
      groupContact: groupInfo?.contact,
    },
  })

  const watchContact = watch('groupContact')

  const handleContactChange = (event) => {
    const { value } = event.target

    if (/^\d{8}$/.test(value)) {
      setValue('contact', `https://wa.me/${value}`)
      setContactValue(`https://wa.me/${value}`)
    } else if (value.includes('@')) {
      setValue('contact', value)
      setContactValue(value)
    } else if (/\.(com|org|net|com\.sg|org\.sg)$/.test(value)) {
      setValue('contact', `https://${value}`)
      setContactValue(`https://${value}`)
    } else if (value === 'na') {
      const formattedValue = value.toUpperCase()
      setValue('contact', formattedValue)
      setContactValue(formattedValue)
    } else {
      setValue('contact', value)
      setContactValue(value)
    }
  }

  const onGroupImageUploaded = (event) => {
    const { files } = event.target
    if (files) {
      const img = files[0]
      setGroupUrl(URL.createObjectURL(img))
      setGroupImg(files)
    }
  }

  const onChangeRadio = (state, value) => {
    if (state === 'groupType') setGroupType(value)
    else if (state === 'defaultRole') setDefaultRole(value)
    else if (state === 'accessMemberList') setAccessMemberList(value)
    else setAutoApproval(value)
  }

  const onSubmit = async (data) => {
    const postData = {
      name: data.groupName,
      description: data.groupDescription,
      photoUrl: groupImgUrl,
      passcode: data.passCode,
      question: data.groupQuestion,
      public: groupType,
      defaultRole,
      autoApproval,
      accessMemberList,
      contact: contactValue,
    }
    if (groupImg) {
      const { msgResp: res } = await uploadApi.uploadPhoto(groupImg)
      postData.photoUrl = res.url
    }
    try {
      await dispatch(
        updateGroup({
          id: groupId,
          postData,
          groupType,
          packageId: packagePremiumAccount?.id,
          startDate: moment().format('YYYY-MM-DD'),
          packageType: PACKAGE_TYPE_PREMIUM_ACCOUNT,
          releaseDate: RELEASE_DATE_EXAMPLE,
        })
      )
      setInfoGroupSelected({
        groupId,
        groupName: data.groupName,
        totalMembers: groupInfo?.totalMembers, // Adjust as needed
      })
      addToast('Updated group successfully', { appearance: 'success', autoDismiss: true })

      if (checkDeviceUser === DESKTOP) return
      history.push('/')
    } catch (error) {
      addToast(error.msgResp || error.message, { appearance: 'error', autoDismiss: true })
    }
  }

  useEffect(() => {
    Promise.all([
      dispatch(getListPackage({ packageType: PACKAGE_TYPE_PREMIUM_ACCOUNT })),
      dispatch(getListInvoicePremiumAccount()),
    ])
  }, [])

  useEffect(() => {
    if (listPackage?.length) {
      setPackagePremiumAccount(listPackage[0])
    }
  }, [JSON.stringify(listPackage)])

  useEffect(() => {
    setGroupId(infoGroupSelected?.groupId)
    const groupFound = (listMyGroups || []).find((group) => group.id === infoGroupSelected?.groupId)

    setDataSelectedGroup(groupFound)
  }, [infoGroupSelected?.groupId, listMyGroups])

  useEffect(() => {
    if (watchContact && watchContact.toLowerCase() === 'na') {
      setValue('groupContact', 'NA')
    }
  }, [watchContact, setValue])

  useEffect(async () => {
    if (groupId !== undefined) {
      const { msgResp: groupData } = await groupApi.getById(groupId)
      if (groupData) {
        setGroupInfo(groupData)
      }
    }
  }, [groupId])

  useEffect(() => {
    if (groupId && dataSelectedGroup) {
      setGroupInfo(dataSelectedGroup)
      setValue('groupName', dataSelectedGroup?.name)
      setValue('groupDescription', dataSelectedGroup?.description)
      setValue('groupQuestion', dataSelectedGroup?.question)
      setValue('passCode', dataSelectedGroup?.passcode)
      setValue('groupContact', dataSelectedGroup?.contact)
      setGroupUrl(dataSelectedGroup?.photoUrl)
      setGroupType(dataSelectedGroup?.public)
      setDefaultRole(dataSelectedGroup?.defaultRole)
      setAutoApproval(dataSelectedGroup?.autoApproval)
      setAccessMemberList(dataSelectedGroup?.accessMemberList)
      setContactValue(dataSelectedGroup?.contact)
    }
  }, [groupId, dataSelectedGroup])

  return (
    <div className='setting-page custom-scrollbar' style={{ height: 'calc(100vh - 160px)' }}>
      <div className='size-width-desktop-col-1 mb-3'>
        <Form onSubmit={handleSubmit(onSubmit)} className='update-group-page'>
          <div className='c-form c-form--group mt-2'>
            <div className='row'>
              <div className='col-4'>
                <div className='upload-image'>
                  <img src={groupImgUrl?.url || groupImgUrl} alt='' style={{ zIndex: 3 }} />
                  <div className='upload-image-txt'>
                    <i className='icon-capture'></i>
                    <span>
                      Add Profile
                      <br />
                      Picture
                    </span>
                  </div>
                  <div className='u-file-upload__item'>
                    <div className='u-file-upload__wrap'>
                      <a className='c-upload preview-images-zone'>
                        <input
                          type='file'
                          accept='image/*'
                          name='file_source_01'
                          size='40'
                          className='inputFile'
                          data-error='#error-file_source_01'
                          onChange={onGroupImageUploaded}
                          style={{ zIndex: '999' }}
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-8'>
                <GroupName register={register('groupName')} error={errors?.groupName} />
                <GroupDescription
                  register={register('groupDescription')}
                  error={errors?.groupDescription}
                />
              </div>
            </div>
            <div className='box-question'>
              <GroupQuestion register={register('groupQuestion')} error={errors?.groupQuestion} />
              <GroupContact
                contactValue={groupInfo?.contact}
                register={register('groupContact', {
                  onChange: (e) => handleContactChange(e),
                })}
                error={errors?.groupContact}
              />
              <GroupSetting
                groupType={groupType}
                defaultRole={defaultRole}
                autoApproval={autoApproval}
                onChangeRadio={onChangeRadio}
                accessMemberList={accessMemberList}
                register={register('passCode')}
              />
            </div>
          </div>
          <div className='row row--bot align-self-end mt-3'>
            <div className='col-6 m-auto'>
              <button className='btn btn--main' type='submit' disabled={isSubmitting}>
                {isSubmitting ? <Spinner color='light' size='sm' /> : 'Save'}
              </button>
            </div>
          </div>
        </Form>
      </div>
      {/* <ModalInfo
        isOpenModalInfoCreateGroup={isOpenModalInfoCreateGroup}
        toggleModalInfoCreateGroup={toggleModalInfoCreateGroup}
      /> */}
    </div>
  )
}

export default WithErrorBoundary(Settings)
